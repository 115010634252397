@import "~@angular/material/prebuilt-themes/indigo-pink.css";
/****USER AUTH********************************************************************/
body {
    height:max-content; /* this is used because we have some elements using 100vh as height, in some places we need to detect if window has scroller using resizeObserve*/
}
body { 
  direction: ltr;
}
body.show-import-sidebar {
    overflow: hidden !important;
}
.clear {
    clear: both;
    content:""
}
.owl-theme .owl-nav{
  margin-top: -8px;
}
.owl-theme .owl-nav .owl-prev, .owl-theme .owl-nav .owl-next{
width:20px;
height:30px;
background:inherit !important
}

.owl-theme .owl-nav .owl-prev:after, .owl-theme .owl-nav .owl-next:after{
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  content:"";
}

.owl-theme .owl-nav .owl-prev:after{
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.pay-invoice .confirm-payment{
margin: unset !important;
}
.hide,.navbar-responsive .navbar-Dropdown,.navbar-responsive-settings ul, .navbar-responsive-profiles ul,.my-account-hidden,.agr-invoices .fa-chevron-up{
    display:none !important;
}
.navbar-AccountProfiles {
    position: relative;
}
.navbar-AccountProfiles:before,
.navbar-AccountProfiles:after {
    width:1px;
    position: absolute;
    left: 1.6rem;
    content:"";
    z-index: 10;
}
.navbar-AccountProfiles:before {
    height: calc(100% - 52px);
    top: 0rem;
    background-color: #1C008A;
}
.navbar-settings .navbar-accounts:hover{
  color: #1C008A !important;
}
.navbar-AccountProfiles:after {
    height:10px;
    top: -0.7rem;
    background-color: #fff
}
.delete-account {
    float: right;
    font-size: 1rem;
    font-weight: bold;
    position: absolute;
    right: 0;
    top: 1.5rem;
    cursor: pointer;
}
/*******************************************************************/
  .myCustomModalClass .modal-dialog {
    max-width: 95%;
    width: 38.125rem;
  }
  .myCustomModalClass .modal-header {
    /* border:none;
    padding:2rem 2rem 0.75rem 2rem */
  }
 .myCustomModalClass .modal-title {
    font-size:1.25rem;
    font-weight: 500;
  }
 /* .myCustomModalClass .modal-content {
  border-radius: 25px;
  } */
.myCustomModalClass .modal-header .close {
    /* background-color: #fff;
      border-radius: 50%;
      font-size: 2rem;
      font-weight: 600;
      color: #000;
      position: absolute;
      right: 2.2rem;
      top: -0.5rem;
      line-height: 3rem;
      padding: 0;
      height: 3rem;
      width: 3rem;
      text-align: center;
      opacity: 1 !important;
      -webkit-box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.13); 
      box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.13); */
      border-bottom: 1px solid lightgray !important;
      padding: 1rem !important;
      text-align: center !important;
  }
  .myCustomModalClass .wizard-dropzone .form-group .dropzone {
    flex-direction: row;
    height:6rem
  }
  .myCustomModalClass .wizard-dropzone .form-group .dropzone .dropzone-text {
    width: 17rem;
    text-align: left;
    padding-right: 1rem;
    position: relative;
  }
  .myCustomModalClass .wizard-dropzone .form-group .dropzone .dropzone-text .or {
    position: absolute;
    top: 0.7rem;
    right: 1rem;
    z-index: 10;
  }
  .myCustomModalClass .modal-actions {
    width: 100%;
    text-align: right;
  }
  /*lilian: */
  /*Style the popup container */
.popup-container {
  display: none; /* Initially hidden */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
  justify-content: center;
  align-items: center;
}
/* Style the active class to show the popup */
.popup-container.active {
  display: flex; /* Display when active */
}

/* Style the popup content */
.popup-content {
  background: white;
  /* padding: 20px; */
  min-width: 50rem;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  position: relative;
  min-height: 60%;
}

/* Style the close button */
.close-button {
  position: absolute;
  top: 15px;
  left: 10px;
  cursor: pointer;
  background: #f0f0f0;
  padding: 0.1rem 0.5em;
  border-radius: 0.3rem;
  font-size: 1.5rem;
}
.popup-header{
  border-bottom: 1px solid lightgray;
  padding: 1rem;
  text-align: center;
}
.popup-footer{
  border-top: 1px solid lightgray;
  padding:  10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inner-content{
  padding: 20px;
  min-height: 300px; /* Adjust the height as needed */
  overflow-y: auto;
}
.cancelBtn{
  padding: 10px;
  background-color: #f0f0f0;
}
.customTable {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 2rem;
}
.customTr {
  border-top: 1px solid lightgrey; 
  /* Horizontal border */
}

/* Style for table cells (columns) */
.customTd {
  /* border-right: 1px solid lightgrey; Horizontal border */
  padding: 8px;
}

/* Style for the left column (first td in each row) */
.customTd:first-child {
  background-color: #f0f0f0;
  width: 35%;
  border-bottom: 1px solid lightgrey;
}

/* Style for the outer borders */
.customTd:first-child {
  border-left: 1px solid lightgrey; /* Outer border for the left column */
}

.customTd:last-child {
  border-right: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey; /* Outer border for the right column */
}

/*spinner*/
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1000; /* Make sure it's on top */
}

.spinner {
  width: 50px;
  height: 50px;
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.full-width{
  width: 100%;
}
.width-100 {
  width: 100% !important;
}
/*lilian*/
  .share-notice {
    font-size:0.75rem
  }
  .share-notice .img {
    float: left;
    margin-right: 1rem;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-top: -0.5rem;
    padding-top: 0.9rem;
    text-align: center;
    box-shadow: 3px 3px 8px 0px rgb(0 0 0 / 13%);
  }
  .sharing-link {
      position: relative;
  }
  .sharing-link input {
    width: 100%;
    height: 3rem;
    border: 1px solid #1C008A !important;
    border-radius: 25px;
    font-size: 1rem;
    padding: 0 1rem;
    padding-right: 5.7rem;
  }
  .sharing-link a {
    position: absolute;
    right: 0.5rem;
    top: 0.6rem;
    z-index: 10;
    font-size: 1rem;
    color: #1C008A !important;
    line-height: 1.8rem;
    font-weight: 500;
  }
  .sharing-link a.copied {
      color:#00B6A8 !important;
      cursor: default !important;
  }
  .sharing-control {
    width: 2.75rem;
    height: 2.75rem;
    line-height: 2.75rem;
    background-color: #fff;
    font-size: 1.3rem;
    color: #1C008A;
    box-shadow: 3px 3px 8px 0px rgb(0 0 0 / 13%);
    position: absolute;
    text-align: center;
    border-radius: 50%;
    top: 1rem;
  }
  .sharing-control.left-control {
      left:0
  }
  .sharing-control.right-control {
    right:0
    }
    .sharing-tools-slider {
        display: flex;
        flex-direction: row;
        padding-left: 3rem;
        padding-right: 3rem;
    }
    .sharing-tools {
        position: relative;
        margin: 1.625rem 2rem;
    }
    .sharing-tool {
        margin:0 1.2rem;
        text-align: center;
    }
.user-auth-left,
.user-auth-right {
    width: 50%;
}
.StepProgress{
  border: 2px solid #1C0D8A;
    color: #1C0D8A !important;
    font-weight: 550;
}
.step-number  .fa-exclamation{
  color: #E74E5A !important;
}
.StepIncomplete{
  border: 2px solid #E74E5A;
}
.user-auth-right .steps-container{
  position: absolute;
  left: -29rem;
  width: 20rem !important;
  top: 17.7rem;
}
.user-auth-right {
    float:right;
    position: absolute;
    right: 10rem;
    top: 12rem;
     width: 58% !important;
    box-shadow: 3px 3px 8px 0px rgb(0 0 0 / 13%);
    background-color: #f8f9fafa;
    border-radius: 50px;
}
.wizard-form-container .mat-button-toggle-appearance-standard .mat-button-toggle-label-content{
  line-height: 35px !important;
}
.user-auth-form {
  float: left;
  width: 100%;
    position: relative;  
}
.user-auth-form .wizard-stepper-block{
  padding: unset !important;
  width: unset !important;
}
.user-auth-top .left-layer {
    width: 50%;
    float:left;
}
.user-auth-top .right-layer {
    width: 50%;
    float:right;
}
body.has-scroll .contactus-page .user-auth-form {
    margin-top: 120px;
}
.user-auth-form.big {
    width: 33.2rem;
}
body.has-scroll .contactus-page .user-auth-top {
    background-color: #fff;
}
.bottom-actions {
    margin:0 auto;
    text-align: center;
}
.security-message {
  position: absolute;
  left: 6.9rem;
  bottom: 0rem;
  transform: translateY(-50%);
  padding: 1rem 0.9rem;
  background-color: #fff;
  border-radius: 8px;
  width: 80%;
  max-width: 22rem;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}
.security-message .text-note, .security-message a {
    color:#D2D2D2 !important;
    font-size:0.625rem !important;
}
.security-message .heading-4 {
    margin-bottom: 0.7rem;
    color:#1C008A !important;
    font-size: 1.1rem !important;
    font-weight: 550 !important;
}
.user-auth-right .heading-4{
  font-size: 1.25rem;
  font-weight: 550;
}
.security-message .heading-4 i {
    font-size:1.3rem
}
.security-message .heading-4 span {
    margin-left:0.7rem;
}
.user-auth-top .dropdown-menu {
    top: 2.4rem !important;
    right: 0 !important;
    left:auto !important;
    min-width: 5.3rem !important;
}
/****END USER AUTH**************************************************************/
/****WELCOME COMPONENT**********************************************************/
.welcome-container .welcome-block {
    width: 100%;
    margin-top: 10rem;
  }
  .welcome-container {
    width: 100%;
    position: relative;
    display: inline-block;
    vertical-align: top;
    padding:0;
  }
  .welcome-container .app-logo{
    padding:0 4.7rem;
  }
  .welcome-container .heading-2 {
    margin-bottom: 1.25rem;
    color:#fff
  }
  .welcome-container .text {
    color:#fff
  }
  .welcome-bottom-layer {
    width: 100%;
    background-color: #1C008A;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }
  .welcome-bottom-layer img {
    height: 100%;
  }
/****END WELCOME COMPONENT******************************************************/
/****ONBOARDING COMPONENT*****************************************/
  .contactus-page .contact-form{
    width:100%;
    margin-top: -1.5rem;
  }
  code-input{
    width: 80%;
  }
  .step-1-info{
    position: absolute;
    z-index: 5;
    top: 5%;
    left: -17rem;
    padding: 1rem;
    background-color: white;
    border-radius: 10px;
    max-width: 20rem;
    box-shadow: 3px 3px 8px 0px rgb(0 0 0 / 13%);
  }
  .step-1-info span,.step-2-info span, .step-3-info span{
    color: #1C008A;
  }
  .step-1-info div,.step-2-info div, .step-3-info div{
    opacity: 0.3;
    font-size: 12px;
  }
  .CurrentBlock{
 background-color: #FDF2E2 !important;
  }
  .CurrentBlock div{
    opacity: 1 !important;
  }
  .step-2-info{
    position: absolute;
    z-index: 5;
    top: 37%;
    left: -34rem;
    padding: 1rem;
    background-color: white;
    border-radius: 10px;
    max-width: 20rem;
    box-shadow: 3px 3px 8px 0px rgb(0 0 0 / 13%);
  }
  .form-group{
    margin-bottom: 1.5rem !important;
  }
  .step-3-info{
    box-shadow: 3px 3px 8px 0px rgb(0 0 0 / 13%);
    position: absolute;
    z-index: 5;
    max-width: 20rem;
    top: 68%;
    left: -17rem;
    padding: 1rem;
    background-color: white;
    border-radius: 10px;
  }
  @media screen and (min-width: 960px){
body:not(.has-scroll) .contactus-page .bottom-actions{
     position: unset !important; 
     margin-bottom: -1rem !important;
}
  }
  @media screen and (min-width: 960px){
 .user-auth-right .bottom-actions, .user-auth-right  .bottom-actions{
    position: absolute !important;
    left: 50% ;
    bottom: -0.3rem !important;
  }
  app-contactform  .bottom-actions,app-register  .bottom-actions, app-verify-account .bottom-actions{
    left: 34% !important;
  }
}
  app-login .user-auth-form, app-forgot-password .user-auth-form,.verification-code-input{
    width: 60% !important;
  }
/****END ONBOARDING COMPONENT*************************************/
/****CONTACT US PAGE IN USER AUTH*********************************/
.contact-map-layer {
  height: calc(100VH - 8rem);
} 
.contact-map-layer agm-map {
    width: 100%;
    height:100%
} 
.InvoicesList .connection-info{
max-width: unset !important;
max-width: 70% !important;
}
.select-all-item .connection-info{
max-width: unset !important;
}
.map-layer-info {
  background-color: #1C008A;
  padding: 1.5rem;
    border-radius: 10px;
    width: 22rem;
    max-width: 90%;
}
.map-layer-info:after {
    position: absolute;
    bottom: -14px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 14px solid #1C008A;
    content: "";
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
}
.map-layer-info label {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 1.8rem;
}
.map-layer-info label:last-child {
    margin-bottom: 0;
}
.map-layer-info b {
    font-weight: 700;
}
.map-layer-info label div {
    font-size: 0.875rem;
    color:#fff;
    float:left
}
.map-layer-info label div a {
    color:#fff;
}
.map-layer-info label i {
    font-size: 1.3rem;
    color:#fff;
    margin-right: 1rem;
    float: left;
    line-height: 1.5rem;
}
.contactus-page app-logo {
    margin: 0 auto;
    width: 33.2rem;
    max-width: 90%;
    display: block;
    padding-top: 3rem;
}
.gm-style .gm-style-iw-c {
    background: none !important;
    padding:0 !important;
    box-shadow: none !important;
}
.gm-style .gm-style-iw-d {
    overflow:visible !important;
}
.gm-style .gm-style-iw-t::after {
  background: #1C008A;
}
/****END CONTACT US PAGE******************************************/
/****WIZARD********************************************************************/
.wizard-container.user-auth-right {
  width: 65% !important;
}
.wizard-content,.agr-invoices .row1-summary .row {
    width:100%;
}
.wizard-app-container {
    width:100%;
    display: inline-block;
    vertical-align: top;
}
.wizard-inquiry {
  position: absolute;
  top: 0.8rem;
  right: 6rem;
  z-index: 1;
}
.wizard-inquiry .question-icon {
    position: absolute;
    top: 1.5rem;
    right: 2.5rem;
    z-index: 1;
    width: 3rem;
    height: 3rem;
    text-align: center;
    line-height: 3rem;
    border-radius: 50%;
    font-size: 1.5rem;
}
.wizard-inquiry .question-icon:hover {
    background:#1C008A;
    color:#fff;
    border-color: #1C008A;
}
.wizard-left {
    float:left;
    background-color: #1C008A;
}
.steps-agreement .step-circle:after {
  content: "";
  border-right: 0.5px solid #1c008a8c;
  background: white;
  position: absolute;
  bottom: 0;
}
.wizard-right {
    background-color: #fff;
}
.wizard-pagination {
    width: 22rem;
}
.wizard-form-container .upper-title-1,
.address-form-container .upper-title-1,
.documents-form-container .upper-title-1 {
    margin-bottom:2rem;
}
.steps-container {
    width: 13rem;
    max-width: 90%;
    margin:0 auto;
}
.steps-agreement .step-circle .step-tab,.steps-agreement .step-circle .step-tag.isCompleted {
color: #363636 !important;
opacity: 0.9;
}
.add-amount:before,.add-amount:after {
  color: white;
  content: '';
  flex: 1;
  border-bottom: solid 1px #1C008A;
  margin: auto 0.25rem;
  box-shadow: 0 -2px;
}
.steps-container app-wizard-step {
    position: relative;
    z-index: 2;
}
.step-circle {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    height: 6.6rem;
    position: relative;
}
.step-number{
  border-radius: 50%;
  background-color: white;
  color: #a3a3a37a;
  left: 22rem;
  cursor: pointer;
  width: 50px;
  top: -0.5rem;
  height: 50px;
  position: absolute;
  font-size: 1.5rem;
  z-index: 55;
  padding: 0.5rem;
  text-align: center;
}
.step-circle .step-tab {
    display: inline-block;
    vertical-align: top;
    color:white;
    font-size: 1rem;
    z-index: 2;
}
.step-circle .step-name {
   opacity: 75%;
}
.step-circle .step-tag {
    font-size: 0.75rem;
    font-weight: 500;
    font-style: italic;
    display: none;
}
.step-circle .step-tag.isCompleted {
    color:white;
    display: inline-block;
}
.step-circle .step-tag.inComplete {
    color:#E74E5A;
    display: inline-block;
}
.gm-ui-hover-effect, .slick-dots li button:before,.slick-dots li button:before,
.navbar-AccountProfiles.no-permissions-found:before,
.navbar-AccountProfiles.no-permissions-found:after,.HideCurrency,.receiver-info-hide,.Indiv-Subscription .fa-chevron-up,.Indiv-Subscription:hover .fa-chevron-down,
.several-docs-hover,.Payment-sched .connection-item .connection-actions,.MyInvoices .connection-item .checkItem.connection-actions,
.payment-list{
    display: none;
}
.address-form-container {
    width: 46rem;
    max-width: 100%;
    float:left;
    position: relative;
    z-index: 2;
}
.sidebar-documents {
  height: 100vh;
  width: 30vw;
  position: fixed;
  left:100%;
  top: 0;
  z-index: 2000;
  padding: 3rem;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  background-color: whitesmoke;
}
.sidebar-documents .wizard-dropzone{
  max-height: 95%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.sidebar-documents.show {
  left:auto;
  right: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0;
  visibility: hidden;
  -webkit-transition-property: all;
    -webkit-transition-duration: 0.5s;
    -webkit-transition-timing-function: linear;
    -webkit-transition-delay: 0s;
    -ms-transition-property: all;
    -ms-transition-duration: 0.5s;
    -ms-transition-timing-function: linear;
    -ms-transition-delay: 0s;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: linear;
    transition-delay: 0s;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
  visibility: visible;
}
.documents-form-container .upload-docs {
  position: absolute;
  top: 0rem;
  right: -8rem;
}
.document-block:hover  .multiple-docs{
  display: flex !important;
  max-width: 50rem;
  overflow-x: scroll;
}
.connections-middle .multiple-docs .document-title{
  margin-left: 1rem;
}
.connections-middle .multiple-docs{
  top: 5rem !important;
}
.multiple-docs{
  position: absolute;
  border: 0.5px solid #F5F5F5;
  display: none;
  background-color: white;
  border-radius: 25px;
  top: 10rem;
  padding: 2rem;
  z-index: 77;
  box-shadow: 3px 3px 8px 0px rgb(0 0 0 / 13%);
}
.settings-list .btn-style-6{
display: unset !important;
width: 90% !important;
}
app-profile .form-group textarea.form-control {
  height: 200px !important;
}
.navbar-iconslist li:hover .share-connection-profile .profile-image-options{
  visibility: visible !important;
  opacity: 1;
}
.navbar-iconslist li .share-connection-profile .profile-image-options {
  left: 8rem !important;
  top: 0.3rem !important;
  border: unset;
  width: 23rem !important;
  box-shadow: 1px 1px 8px 0px rgb(0 0 0 / 13%);
  border-radius: 25px !important;
}
.documents-form-container .documents-list .multiple-docs .document-block {
  width: 150px;
  padding-top: 2rem;
  height: 100px;
}
.multiple-docs .document-block{
  margin: 0rem 1rem;
}
.documents-list .fa-folder{
  font-size: 90px;
  position: absolute;
  top: -1rem;
  left: 0;
  color: #CDDAFD ;
}
.documentfilledtitle {
  font-weight: 600;
  opacity: 100% !important;
}
.close-documentsform{
  position: absolute;
  box-shadow: 3px 3px 8px 0px rgb(0 0 0 / 13%);
    border-radius: 50%;
    width: 30px;
    font-size: 1.2rem;
    line-height: 1.8;
    height: 30px;
    text-align: center;
    top: 1rem;
    right: 1rem;
    background-color: white;
}
.back-button i{
  font-weight: 300 !important;
}
.Transactions .InvoiceDetails table {
  box-shadow: 1px 1px 8px 0px rgb(0 0 0 / 13%);
  border: unset !important;
}
@media screen and (min-width: 1600px) {
  .confirm-payment .connection-item .cancelPay i {
    padding-top: 0.25rem;
  }
  .back-button {
    line-height: 1.8 !important;
}
}

.back-button{
  text-align: center;
    border-radius: 50%;
    border: 1px solid #707070;
    width: 40px;
    font-size: 1.5rem;
    display: inline-block;
    height: 40px;
    margin-right: 1rem;
    line-height: 2;
    background-color: white;
}
.documents-form-container .documents-list {
  border-bottom: unset !important;
  width: 90%;
  margin: auto;
  padding-left: 1rem;
  margin-top: 3rem;
}
.documents-form-container .documents-list .document-block{
  width: 200px;
    height: 150px;
    padding-top: 2.2rem !important;
}
.documents-form-container .documents-list .fa-folder{
  font-size: 200px;
    top: -3.5rem;
}
.documents-form-container .documents-list .doc-icon{
height: 100%;
}
.documents-form-container .documents-list .doc-icon img{
  height: 70%;
  width: 50%;
}
.Add-doc-block{
  width: 200px;
  height: 150px;
  cursor: pointer;
  border: 2px dashed #1C008A;
  border-radius: 20px;
  margin-top: 1rem;
  text-align: center;
  padding-top: 3rem;
  background-color: white;
  color: #E7E5F3;
  font-size: 1.3rem;
}
.map-container {
    position: relative;
    z-index: 1;
}
.relative-position{
  position: relative;
}
/**End Personal Info**/
/**Address**/
.centerMarker {
    position:absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    left:0;
    margin:0 auto;
    z-index:1;
    height:100px;
    width:100px;
    cursor:pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.centerMarker img {
    position: relative;
    z-index: 3;
    left: 0;
    top: -16px;
}
.centerMarker:before,
.centerMarker:after {
    width: 100%;
    height: 100%;
    content:"";
    position:absolute;
    left: 0px;
    top: -10px;
    z-index: 1;
    border-radius: 50%;
}
.centerMarker:before {
    background-color: rgba(0,115,177,0.17);
}
.centerMarker:after {
    transform: scale(0.5, 0.5);
    background-color: rgba(0,115,177,0.2);
    opacity:0;
    visibility: hidden;
}
.loading-effect .centerMarker:after {
    animation: pulse 1.2s infinite;
    opacity:1;
    visibility: visible;
}
@keyframes pulse {
    0% {transform: scale(0, 0);  }
    100% { transform: scale(1, 1);}
}
.agm-map {
    width: 100%;
    height: 100%;
}
.map-agm {
    width: 100%;
    height: 200px;
    position: relative;
    display: inline-block;
}
.map-agm iframe {
    width: 100%;
    height: 100%;
    border:none
}
.map-center-overlay {
    margin: 0;
    position: absolute;
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
}
.find-location {
       position: relative;
       font-size: 1.1rem;
       z-index: 5;
}
/**end address**/
/**social media**/
.social-media-connect {
    position: relative;
    padding-left:3rem;
    margin-bottom: 2.3rem;
    width: 100%;
    display: inline-block;
    vertical-align: top;
}
.documents-list .document-title{
  margin-top: 0.5rem;
  text-align: center;
  width: 7rem;
}
.connections-middle .documents-list{
  padding-top: 0.7rem !important;
}
.documents-list{
  width: 100%;
  display: flex;
  grid-gap: 30px;
  flex-wrap: wrap;
  padding: 2rem 0rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #DBDBDB;
}
.upload-docs a{
  width: 100%;
}
.document-block:hover .profile-image-options{
 visibility: visible;
 opacity: 1;
}
.document-block .profile-image-options div{
padding: 0.5rem 0rem;
opacity: 0.9;
border-bottom: 0.5px solid #EFEFEF;
}
.agreement-summary .action-link.primary {
  position: absolute;
    right: 2rem;
}
.document-block{
  width: 90px;
  cursor: pointer;
  height: 70px;
  margin-top: 1rem;
  border-radius: 8px;
  background-color: #EFEFEF;
  text-align: center;
  padding-top: 0.5rem;
  position: relative;
  z-index: 10;
}
.document-content{
  opacity: 0.5;
}
.documentfilled{
  background-color: #CDDAFD !important;
  background-image: unset !important;
  z-index: unset !important;
  border-top: 0.5px solid #8da8f1;
}
.documentfilled .document-content{
  opacity: 1 !important;
  font-weight: 600;
}
.social-media-connect .input-search-zoom {
  top: 0.3rem;
    font-size: 1.8rem;
    line-height: 1.8rem;
}
.wizard-app-container .documents-form-container  .documents-list .document-title {
  width: unset !important;
    margin-top: 1rem;
    color: #363636;
    opacity: 75%;
    font-size: 16px;
}
.social-media-item{
  width: 35%;
}
.social-media-connect label {
    vertical-align: middle;
    width: 8.375rem;
    margin: 0;
}
.social-media-connect .btn-style-1 {
    display: inline-block;
    width: 10rem;
    line-height: 3.5rem;
    min-width: 10rem;
    vertical-align: middle;
    font-weight: 400;
}
/**end social media**/
.pac-container {
    margin-top: -4px;
    z-index: 100;
    border-radius: 0 0 40px 40px;
    padding:25px 2.2rem 20px;
    font-family: 'Roboto', sans-serif;
}
.pac-container .pac-item {
    border:none !important;
    font-family: 'Roboto', sans-serif;
}
/****END WIZARD****************************************************************/
/****DASHBOARD****************************************************************/
.dashboard-wrapper {
    position: relative;
}
.dashboard-bluebg {
    width: 100%;
    position: absolute;
    top:0;
    left:0;
    z-index: 1;
}
.dashboard-content {
    position: relative;
    z-index: 2;
    width: 100%;
    display: inline-block;
    vertical-align: top;
}
.dashboard-container {
    max-width:2000px;
    width: 97%;
    margin-top: 3rem !important;
    margin-bottom: 0rem !important;
    margin:4.93rem auto 2rem;
    margin-left: 0rem !important;
    display: flex;
    align-items: stretch;
}
.dashboard-left {
    width: 19.44rem;
    min-width: 20.44rem;
}
app-my-account .dashboard-left{
  min-width: 25.44rem !important;
}
.dashboard-right {
    width: 13.625;
}
.dashboard-middle {
    flex-grow: 8;
    width: calc(100% - 20rem);
}
.dashboard-middle {
    margin-left:0.75rem;
}
.user-dashboard-middle .no-documents-found img,.user-dashboard-middle .dashboard-middle-container .no-documents-found{
  margin-top: 1.5rem !important;
}
.user-dashboard-middle .Agreements-rightpart .no-documents-found{
transform: unset !important;
}
.dashboard-left {
    min-height:calc(100vh - 9.875rem - 5.1rem);
    position: sticky;
    top: 0;
}
.dashboard-left ul {
    padding:0;
    margin:0;
}
.add-list-scoller li{
border-bottom: 1px solid #d1d0d0a6;
padding: 0.5rem 0rem;
}
.connections-top app-import-clients-popup .btn-style-5{
  right: 8rem;
}
.dashboard-left ul li {
    list-style: none;
    width: 100%;
    display: inline-block;
    vertical-align: text-top;
}
.address-actions{
  text-align: right;
  margin-left: 1rem;
}
.dashboard-left ul li label {
    font-size: 0.875rem;
    color:#000000;
    padding:0.625rem 0;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 0;
    width: 91%;
    float: left;
    padding-left: 0.5rem;
}
.navbar-dropdown-profile-image {
    overflow: hidden;
}
.navbar-dropdown-profile-image img {
    width: 100%;
    height:100%;
    object-fit: cover;
}
.dashboard-left ul li:last-child label {
    border-bottom: none;
}
.left-actions {
    position: absolute;
    width: 90%;
    bottom: -1rem;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.load-more .btn-style-4{
  position: relative;
  min-width: 17rem !important;
}
.load-more i{
  font-size: 2rem;
  position: absolute;
  left: 3rem;
  top: 0.7rem;
}
.dashboard-left .heading-5 {
    margin-bottom: 1rem;
}
.dashboard-profile-left {
  display: flex;
  align-items: center;
  justify-items: center;
}
.dashboard-profile-left .label-style-1{
  font-size: 1rem !important;
    font-weight: 600 !important;
}
.profile-left-content {
    margin:0 auto;
    text-align: center;
    width: max-content;
}
.profile-left-content .btn-style-5 {
    margin: 0 auto;
}
.profile-pic {
    position: relative;
    width: 7.5rem;
    height: 7.5rem;
    display: inline-block;
}
.profile-pic > .pro-image {
    width: 100%;
    height: 100%;
}
.profile-pic img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.modal-content .modal-body img{
  max-width: 60vw;
  max-height: 30vw;
}
.enlarged-image{
  width: 100%;
}
.profile-pic a {
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 50%;
    line-height: 1.75rem;
    color:#1C008A !important;
    border: 1px solid #1C008A;
    position: absolute;
    bottom: 0.4rem;
    right: 0.4rem;
    z-index: 1;
    text-align: center;
    background-color: #fff;
}
.profile-pic a:hover {
    background-color: #1C008A;
    color:#fff !important
}
.profile-name {
    margin-top: 0.5rem;
    font-weight:500;
    font-size:1.125rem;
}
.verified-icon {
  color: #D7008A;
  position: relative;
  font-size: 1.3rem;
}
.verified-icon i:last-child {
  position: absolute;
  z-index: 2;
  top: 0.6rem;
  left: 0.35rem;
  color: #fff;
  font-size: 0.6rem;
}
.profile-job {
    margin-top: 0.6rem;
    font-size: 0.875rem;
    color:#CDCACA;
}
.profile-socialmedia {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    height: 2.5rem;
}
.profile-socialmedia a {
    width: 2rem;
    color:#CDCACA !important;
    height: 100%;
    margin: 0 0.75rem;
    font-size: 2rem;
}
.profile-socialmedia a:hover {
    color:#1C008A !important
}
.profile-qrcode {
    margin-top: 0.75rem;
    text-align: center;
}
.profile-qrcode img,
.profile-qrcode canvas {
    max-width: 100%;
    height:auto !important;
    width: 100% !important;
}
.profile-left-content .btn-style-5 {
    margin-top: 0.875rem;
    width: 70%;
}
.profile-left-menu {
  overflow: hidden;
}
.profiile-flex-container {
    display: flex;
}
.profile-middle-menu {
    width: 18.5rem;
    padding:1.375rem 1.25rem;
    border-right: 1px solid #CDCACA;
}
.profile-middle-menu ul.menu-style-1 li a {
    border-bottom: 1px solid #CDCACA;
    padding:1.1rem 1.2rem;
}
.profile-middle-menu ul.menu-style-1 li a i {
    border-radius: 50%;
    width: 1.2rem;
    height: 1.2rem;
    font-size: 0.7rem;
    text-align: center;
    line-height: 1rem;
}
.profile-middle-menu ul.menu-style-1 li a i.fa-angle-right {
    border:none;
    font-size: 1.6rem;
    line-height: 1.6rem;
    top: 0.8rem;
}
.profile-middle-menu ul.menu-style-1 li a i {
    right:0.5rem
}
.profile-progress {
    margin-top: 2.6875rem;
}
.profile-progress-title {
    font-size:0.875rem;
    font-weight: 700;
}
.profile-progress-bar {
  width: 100%;
  height: 1rem;
  position: relative;
  border: 2px solid #00008A;
  border-radius: 8px;
  margin-top: 1.5rem;
}
.fal.fa-bell{
  transform: rotate(45deg);
}
.profile-contact-info .form-group .action-link.primary{
  color: white;
  background-color: #1C008A;
}
.profile-progress-completed {
  width: 0%;
    height: 100%;
    background-color: #1C008A;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    border-radius: 8px;
    color: white;
}
.profile-left-content .btn-style-5:hover .profile-image-options{
  visibility: visible !important;
  opacity: 1;
  left: 4rem;
  top: 2.8rem;
  width: 25rem;
  padding: 2rem;
  border-radius: 40px;
  font-weight: 400;
}
.otherdocs{
 border-bottom: unset !important;
}
.connections-middle .labelstyle-2{
  font-weight: 500 !important;
}
.share-connection-profile .Dropdown-header {
  font-weight: 600;
  color: #1C008A;
  border-bottom: 1px solid #cdcacaad;
}
.share-connection-profile .navbar-accounts{
  border-top: 0.2px solid #cdcaca1a;
    border-bottom: 0.2px solid #cdcacac2;
}
.share-connection-profile .profile-image-options .btn-style-5{
  width: 100%;
}
.connections-middle .menu-style-1 li a{
  border: 1px solid #DBDBDB;
  border-radius: 15px;
  margin-top: 1rem;
  background-color: unset;
  opacity: 1 !important;
  font-weight: 550;
}
.connections-middle .wizard-stepper-block{
  padding: 2rem 0.5rem !important;
  width: unset !important;
}
.share-connection-profile .btn-style-5.mr-3:hover .profile-image-options{
  display: block !important;
    left: -14rem;
    z-index: 55;
    top: 2.9rem;
    width: 25rem;
    border-radius: 25px;
}
.profile-left-content .btn-style-5:hover,.share-connection-profile .btn-style-5:hover{
  position: relative;
  opacity: 1 !important;
}
.profile-progress-completed i {
  position: relative;
  top: -0.45rem;
  right: -13px;
  z-index: 1000;
  background-color: white;
  float: right;
  font-size: 1.6rem;
  font-weight: 500;
  color: #00008A;
  border-radius: 100%;;
}
.profile-middle-content {
    flex-grow: 8;
}
.wizard-stepper-block {
  padding: 1.8rem;
    width: 27.125rem;
    opacity:0;
    visibility: hidden;
    display: none;
}
.wizard-stepper-block.full {
    width: 100%;
}
.wizard-stepper-block.show {
    opacity:1;
    visibility: visible;
    display: block;
}
app-my-documents,app-setting-rolemanagement,app-change-pass {
    width: 100%;
}
.no-documents-found {
    font-size: 1rem;
    color:#1C008A;
    text-align: center;
}
.role-management-container .btn-style-5{
  width: 100%;
  margin-top: 3rem;
  height: 3.4rem !important;
  border-radius: 50px;
}
.dashboard-left .no-documents-found {
    color:#8C8C8C
}
.no-documents-found.center {
    position: relative;
    top: 36%;
    transform: translateY(20%);
}
.load-more {
    text-align: center;
    margin-top: 2rem;
}
.profile-verified-notice {
    font-size: 0.875rem;
    padding:1.375rem 1.25rem;
    background-color: #EBF6FF;
    border-radius: 15px;
    margin-top: 2rem;
}
ul.menu-style-1 li a .fa-check-circle {
  position: absolute;
  z-index: 1;
  font-size: 1.2rem;
  right: unset;
  position: unset;
  margin-right: 1rem;
  color: #1C008A;
}
.profile-verified-notice a {
    color:#1C008A !important;
    text-decoration: underline !important;
}
.connections-middle .profile-verified-notice{
  background-color: #F5F5F5;
    margin-left: auto;
    width: 40%;
    margin-top: -5rem!important;
}
/**profile address**/
.addresses-list {
    width: 100%;
    border-bottom: 0.5px solid #DBDBDB;
    margin-bottom: 1rem;
    display: flex;
    padding-bottom: 2rem;
    grid-gap: 15px;
    flex-wrap: wrap;
}
.make-primary {
  position: absolute;
  bottom: -1.5rem;
}
.make-primary input{
  accent-color: #4d2fc4;
  position: absolute;
}
.user-auth-form .agreement-actions .btn-style-2 {
  background-color: transparent;
}
.wizard-form-container .form-group .mat-form-field .mat-select .mat-select-arrow{
  top: -0.3rem;
    right: 1.8rem !important;
}
.address-item {
    width: 100%;
    padding: 1.25rem 0rem;
    border-radius: 8px;
    flex: 49%;
    position: relative;
}
.address-info {
    float:left;
    max-width: 70%;
    width: max-content;
}
.address-name {
    font-size:1.2rem;
    font-weight: 550;
    margin-bottom: 0.875rem;
    color: #1C008A;
}
.address-actions .action-link {
    margin:0 0.25rem;
    display:inline-block;
    vertical-align: top;
}
.hide-address-form {
    opacity:0;
    visibility: hidden;
    height: 0;
    overflow: hidden;
}
.address-top-search {
    position: relative;
    z-index: 3;
}
.address-top-search .find-location {
    top: 0.1rem;
}
.wizard-stepper-block .btn-style-4 {
    min-width: 17.5rem;
    text-transform: capitalize;
    width: 100%;
}
.address-actions{
  margin-top: 1.5rem;
}
.info-field {
    width:calc(100% - 3.375rem);
    float:left
}
.info-field.full {
    width: 100%;
    position: relative;
}
.contact-info-layer {
    margin-bottom: 0.8rem;
    display: inline-block;
    width: 100%;
}
.profile-contact-info .info-field{
  width: calc(100% - 4.375rem);
}
.profile-contact-info .form-group {
    width: 100%;
    display: inline-block;
    margin-bottom: 0.7rem;
}
.business-descp textarea.form-control{
  height: 200px;
}
.profile-contact-info .form-group label {
    width: 100%;
}
.profile-contact-info .form-group .action-link,.address-actions,.left-actions .btn-style-1,
.agreement-selected-accounts > a, .Float-right-elements{
   float:right
}
.contact-info-layer .form-group.verified,
.contact-info-layer .form-group.not-verified {
    position: relative;
}
.contact-info-layer .form-group.verified .info-field input,
.contact-info-layer .form-group.not-verified .info-field input {
    padding: 0 0 0 3rem
}
.contact-info-layer .form-group.verified .info-field i,
.contact-info-layer .form-group.not-verified .info-field i {
    width: 3rem;
    text-align: center;
    position: absolute;
    top:-0.4rem;
    line-height: 3rem;
}
.contact-info-layer .form-group.not-verified .info-field i {
    color:#E74E5A
}
.contact-info-layer .form-group.verified .info-field i {
    color:#00B6A8
}
.contact-info-layer .form-group .info-field span:not(.iti__country-name):not(.iti__dial-code) {
    position: absolute;
    top: 0.7rem;
    right: 1.1rem;
    color:#1C008A;
    cursor: pointer;
    font-style: italic;
    font-weight: 550;
}
.profile-middle-content .bottom-actions {
    width: 24.625rem;
    padding-left: 2.5rem;
    float: left;
    position: unset;
}
.profile-middle-content .wizard-dropzone .bottom-actions {
    padding-left: 0;
    width: 22.125rem;
}
.profile-middle-content .bottom-actions .btn-style-2 {
    min-width:45%;
}
.profile-middle-content .bottom-actions .btn-style-1 {
    min-width: 50%;
    float:right
}
/**end profile address**/
/**end connections**/
.connections-middle {
    padding:2rem;
    width: 100%;
}
.connections-middle .connection-info{
  max-width: 50%;
}
app-my-profile{
  width: 100%;
}
.share-connection-profile{
  position: absolute;
    right: 5rem;
}
.share-connection-profile .btn-style-5{
  min-width: 8rem !important;
  border: 1px solid #363636 !important;
  color: #363636 !important;
}

.connections-middle .btn-style-5 {
    width: 6.25rem;
    float:right
}
.connections-search {
    float:left;
    width: 100%;
}
.refresh{
  margin-left: auto;
}
.refresh i{
  color: #A3A3A3;
  font-size: 1.1rem;
}
.dashboard-left .heading-4{
  color: #1C008A !important;
  display: flex;
  font-weight: 500;
  margin-bottom: 1rem;
  font-size: 1.1rem;
}
.modal.show .modal-dialog {
    top: 50%;
    transform: translateY(-50%);
}
.modal-actions{
  text-align: center;
}
.sb-circles-dark .sb-wrapper .sb-icon{
  box-shadow: unset !important;
}
.modal-actions .btn-style-1,
.modal-actions .btn-style-2 {
  width: 10.5rem;
    min-width: auto;
    margin-left: 0.625rem;
}
.modal-actions .btn-style-4 {
    min-width: auto;
    padding: 0 1rem;
    border-radius: 25px;
    float: left;
}
.connections-top {
    margin-top: 1.5rem;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    position: relative;
}
.labelstyle-2 a{
  font-size: 2rem;
    color: #1C008A;
}
.notifications-dropdown .labelstyle-2{
  opacity: 1 !important;
}
.sb-circles-dark.sb-group{
 
    flex-wrap: unset !important;
}
.connections-top .btn-style-5 {
  min-width: auto;
  padding: 0 1rem;
  border-radius: 20px;
  position: absolute;
  top: -5rem;
  width: unset !important;
  right: 0;
}
.connections-list {
    width: 100%;
    display: flex;
    grid-gap: 15px;
    flex-wrap: wrap;
}
.add-list-scoller {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}
.tutorials-carousel .carousel{
  overflow: visible !important;
}
.connection-item {
    padding:1.25rem;
    background-color: #fff;
    -webkit-box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.13); 
    box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.13);
    margin-top: 1.2rem;
    border-radius: 8px;
    flex: 49%;
    max-width: 49%;
}
.carousel-slide-content{
  position: unset !important;
}
.connection-item.cursor {
    cursor: pointer;
}
.connection-item-right {
    margin-left:4.2rem
}
.connection-photo,
.connection-name,
.connection-phone {
    cursor: pointer;
}
.connection-photo {
    width: 3.125rem;
    height: 3.125rem;
    overflow: hidden;
    border-radius: 50%;
    float: left;
}
.connection-photo img {
    width: 100%;
    height: 100%;
}
.connection-info {
    float: left;
    max-width: 60%;
}
.connection-name {
    font-size:0.875rem;
    color:#000000;
    display: inline-block;
    vertical-align: top;
    font-weight: 500;
    padding-top: 0.2rem;
    position: relative;
}
.connection-name span {
  position: absolute;
  top: 0.2rem;
  left: 9rem;
  z-index: 10;
  font-style: italic;
  color: #300fb4;
}

.connection-phone {
  width: 100%;
  font-size: 0.8375rem;
  color: #CDCACA;
  display: inline-block;
  vertical-align: top;
  margin-right: 1rem;
  font-weight: 500;
  margin-top: 0.2rem;
}
.connection-actions {
    font-size:2rem;
    color:#000000;
    position: relative;
    float:right
}
.connection-actions .fa-ellipsis-h{
  transform: rotate(90deg);
  color: #1C008A;
  opacity: 0.2;
}
.connection-actions .action-link {
    margin:0 0.2rem
}
.connection-actions-options {
    width: 23rem;
    background-color: #fff;
    position: absolute;
    top: 100%;
    right: -2.5rem;
    z-index: 10;
    padding: 1rem 0;
    border: 0.5px solid #CDCACA;
    border-radius: 25px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition-property: all;
    -webkit-transition-duration: 0.5s;
    -webkit-transition-timing-function: linear;
    -webkit-transition-delay: 0s;
    -ms-transition-property: all;
    -ms-transition-duration: 0.5s;
    -ms-transition-timing-function: linear;
    -ms-transition-delay: 0s;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: linear;
    transition-delay: 0s;
}
.connection-actions:hover .connection-actions-options {
    opacity: 1;
    visibility: visible;
}
.connection-actions-options a {
  font-size: 0.875rem;
    color: #000000 !important;
    width: 100%;
    font-weight: 500;
    display: inline-block;
    vertical-align: top;
    padding: 0 1.8rem;
}
.connection-actions-options a span.con-name{
  display: unset;
    border-bottom: unset;
    width: unset;
    vertical-align: unset;
    padding: unset;
}
.connection-actions-options a span {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    border-bottom: 0.5px solid #cdcaca73;
    padding:1rem 0;
}
.connection-actions-options a.warning {
    color:#E74E5A !important;
}
.connections-middle .connections-list .connection-actions-options{
  border: unset;
  box-shadow: 1px 2px 10px 0px rgb(0 0 0 / 10%);
  border-radius: 40px;
  padding: 0;
}
.connection-actions-options a:last-child{
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
}
.connection-actions-options a:nth-child(1){
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}
.connection-actions-options a:hover,
.connection-actions-options a.warning:hover {
    background-color:#1C008A;
    color:#fff !important
}
.connection-actions-options a.active,
.connection-actions-options a.warning.active {
    background-color:#1C008A;
    color:#fff !important
}
.connection-actions-options a:last-child span {
    border-bottom: none;
}
.filter-actions a {
    font-size: 0.875rem;
    color: #1C008A !important;
    padding: 0.2rem 0;
    width: 100%;
    display: inline-block;
    text-decoration: underline !important;
    margin-bottom: 0.2rem;
}
.connection-tab  {
    margin-left: 2rem;
}
.dashboard-left ul li input[type="checkbox"] {
    float:left;
    margin-top: 0.5rem;
}
.dashboard-left ul li.connection-li input[type="checkbox"] {
    margin-top: 1.5rem;
    margin-left: 0;
}
.dashboard-left ul li input[type="checkbox"] {
    margin-left: 0;
}
.dashboard-left ul li .connection-tab {
    margin-left: 0;
}
.dashboard-left ul li .connection-tab .connection-info {
    padding-left:0.4rem;
    max-width: 70%;
    margin-left: 0.5rem;
}
.connection-tab .connection-photo {
    float:left;
    margin-right: 0;
}
.import-container {
    flex-direction: row;
    padding: 0.5rem 2.5rem;
    position: relative;
}
.import-container .profile-progress-completed{
  background-color: #1C008A;
}
.import-container .note {
    position: absolute;
    top: 110%;
    left:0;
    z-index: 10;
    color:#363636;
    font-size:0.75rem;
}
.wizard-dropzone .form-group label {
    color:#363636
}
.import-container .profile-progress-bar {
    margin-top: 0;
    border-radius: 20px;
    background-color: #1c008a36;
    border: unset !important;
    height: 0.5rem !important;
}
.import-container.left {
    justify-content: left;
}
.right-sidebar-shadow {
    width: 100vw;
    height: 100vh;
    background-color:rgba(0,0,0,0.4);
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9;
    display: none;
    opacity:0;
}
.right-sidebar {
  width: 30vw;
  background-color: #fff;
  position: fixed;
  left: 115%;
  top: 0;
  z-index: 10;
}
body.show-import-sidebar .right-sidebar-shadow {
    display: block;
    opacity:1;
}
body.show-import-sidebar .right-sidebar {
  left: 70%;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding: 4rem;
  height: 100vh;
}
.right-sidebar .btn-style-4{
  width: 100%;
}
.right-sidebar .modal-actions{
  position: absolute;
  bottom: 3rem;
  left: 3rem;
  right: 0;
}
.statistics-block {
    font-size:0.75rem;
    line-height: 2;
}
.left-actions .btn-style-1,
.left-actions .btn-style-2 {
    min-width: 49%;
}
/**end connections**/
ul li label input[type="checkbox"] {
    margin-right: 0.5rem;
    margin-top: -0.2rem;
    cursor: pointer;
}
/**Agreement**/
.form-group label.list-heading {
    width: 100%;
    padding: 1rem;
    background-color: #f8f8f8;
    border-radius: 8px;
}
.matensa-search-list {
    max-height: 200px;
    overflow-y: auto;
    list-style: none;
}
.agreement-info-step,
.agreement-value-container {
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
}
.agreement-info-step.show,
.agreement-value-container.show {
    height: max-content;
    opacity: 1;
    visibility: visible;
}
/**End Agreement**/
/****END DASHBOARD****************************************************************/
@media screen and (min-width:960px) {
    /****USER AUTH************/
    .user-auth-left {
        top: 0;
        left: 0;
        z-index: 1;
        min-height: calc(100VH - 8rem);
        margin-top: 8rem;
    }
 
    .user-auth-right {
      display: flex;
      min-height: 35rem;
      align-items: center;
      padding: 0rem 5rem;
      height: 75%;
      z-index: 3;
    }
    .user-auth-form {
        height: calc(100% - 50px); 
        padding-bottom: 100px;
    }
    .user-auth-right.contactus-page .user-auth-form {
        padding-bottom: 115px;
    }
    .contactus-page .user-auth-top {
        position: absolute;
        width: 100%;
    }
    .agm-map-container-inner {
      border-top-right-radius: 20%;
      border-bottom-right-radius: 20%;
      width: 80% !important;
    }
    .user-auth-top {
        width: 100%;
        position: absolute;
        top:0;
        right:0;
        z-index: 2;
        height: 1px;
    }
    .user-auth-top .right-layer {
        padding: 2.5rem 2.5rem 0 2.5rem;
    }
    .user-auth-top .left-layer .app-logo {
      position: absolute;
        top: 2rem;
        left:4.7rem;
        z-index: 2;
    }
        .authentication-design {
          position: absolute;
          right: 1rem;
          top: 15rem;
          height: 35rem;
        }
    body:not(.has-scroll) .bottom-actions {
      position: fixed;
      z-index: 2;
      margin-top: 1.5rem;
    }
    .welcome-container,
    .onboarding-container {
      height: 84vh;
    }
    .welcome-bottom-layer {
      width: 80%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
    /****END USER AUTH************/
    /****WIZARD************/
    .add-account .heading-layer {
        margin-bottom:2.875rem;
    }
    .wizard-left {
        width: 22rem;
        height:100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
    }
    .wizard-content {
        padding:4rem 8rem;
        display: inline-block;
        vertical-align: top;  
    }
    .wizard-right {
      margin-left: 3rem;
        min-height: 100vh;
    }
    .steps-container {
        position: relative;
        top:50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .step-circle {
        padding-bottom: 1.5rem;
    }
    .step-circle .step-tab { 
      padding-left: 5rem;
    }
    .step-circle:after {
        position:absolute;
        top: 1rem;
        left: 0.4rem;
    }
    body:not(.has-scroll) .wizard-pagination {
        position: fixed;
        bottom: 1.5rem;
        left: 30rem;
        z-index: 2;
    }
    body:not(.has-scroll) .wizard-pagination button.float-right {
        position: fixed;
        bottom: 1.5rem;
        right: 5rem;
    }
    .wizard-pagination .btn-style-4 {
        position:fixed;
        bottom: 7%;
        left: 4.4rem;
        z-index: 1;
    }
    .map-container {
        width: 100%;
        float: left;
    }
    .document-modal-popup .modal-body {
        padding:0 !important
    }
    .modal-body{
      padding: 0rem 2rem 2.5rem !important;
    }
    .myCustomModalClass.document-modal-popup .modal-dialog {
        width: 100%;
        height: 100%;
        max-width: 100%;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .myCustomModalClass.document-modal-popup .modal-content {
        background: none;
        border:none;
        width: max-content;
        max-width: max-content;
    }
    .myCustomModalClass.document-modal-popup .modal-header {
        padding:0;
        height:0;
        position: relative;
        border:none
    }
    .myCustomModalClass.document-modal-popup .modal-header .close {
        position: absolute;
        top: -11px;
        right: -6px;
        z-index: 10;
    }
    /****END WIZARD************/
}
@media screen and (max-width:960px) {
  .alertify-custom-msg .msg{
    max-width: 80%;
  }
  .user-auth-right .btn-style-1{
    min-width: 80% !important;
  }
  .dashboard-middle-container {
    padding: 0.8rem !important;
}
.user-dashboard button.mat-icon-button::after {
  line-height: 1 !important;
}
  .modal.show .modal-dialog {
    transform: translateY(-40%);
}
  .alertify-custom-msg .msg-close{
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
    .ddl-rectangle {
        width: 4.4375rem !important;
    }
    /****USER AUTH************/
    .user-auth-top {
        padding:1rem 0 0;
        max-width: 90%;
        margin:0 auto
    }
    app-wizard .user-auth-top {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
    }
    .wizard-inquiry .question-icon {
        top: 0.8rem;
        right: 6rem;
    }
    .user-auth-left,
    .user-auth-right {
        width: 100%;
        display: inline-block;
        vertical-align: top;
    }
    .user-auth-right {
      width: 100% !important;
      background-color: unset !important;
      box-shadow: unset !important;
      left: 0;
      padding: 0rem 2rem;
      margin-top: -3rem;
      padding-bottom: 2rem;
    }
    .add-account .wizard-app-container{
      padding-top: unset !important;
    }
    .add-account .bottom-actions .btn-style-2.small, .add-account .bottom-actions .btn-style-1.small{
     min-width: 45% !important;
  }
  .View-agreement-container .label-style-1{
    text-align: left;
  }
    .back-button {
      line-height: 1.6 !important;
      left: unset !important;
      top: 8rem;
  }
  .template-form .danger {
    margin-top: unset !important;
    margin-bottom: 2rem;
}
  .user-auth-form .back-button {
    top: 12.3rem !important;
}
.inProgress {
display: none;
}
.document-block.Multiple:hover .multiple-docs {
  display: block!important;
}
.document-block.Multiple:hover .multiple-docs div {
  margin-bottom: 1rem;
}
.documents-form-container .documents-list .document-block{
  margin-top: 1rem;
  width: unset !important;
  padding-top: 1.2rem !important;
  height: 100px !important;
}
.documents-form-container .Add-doc-block{
  width: 45% !important;
  padding-top: 1rem !important;
  font-size: unset !important;
  height: 100px !important;
}
.right-sidebar .modal-actions {
  bottom: 7rem !important;
  left: 0rem !important;
}
.confirm-payment .headlines {
  right: unset !important;
}
.View-agreement-container .confirm-payment .cancelPay i {
  left: 0.35rem !important;
  top: 0.2rem !important;
}
 .View-agreement-container .confirm-payment.Agreements-rightpart .col-3 {
  min-width: 3rem !important;
}
.confirm-payment .settings-bottom-buttons{
  margin-left: unset !important;
}
.confirm-payment .settings-bottom-buttons button{
  min-width: 15rem !important;
  margin-bottom: 1rem;
}
.confirm-payment .heading-6{
  margin-top: unset !important;
}
.confirm-payment{
  margin-top: unset !important;
  padding: unset !important;
}
.middle-actions.agreement-actions .btn-style-2 {
  min-width: 10.25rem !important;
  margin-left: 1rem !important;
}
    .welcome-bottom-layer {
        position: relative;
    }
    .welcome-container .welcome-block,
    .onboarding-container .onboarding-carousel {
        margin-top: 2rem;
    }
    .security-message {
        left:0;
        right: 0;
        margin:0 auto;
        top: inherit;
        bottom: 1rem;
        transform: translateY(0);
    }
    .user-auth-left,.authentication-design,.step-2-info,.step-3-info,.step-1-info{
      display: none;
   }
   .wizard-container.user-auth-right {
     width: 100% !important;
     top: 8rem;
   }
   .wizard-container.user-auth-right .user-auth-form .agreement-actions button {
     min-width: 40% !important;
   }
   .form-group .invalid-feedback {
     position: unset !important;
   }
   .wizard-inquiry {
     top: 0rem;
     right: 0rem;
   }
   .user-auth-form .agreement-actions {
     bottom: unset !important;
     position: unset !important;
   }
   .documents-form-container .documents-list{
    width: 100% !important;
    margin-top: unset !important;
   }
   .documents-form-container .documents-list .relative-position{
   width: 45%;
   }
   .user-auth-form .address-actions {
   float: unset !important;
}
   app-login .user-auth-form, app-forgot-password .user-auth-form, .verification-code-input {
     width: 100% !important;
   }
    /****END USER AUTH************/
    /****WIZARD************/
    .wizard-left,
    .wizard-right {
        width: 100%;
    }
    .wizard-left {
        overflow-x: auto;
    }
    .wizard-container {
        padding-bottom:2rem
    }
    .wizard-app-container {
        padding-top:2rem
    }
    .wizard-form-container,
    .address-form-container,
    .documents-form-container {
        float: none;
        margin: 0 auto;
    }
    .steps-container {
        width: max-content;
        max-width: max-content;
        padding: 7rem 0 1.5rem;
    }
    .steps-container app-wizard-step {
        display: inline-block;
        vertical-align: top;
        width: 5rem;
        margin: 0 1rem;
    }
    .wizard-pagination {
        max-width: 90%;
        width:22rem;
        margin:0 auto;
        text-align: center;
    }
    .document-block input[type="checkbox"] {
      margin-top: -0.3rem !important;
      left: 0.2rem !important;
  }
   .agreement-value .mat-form-field {
    width: 100%;
  }
    .map-container {
        width:100%;
    }
    app-professional-info .wizard-form-container {
      width: 100% !important;
  }
  .social-media-item {
    width: 100%;
}
.step-circle .step-tab {
 color: black;
}
.step-number {
  margin: auto;
  position: unset !important;
  left: unset !important;
}
.step-name{
  margin-top: 1rem;
  font-size: 0.9rem;
}
.step-tag{
  display: none !important;
}
.contactus-page .user-auth-form, .register-form {
  margin-top: 0rem !important;
}
.user-auth-right .steps-container{
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-x: scroll; 
}
 .steps-container::-webkit-scrollbar{
   display: none; /* for Chrome, Safari, and Opera */
}
 .steps-container {
  position: unset !important;
  top: unset;
  left: unset;
  overflow-x: auto;
  grid-gap: 1.5rem;
  text-align: center;
  padding: unset;
  overflow-y: hidden;
    min-height: 7rem;
  width: unset !important;
  display: flex;
  margin-top: 3rem;
}
    .wizard-pagination .btn-style-1 {
        max-width: 55%;
    }
    .wizard-pagination .btn-style-2 {
        max-width: 40%;
        min-width: 8.6rem;
    }
    .wizard-pagination .btn-style-4 {
        margin-top: 1rem;
    }
    /****END WIZARD************/
}
.add-account .btn-style-2.small{
  min-width: 12rem !important;
margin-bottom: 1rem;
margin-top: 3rem;
}
@media screen and (max-width:480px) {
    /****WIZARD************/
    .social-media-connect label {
        width: auto;
        margin-top: 0.8rem;
    }
    .social-media-connect .input-search-zoom {
        top: 0.6rem;
    }
    .social-media-connect .btn-style-1 {
        float: right;
    }
    /****END WIZARD************/
}
/* Eman Style */
/* NAVBAR */
.nav-header {
  padding-left: 3.5rem;
    padding-right: 2.5rem;
    padding-top: 1.5rem;
  }
  .navbar-toggler{
    filter: grayscale(100%);
  }
  .navbar-toggler-icon{
    background-image: url(../images/toggler.png);
  }
  .change-background{
    background-image: url(../images/close.jpg) !important;
  }
  .dashboard-navbarLink {
  color: #363636;
  font-size: 1rem;
  opacity: 85%;
  }
  .dashboard-navbarLink:hover{
    color: #1C008A !important;
    text-decoration: none !important;
  }
  .navbar-brand img{
    width: 90%;
  }
  .navbar-brand{
    margin-left: 7rem;
  }
  .navbar-primary-list {
    list-style-type: none;
    display: flex;
    margin-bottom: 0px;
    margin-left: 6rem;
    margin-top: 1rem;  
  }
  .user-dashboard-middle .cancel-payment{
    bottom: 1% !important;
  }
  .user-dashboard-middle .payments-listing span.invited,.user-dashboard-middle .payments-listing .invoice-date {
   left: unset !important;
}
.donut {
  margin: auto;
}
  .navbar-primary-list a{
    padding: 0.5rem 1rem;
  }
  .searchforInvoice.agreement-info2{
    width: 85%;
    margin: auto;
  }
  .transactions-details{
    margin-top: 5rem;
  }
  .Payments-Details.transactions-details .AmountsTableRows td.col-md-4{
    border-bottom: unset !important;
  }
  .navbar-primary-list .active{
    border-bottom: 3px solid;
    color: #1C008A !important;
    opacity: 100%;
  }
  .agr-invoices .Payments-Actions .confirm-payment .back-button {
    display:none;
}
  .navbar-icons .navbar-list{
    display: inline-block;
    width: 3rem;
    height: 3rem;
    border-radius: 40px;
    text-decoration: none;
    font-size: 1.5rem;
    text-align: center;
    margin: 0.5rem;
  }
  .navbar-icons:hover{
    background-color: white;
    z-index: 3333;
    border-radius: 50px;
    margin: 0.5rem;
  }
  .navbar-icons:hover a i{
  color:#1C008A;
}
.navbar-icons .share-connection-profile{
  padding-top: 1.6rem;
}
.navbar-icons:hover .navbar-list{
margin: 0rem !important;
}
  .fas-icon {
    color: #363636;
    margin-top: 0.3rem;
    line-height: unset;
  }
  .navbar-settings .fas-icon{
    font-size:1.43rem;
    color: #1C008A;
    margin-left:0.85rem;
  }
  .Dropdown-inner {
    padding:0 2rem;
  }
  .navbar-settings .Dropdown-inner ,.navbar-notifications .Dropdown-inner{
    padding-bottom: 1rem;
  }
  .navbar-settings .navbar-labelstyle2{
  width: 100%;
  }
  .navbar-settings .navbar-accounts{
    text-align: center;
  }
  .navbar-Dropdown{
    min-width:25rem;
    margin-right: 0;
    right: 0;
    z-index: 100;
    position:absolute;
    display: block;
    padding-top: 1.6rem;
  }
  .navbar-settings .navbar-Dropdown {
    right: -4.4em;
    min-width: 15rem !important;
  }
  .navbar-iconslist li .navbar-Dropdown{
    opacity:0;
    visibility: hidden;
 }
   .navbar-iconslist li:hover .navbar-Dropdown {
     opacity:1;
     visibility: visible;
 }
 /* .blockSuggestion img{
  max-width: 80%;
 } */
 .MyPayments.choose-account-container .confirm-payment{
  padding: 0;
  margin-top: 1rem;
 }
 .MyPayments.choose-account-container .confirm-payment .back-button{
 display: none;
 }
 .note-title{
  color: #FFA72E;
  font-weight: 600;
  font-size: 16px;
  border-top: 0.8px solid #80808047;
  padding-top: 1.5rem;
 }
 .navbar-notifications .navbar-Dropdown{
  right: -12.7em;
  }
  .navbar-settings .Dropdown-inner{
   border-top: unset;
  }
  .navbar-accounts {
    position: relative;
    display:flex;
    padding:1rem 0;
}
 .navbar-AccountProfiles{
    padding-left:4.8rem;
  }
 .navbar-AccountProfiles .navbar-accounts {
    position: relative;
}
.navbar-AccountProfiles .navbar-accounts:before {
    position: absolute;
    left: -3.2rem;
    top: 1rem;
    content: "";
    width: 3rem;
    height: 2rem;
    background: url('../images/permission-line.svg') no-repeat left center;
}
.Dropdown-content{
    padding-bottom: 1rem;
    background-color: #fff;
    border-radius: 40px;
    box-shadow: 1px 1px 8px 0px rgb(0 0 0 / 13%);
    display: inline-block;
    width: 100%;
 }
 .Dropdown-content .btn-style-5{
    width:100%;
    justify-content: center;
    line-height: 2.6rem;
  }
 .navbar-settings .Dropdown-content{
   padding-bottom: 0rem !important;
  }
  .navbar-iconslist{
    display:flex;
    margin-bottom:unset !important;
    margin-top: 0.5rem;
    list-style-type: none;
  }
  .navbar-iconslist li {
    position: relative;
}
   .navbar-responsive .navbar-Dropdown{
    min-width: 20rem !important;
    right:5%;
    padding-top:1rem;
    }
    .navbar-opened .navbar-Dropdown{
         display:block !important; 
    }
    .navbar-responsive  .navbar-primary-list,.btn-unblock span{
    display:unset;
    }
    .navbar-responsive .dashboard-navbarLink{
      font-weight: 500;
    }
    .navbar-responsive .dashboard-navbarLink:hover{
      opacity: 0.5;
    }
    .navbar-responsive li{
        margin-left:-0.5rem !important;
        margin-bottom: 2rem;
        padding-left: 1rem;
    }
    .Dropdown-header{
        padding:0.9rem 2rem;
        border-top-right-radius:20px;
        border-top-left-radius:20px;
        display:flex;
     }
     .navbar-labelstyle1{
        color:white;
        font-size:0.9375rem;
        float:left;
        padding-left:1rem;
        padding-top: 0.8rem;
       }
       .navbar-labelstyle2{
        font-size:0.9375rem;
        margin-left:0.8rem;
        float:left;
        padding-top: 0.7rem;
       }  
   .navbar-labelstyle3{
    font-size:0.9375rem;
    margin-left:1rem;
    margin-top:0.2rem;
    float:left;
   }
   .Invoice-details-text2{
     font-size: 2rem;
     font-weight: 500;
   }
   .navbar-labelstyle4{
    font-size:0.9375rem;
    margin-left:1rem;
    float:left;
   }
  hr {
    margin-left:5px;
    margin-right: 5px;
    background-color:#cdcaca96;
    opacity: 0.5;
    margin-top: 0;
    margin-bottom: 0;
  }
  .BlueLine{ 
    background-color:#1C008A;
    opacity:1;
    margin:0;
    margin-bottom:1rem;
  }
  .Dropdown-inner-profiles{
    margin:0 2rem;
    border-bottom:1px solid #cdcaca;
    border-top:1px solid #cdcaca;
  }
  .navbar-btnstyle1{
    justify-content: center;
    border:none;
    background-color: white;
    margin: 0.5rem 0.3rem;
    font-size:0.9375rem;
    float:left;
    border-bottom: 0.2px solid #cdcaca80;
    padding-bottom: 1.5rem;
    width: 100%;
    text-align: left;
  }
  .navbar-btnstyle1:hover{
    opacity:0.7;
  }
  .navbar-btnstyle1 .fas {
    font-size: 1.125rem;
  }
  .navbar-responsive-settings:hover ul,
  .navbar-responsive-profiles:hover ul {
    display: block;
    margin: 0.2rem;
  }
  .navbar-responsive-settings ul li a,
  .navbar-responsive-profiles ul li a {
    color: #1C008A;
    font-size: 1rem;
  }
   /* REMINDERS LIST */
   .notifications-dropdown .fas{
    font-size:1.25rem;
  }
  .new-notification{
    position: absolute;
    right: 0.7rem;
    /* top: 0rem; */
    bottom: 1.7rem;
    text-align: center;
    border: 1px solid red;
    border-radius: 50%;
    font-size: 0.55rem;
    width: 12px;
    height: 12px;
    background-color: red;
    color: white;
  }
  .notifications-dropdown{
    padding: 1.5rem 2rem 1rem 0rem;
  }
  .notifications-dropdown .navbar-labelstyle2{
    font-weight: 550;
  }
  .notifications-dropdown .heading-5{
    position: absolute;
    top: 3rem;
    right: 2rem;
    font-weight: 500;
    font-size: 0.9rem;
  }
  .notification-filters{
    float:right;
    margin-top:0.2rem;
    display:flex;
    margin-right:0.2rem;
  }
  .notification-block .View-agr-options,.notification-filters .View-agr-options {
    font-weight: unset !important;
  }
  .notification-filters .View-agr-options{
    left: unset !important;
    top: -3rem !important;
    color: #1C008A !important;
  }
  .notification-filters .connection-actions-options{
      top: 130%;
      right: -3rem;
      padding: 1rem 0;
      text-align: left;
      border: unset;
      box-shadow: 1px 1px 8px 0px rgb(0 0 0 / 13%);
  }
  .notification-filters .headingstyle1{
    margin-bottom: 1rem;
  }
  .notification-filters .Clear-All {
    top: 1rem;
    right: 1rem;
    position: absolute;
}
.notification-filters .Clear-All i{
  color: #FFA72E;
}
  .notification-filters .list-1{
    list-style-type: none;
    margin-right: 2rem;
      margin-left: -1rem;
  }
  .notification-filters ul.list-1 input[type="checkbox"]:checked:before{
    border: 1px solid black;
  }
  .notification-filters .list-1 li label{
    margin-top: 0.5rem;
  }
  .notification-filters .list-1 li{
    font-size: 1rem;
    color: black;
    padding: 0.5rem 0rem;
  }
  .notification-title{
    position: absolute;
    bottom: 6.2rem;
    left: 0.5rem;
  }
  .notification-time{
    color: #363636;
    font-size: 0.9rem;
    margin-top: 0.3rem;
    opacity: 75%;
  }
   .notification-block .connection-actions-options{
     right: -1rem;
     padding: unset;
   }
   .notification-block .connection-actions-options a:first-child:hover{
   border-top-left-radius: 25px;
   border-top-right-radius: 25px;
}
.notification-block .connection-actions-options a:last-child:hover{
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}
   .notification-block .image{
    width: 100%
   }
  .notification-block .connection-actions-options{
    display: block;
  }
  .notification-block .settings-bottom-buttons {
    margin-top: 10px;
  }
  .notification-block button{
    height: 2.87rem;
      min-width: 8rem;
      line-height: 2rem;
  }
  .notification-block .View-agr-options{
    left: 90% !important;
    position: absolute;
    font-size: 1.9rem !important;
    color: #1c008a30 !important;
  }
  .notifications-dropdown .menu-style-2{
    width: 50%;
    height: unset;
  }
  .notifications-dropdown .menu-style-2 a{
    padding: 0.5rem 1rem;
  }
  .notifications-dropdown .menu-style-2 a li{
    font-size: 0.8rem !important;
  }
  .notification-block .View-agr-options i{
   opacity: 0.5;
   color: #1c008a85;
  }
  .notification-block .fas{
    color: #1C008A;
    font-size: 0.7rem;
    line-height: 3rem;
  }
  .row .upper-title-1 i{
    left: -0.5rem !important;
  }
  .view-agr.connections-search{
    float: unset;
  }
  .Notifications-container table{
    width: 100%;
  }
  .Notifications-container .profiile-flex-container{
    width: 85%;
    margin: auto;
    display: revert;
  }
  .adjust:hover .profile-image-options{
 visibility: visible;
 opacity: 1;
    top: 2rem;
    font-size: 1rem;
  }
  .Notifications-container .profiile-flex-container .adjust{
    font-size: 1.8rem;
    color: #A3A3A3;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  .Notifications-container .notification-block .View-agr-options{
    left: 97% !important;
  }
  .Notifications-container .Dropdown-content{
    border: 1px solid #DBDBDB;
    border-radius: 25px;
    box-shadow: unset;
  }
  .Notifications-container .notifications-content{
    padding-top: 1rem;
  }
  .Notifications-container .notifications-dropdown{
    display: none;
  }
  .Notifications-container.dashboard-container{
    width: 100%;
  }
  .notification-block{
    background-color: #F1EFFB;
    font-size: 0.9rem;
    width: 100%;
      text-align: left;
      padding: 1rem 1rem;
      position: relative;
      display: flex;
  }
  .mutual-contact{
    color:#979797;
  }
  .notification-block-white{
    background-color: white;
  }
  /* CONNECTION PROFILE */
  .connections-profile-actions{
    margin:2rem 0.5rem;
    grid-gap:0.5rem;
  }
  .connections-profile-actionlabels{
    color:#000;
    margin-top:0.7rem;
    font-size: 0.75rem;
  }
  .profile-socialmedia {
    display: unset !important;
  }
  .label-style-6 .Decline-accept-actions{
    margin-top: 1rem !important;
  }
  .connection-info-container{
    margin: 2rem;
    display:flex;
  }
  .btn-unblock{
    width:80%;
    display:unset !important;
    margin-top:5px;
  }
  .connection-labelstyle1{
    font-size: 1rem;
    font-weight: 550;
    color: #1C008A;
    opacity: 85%;
  }
  .connection-labelstyle2{
    font-size:1rem;
    color: black;
    font-weight: 500 !important;
  }
  .Payment-sched.connections-middle .menu-style-1 li a{
    background-color: #d8d5e563 !important;
    padding: 1.5rem !important;
  }
  .Payment-sched  .UpperFilter.Agreements-rightpart ul.list-types {
    width: 35% !important;
}
.Payment-sched  .UpperFilter.Agreements-rightpart ul.list-types li{
  padding: 0.5rem;
  margin-right: 1rem;
  cursor: pointer !important;
}
  .Payment-sched.connections-middle .menu-style-1 li .fa-chevron-up,.Payment-sched.connections-middle .menu-style-1 li .fa-chevron-down{
    right: 3rem;
    top: 1.5rem;
  }
  .connections-MoreActions{
    text-align: center;
    height: 80%;
  }
  .connections-MoreActions a{
    width: 80%;
    margin-top: 40%;
  }
  app-more-actions{
    width: 50%;
    margin: 0 auto;
  }
  app-connection-info{
    width: 100%;
  }
  .connections-MoreActions .btn-style-5 span,.Connection-profileInfo .Decline-accept-actions .btn-style-5 span,
  .connection-blocked,.blockedaccounts-settings .btn-style-5 span{
    margin:auto;
  }
  .connections-MoreActions img{
    width: 40%;
  }
  .connections-MoreActions .connections-info-left, .connections-MoreActions .btn-style-5 , .wizard-stepper-block.full{
    width: 100%;
  }
  .connections-mainInfo,
    .connections-MoreActions {
      margin-top: 1rem;
      padding: 1rem;
      background-color: white;
      border-radius: 8px;
    }
    .connections-mainInfo .connections-info-right {
      margin-left: 30%;
      }
      .connections-mainInfo .connections-info-left {
      margin-top: 2rem;
      }
      .haveNotif .dashboard-SvgLabels{
        font-weight: 650;
        color: black;
      }
      .haveNotif{
        position: relative;
      }
            .payment-details-link {
              margin: auto;
              width: 80%;
              margin-top: 5rem;
            }
      .haveNotifdot{
        background-color: #E72F3E;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        position: absolute;
        top: 3.5rem;
        right: -0.8rem;
      }
      .info-block2{
        display: flex;
        margin-bottom: 1rem;
      }
      .info-block .connection-photo{
        background-color: #EFEFEF;
      }
      .info-block img{
        width: 50%;
        height: 50%;
        margin-top: 0.7rem;
        margin-left: 0.8rem;
      }
      .info-block2 .connection-info-item{
        line-height: 3;
        margin-left: 0.5rem;
      }
      .Connection-profileInfo .dashboard-left{
        width: 25.44rem;
      }
       .profile-left-menu{
        margin-top: 1rem;
      }
      .Connection-profileInfo .btn-style-3 {
        min-width: 8rem;
        width: 100% !important;
      }
      .Connection-profileInfo ul{
        background-color: #F5F5F5;
        min-height: 30rem;
      }
      .cantConnect .btn-style-5{
        opacity: 0.2;
        height: 3.5rem !important;
      }
      .urBlocked{
        width: 40%;
        text-align: center;
        margin: 10rem auto;
      }
      .cantConnect a{
        text-decoration: underline;
        color: #1C008A;
        font-weight: 550;
      }
      .Connection-profileInfo .Decline-accept-actions .btn-style-6{
        height:3.5rem;
        width: 100% !important;
      }
      .Connection-profileInfo .new{
        margin: auto;
      }
      .Connection-profileInfo .btn-style-3{
        background-color: #26BA87;
        background-image: linear-gradient(to right, #197E77 0%, #1F9B7F 51%, #26BA87 100%);
      }
   /* HELP SECTION */
.helpsection-leftmenu {
        min-height: 35rem;
        margin-left: -3rem;
        width: 129%;
        align-items: center;
        justify-items: center;
    }
.helpsection-leftmenu ul li{
  min-height: 5.9rem;
    justify-content: center;
    padding: 2rem;
    font-size: 1rem;
    font-weight: 500;
    padding: 1rem 4.5rem;
}
.helpsection-leftmenu ul li.active{
   background-color:#D8D5E5;
}
 .helpsection-main-page .connections-search{
   float: unset;
   width: unset;
 }
 .helpsection-main-page .dashboard-middle{
    margin-left:2.81rem;
    box-shadow: unset;
    background-color: unset;
    margin-top: 1rem;
    display:unset;
}
.fixed-helpMessage{
  position: fixed;
  bottom: 2%;
  left: 3%;
  height: 5.5rem;
  width: 13rem;
  align-content: center;
}
.helpsection-main-page .btn-style-10{
    width:unset;
   float: unset;
   position: unset;
   margin-top:0.2rem;
}
.helpsection-questions-header{
  font-size:0.875rem;
  opacity: 85%;
}
.payment-value{
  font-size: 0.8rem;
}
.payments-listing .connection-item{
  padding: 0.5rem !important;
}
.notification-block .connection-info{
  max-width: 90% !important;
  cursor: pointer;
}
.agr-documents .document-block input[type="checkbox"] {
margin-top: unset !important;
}
.Notifications-container .notification-block .image{
  width: 100%;
}
.Connection-profileInfo .dashboard-grey{
  min-height: unset !important;
}
.helpsection-questions-block{
    width: 100%;
    padding: 1rem 0rem;
    cursor: pointer;
    border-bottom: 0.5px solid #cdcaca96;
}
.helpsection-questions-icons{
  float:right;
  color:#CDCACA;
  margin-left: auto;
}
.helpsection-answers{
  color: #66737aa6;
  font-size:0.875rem;
}
  /* SETTINGS */
  .notification-settings .profile-middle-menu{
    min-width:20rem;
  }
  .Email-notifications .black-labels{
    padding-top:0.5rem;
    padding-left:0.6rem;
  }
   .main-profile-settings .dashboard-settings-left ul{
        margin-left:-1.8rem !important;
    }
   .main-profile-settings .dashboard-settings-left ul li a{
    margin-left:1.5rem;
    }
   .main-profile-settings .dashboard-left ul li{
        border-bottom: unset;
    }
    .privacy-security .profile-middle-menu {
      max-width: 16rem !important;
      min-width: 15.5rem;
      padding:2rem 1.25rem;
  }
  .blockedaccounts-settings .btn-style-6{
    width:6rem;
    height:unset;
    margin-left: auto;
  }
  .blockedaccounts-settings .connection-item{
    max-width:49% !important;
    display:flex;
    box-shadow: unset;
    border: 1px solid #DBDBDB;
    border-radius: 10px;
  }
  .blockedaccounts-settings .btn-style-5{
    margin-bottom: unset;
    height:56px !important;
  }
  .blockedaccounts-settings .connection-phone{
    margin-top: 0.3rem;
  }
  .settings-bottom-buttons-modal .btn-style-2 , .settings-bottom-buttons-modal .btn-style-1{
    min-width:10rem;
    margin-top:1rem;
}
  .blockedaccounts-settings .settings-info-container{
    padding: 1rem 1rem;
  }
  .blockedaccounts-settings .connection-info{
      margin-left:1rem;
  }
  .privacyAndSecurity .connection-info-container{
    margin: 1.5rem;
    margin-right:0rem;
  }
  .privacyAndSecurity{
    margin-top: 1rem;
  }
  .privacyAndSecurity .connections-info-right{
    margin-left:1.8rem;
    width: 50%;
  }
  .privacyAndSecurity .connections-info-left {
    margin-bottom: 3rem;
    width: 50%;
    padding-right: unset;
    border-right: 1px solid #CDCACA;
    padding-right:1.8rem;
  }
  app-setting-myprofile,app-setting-blockedaccounts{
    width: 100%;
  }
  .privacyAndSecurity .black-labels{
    padding-top:0.5rem;
  }
  .role-management-add { 
      display: block;
    }
  .role-managemenet-main .connection-actions-options:before {
      content:unset;
  }
  .role-managemenet-main .connection-actions-options:after {
      content:unset;
  }
  .role-managemenet-main .role-useradded,.helpsection-questions-row,.role-managemenet-main .role-managemenet-cards,.role-managemenet-main .bottom-actions,.settings-permissions,.agreement-info3 .connection-item-right,.agreement-amounts .VAT,.add-amount,.payment-type{
      display:flex;
  }
  .role-managemenet-main .connection-actions-options{
      right:0;
      top: 120%;
      width:15rem;
      padding:unset;
      border: unset;
      border-radius: 10px;
      box-shadow: 1px 1px 8px 0px rgb(0 0 0 / 13%);
      text-align: center;
  }
  .role-managemenet-main .connection-actions-options a:hover{
    background-color: #1c008a29;
    color: black !important;
  }
  .role-managemenet-main .role-management-container{
    margin: 1rem 1rem;
  }
  .role-managemenet-main .danger{
      margin-left:auto;
  }
  .role-managemenet-main .btn-style-5 span{
      align-self: center;
  }
  .role-managemenet-main .connection-info{
      margin-left:1rem;
  }
  .role-managemenet-main .connection-actions{
      margin-left: auto;
      display: flex;
  }
  .role-managemenet-main .collection-item2 .connection-actions{
    margin-left: 0.5rem;
}
  .role-managemenet-main .collection-item2{
    
      max-width: 49% !important;
     box-shadow: unset !important;
     border: 1px solid #DBDBDB;
     border-radius: 10px;
  }
  .role-managemenet-main .search-input {
      width: unset;
      max-width: 100%;
      margin-bottom: 1.5rem;
  }
  .role-managemenet-main .btn-style-1, .role-managemenet-main .btn-style-2{
      min-width:13rem !important;
  }
  .role-managemenet-main .connections-search{
      width:100%;margin-right:0;
  }
  .btn-share-profile span{
    margin-left:1.1rem !important;
  }
  .confirm-payment .connection-info{
    max-width: 55% !important;
  }
  .multiple-roles.modal-agr{
    margin-bottom: 0rem !important;
  }
  .multiple-roles div{
    display: flex;
    margin-top: 3rem;
  }
  .multiple-roles div button{
    min-width: 10.5rem;
    margin-right: 1rem;
    background-color: white;
    line-height: 3rem;
  }
  .btn-share-profile{
    margin-top:1rem;
  }
  .role-managemenet-main .search-input input[type="text"]{
      color: black;
      font-size: 16px;
  }
   a.dashboard-settings-list{
    font-size:0.875rem !important;
  }
  .dashboard-settings-left ul li a {
    font-size:1rem;
  }
  .dashboard-settings-left ul li  {
    margin-bottom: 1rem;
  }
  .headingstyle1{
    font-size:1.125rem;
    font-weight:550;
  }
  .dashboard-settings-left ul li.active a {
    color: #1C008A !important;
    text-decoration: none;
    font-weight: 500;
  }
  .settings-labelstyle1{
  color:#1C008A;
  font-size:16px;
  font-weight: 500;
  }
  .settings-info-container{
    padding: 1rem 1rem;
  }
  .settings-bottom-buttons{
    margin-top: 5%;
    display: flex;
  }
  .settings-bottom-buttons-modal{
    float: right;
  }
  .terms-conditions{
    border: 1px solid #EEEEEE;
    border-radius: 8px;
    padding: 1rem 1rem;
    width: 100%;
    height: 24rem;
    font-style: italic;
    font-weight: lighter;
  } 
  .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
  .switch {
    position: relative;
    width: 60px;
    height: 34px;
    margin-left: auto;
    margin-right: 1rem;
  }
  .settings-notification .switch{
    margin-right: 4.2rem;
  }
  .switch input{ 
    opacity: 0;
    width: 0;
    height: 0;
  }
  .inner-tutorials-carousel .owl-stage{
    left:-25px
  }
  .settings-permissions .sorting-dropdown{
    position: absolute;
    top: 10rem;
    right: 5rem;
    display: flex;
  }
  .dashboard-notification-settings-label{
    font-style: italic;
    font-size:0.9375rem;
  }
  .legal-settings .profile-middle-menu {
    min-width: 16rem;
    padding:2rem 1.25rem;
  }
  .logged-in-devices .active-device{
    color:#00008A;
  }
  .logged-in-devices .device-icons{
    font-size: 20px;
    margin: auto 2rem;
  }
  .logged-in-devices .danger{
    margin-left: auto;
  }
  .logged-in-devices .settings-container{
    padding-top:0.7rem;
  }
  .logged-in-devices .connection-item{
    display: flex;
    max-width: 49%!important;
    min-width: 23rem;
    border-radius: 10px;
    box-shadow: unset;
    border: 1px solid #DBDBDB;
  }
  .change-password .form-group .form-control{
    min-width:300px;
    }
    .change-password .btn-style-2{
        min-width:10rem;
    }
    .change-password .btn-style-1{
    min-width:13rem;
    }
    .edit-security-questions .btn-style-2{
      min-width:10rem;
  }
  .edit-security-questions .btn-style-1{
  min-width:13rem;
  }
  .edit-security-questions .settings-bottom-buttons-modal{
      float:right !important;
  }
  .edit-security-questions .password-modal-container{
      padding:1rem;
  }
  .change-mobile-number .settings-container{
    min-width:25rem;
  }
  .change-mobile-number .btn-style-2{
    min-width:10rem;
  }
  .change-mobile-number .btn-style-1{
  min-width:13rem;
  }
   .password-modal-container{
    padding:1rem;
  }
  .change-mobile-number.verification-code-input{
    margin-top:3rem;
    margin-bottom:3rem;
    width:60%;
  }
  .settings-info-container .no-documents-found{
    margin-top:5rem;
  }
  .account-verif-main {
    margin: 1rem 2rem;
  }
  .account-verif-main .center{
    font-size: 1.5rem;
    color:#cdcaca;
  }
  .securityandLogin .profile-middle-menu{
    min-width:18rem;
  }
  .account-verif-main img{
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .settings-profile-photo{
    width: auto;
    height: auto;
    overflow: hidden;
    border-radius: 1px;
    margin-right: 1rem;
    padding:auto;
}
.settings-container{
  padding: 1.7rem;
}
.headingstyle2{
  font-size:0.875rem
}
.settings-labelstyle2 a{
font-size:1rem;
font-weight: 550;
color:#00008A;
}
.btn-style-9:last-child{
  margin-bottom: unset !important;
}
.btn-style-9 span{
  opacity: 75%;
}
.user-dashboard .dashboard-grey{
  height: unset !important;
}
.user-dashboard .Payments-Actions{
  width: 85%;
  margin: auto;
  margin-top: 3rem;
}
.accounts-list{
  overflow-y: auto;
  max-height: 30rem;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(177, 175, 175); 
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: rgb(210, 205, 205); 
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #b5b5b5; 
}
.user-dashboard-middle .payments-listing::-webkit-scrollbar,.user-dashboard-middle .connections-list.InvoicesList::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.sidebar-documents .wizard-dropzone {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
}
.sidebar-documents .wizard-dropzone::-webkit-scrollbar{
   display: none; /* for Chrome, Safari, and Opera */
}
.multiple-docs {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-x: scroll; 
}
.multiple-docs::-webkit-scrollbar{
   display: none; /* for Chrome, Safari, and Opera */
}
.settings-labelstyle3,.settings-labelstyle3 a{
  color:#00008A;
  text-decoration: underline;
}
.settings-labelstyle3{
  position: absolute;
  right: 6rem;
  opacity: 75%;
}
.security-login-main .profile-verified-notice{
  background-color:#F3D2A6;
}
.role-management-container .bottom-actions{
    padding-left: unset;
    margin-bottom: 1rem;
  }
  .role-management-container .load-more{
    margin-bottom: 2rem;
    text-align: unset;
  }
  .ShowCheckbox{
    display: unset !important;
  }
  /* End Of Settings */
/* DASHBOARD */
  /* RIGHT SIDE BAR COMPONENT STYLE */
  .user-dashboard .dashboard-left{
    padding: 0rem 0.5rem;
    width: 21rem !important;
    min-width: unset !important;
  }
  .dashboard-grey{
    background-color: #F5F5F5 !important;
    border-top-right-radius: 100px;
    padding: 3rem;
    margin-top: -2rem;
    height: 100%;
    min-height: calc(100vh);
    position: sticky;
    top: 0;
  }
  .role-management{
    width: 100% !important;
    display: none;
  }
  @media screen and (min-width: 960px){

  .agr-summary{
      padding: 0.5rem 0rem;
    }
  body:not(.has-scroll) .add-account .bottom-actions {
      bottom: 0;
  }
}
.notifications-content .view-agr.connections-search {
  width: 90%;
  margin: auto;
  margin-bottom: 1rem;
}
  form .dashboard-grey .heading-4{
    z-index: 88;
    padding: 20px 0px;
    background-color: #F5F5F5;
  }
  .user-dashboard-middle .settings-labelstyle3{
    display: none;
  }
  .profile-pic:hover .profile-image-options{
 visibility: visible;
 opacity: 1;
  }
  .ListAgreements.view-customers .sorting-dropdown .Sort-agreements {
   right: 4% !important;
}
  .profile-image-options .warning{
    color:#E74E5A;
    border-top: 0.5px solid #cdcaca69;
    padding-top: 1rem;
  }
  .navbar-icons .Dropdown-header{
    background-color: unset !important;
    padding: 0.9rem 0rem !important;
  }
  .profile-image-options .Dropdown-inner{
    padding: unset !important;
  }
  .profile-image-options .navbar-labelstyle1{
    color: unset !important;
  }
  .donut.emptydonut{
    background: conic-gradient(rgb(174 166 171) 0deg, rgb(167 167 167) 360deg);
  }
  .adjust .profile-image-options{
    left: -12rem !important;
    font-size: 1rem;
  }
  .role-management-container .settings-labelstyle1 i:hover .profile-image-options{
    visibility: visible;
    opacity: 1;
  }
  .role-management-container .profile-image-options{
    left: unset !important;
    width: 25rem;
    font-family: 'Poppins', sans-serif;
    padding: 3rem 2rem;
    border-radius: 40px;
  }
  .role-management-container .profile-image-options span{
    color: #1C008A;
    font-weight: 500;
  }
  .profile-image-options{
   visibility: hidden;
   opacity: 0;
    cursor: pointer !important;
    left: 3rem;
    position: absolute;
    z-index: 5;
    padding: 1rem 2rem;
    box-shadow: 1px 3px 10px rgb(0 0 0 / 10%);
    border-radius: 15px;
    width: 15rem;
    background-color: #fff;
    color: black ;
    text-align: left;
    -webkit-transition-property: all;
    -webkit-transition-duration: 0.5s;
    -webkit-transition-timing-function: linear;
    -webkit-transition-delay: 0s;
    -ms-transition-property: all;
    -ms-transition-duration: 0.5s;
    -ms-transition-timing-function: linear;
    -ms-transition-delay: 0s;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: linear;
    transition-delay: 0s;
  }
  .user-dashboard .dashboard-middle {
    margin-left:1.5rem;
    display:unset;
    width: unset !important;
  }
  .user-dashboard .dashboard-right {
    width: 15rem;
    margin-left:1.5rem;
  }
  .user-dashboard .dashboard-container{
    margin-left: 0rem !important;
    margin-right: 0rem !important;
    width: 98% !important;
    margin-top: 3.5rem;
  }
  .user-dashboard .dashboard-left ul li{
    border-bottom: unset;
  }
  .user-dashboard .dashboard-right,.user-dashboard .dashboard-left,.user-dashboard .dashboard-middle{
    background:none;
    box-shadow:none; 
  }
  .percentage{
    margin-left: auto;
  }
  .dot,.dot1,.dot2{
    border-radius: 50%;
    width: 10px;
    height: 10px;
    display: inline-block;
  }
  .dot{
    background-color: #D70C8A;
  }
  .dot1{
    background-color: #FFA72E;
  }
  .dot2{
    background-color: #1C008A;
  }
  .dashboard-balance{
    align-items: center;
    background-color:white;
    padding:1.9rem 1.5rem;
    border-radius: 15px;
    min-width: 13.625rem;
    margin-bottom: 1rem;
    height: 96.5%;
    border:1px solid #DBDBDB;
  }
  .sharing-tool {
    margin: 0 0.5rem;
  }
  .dashboard-balance .labelstyle-2{
    width: unset !important;
  }
  .labelstyle-2{
    font-size: 1rem;
    color:#363636;
    font-weight: 550;
    opacity: 75%;
    width: 50%;
  }
  .balance-allocation .labelstyle-7{
    display: flex;
    margin-top: 0.3rem;
    line-height: 1;
    color: #000 !important;
  }
  .balance-allocation{
    margin-top: 2rem;
  }
  .balance-pie{
    margin: auto;
    width: 90%;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
  .user-dashboard .Payments-Actions .confirm-payment .back-button{
    display: none;
  }
    .user-dashboard .Payments-Actions .confirm-payment {
      width: unset !important;
      padding: unset !important;
      margin-top: 1rem !important;
    }
  .MyInvoices .btn-style-10 {
  bottom: 35% !important;
}
  .user-dashboard-middle .connection-info {
    max-width: unset !important;
}
  .user-dashboard-middle .col-md-9{
    padding-right: unset !important;
  }
  .balance-pie svg{
    width: 100%;
  }
  .payments-listing .connection-info{
    max-width: unset !important;
  }
  /* MIDDLE COMPONENT STYLE */
  .black-labels{
    font-weight:500;
  }
  .settings-notification .black-labels , .connection-info-container  .black-labels {
    font-weight:unset;
  }
  .mat-input-element{
    box-sizing: border-box !important;
  }
  /* .user-dashboard-middle .payments-listing .Fees:before {
    top: unset !important;
    bottom: -1rem;
    border-bottom:unset !important;
    border-top: 15px solid #363636;
} */
button.mat-icon-button.mat-button-base.mat-accent.ng-star-inserted{
  margin-top: 2rem !important;
  margin-top: 8rem!important;
}
 mat-carousel .mat-icon-button .mat-icon{
  opacity: 0;
  position: absolute;
}
button.mat-icon-button.mat-button-base.mat-accent.ng-star-inserted::after{
  content: "\003E";
  font-size: 1.6rem;
}
.total-matensa{
  margin-left: 3rem;
  margin-top: 0.9rem;
}
.Payments-Details .SeeAll-links{
  float: unset !important;
  cursor: pointer;
  margin-bottom: 1rem;
}
.Payments-Details .InvoiceDetails .connection-photo{
  width: 4.125rem;
  height: 4.125rem;
}

@media screen and (max-width: 1025px){
  .connections-mainInfo .multiple-addresses {
    width: 80%;
}
.import-file .btn-style-5 {
 display: none;
}
.ListAgreements.view-customers .sorting-dropdown .Sort-agreements {
  left: 4% !important;
}
.ListAgreements.view-customers .sorting-dropdown.agr-sorting .Sort-agreements {
  left: unset !important;
}
.ListAgreements.view-customers .sorting-filters {
  position: relative;
}
.Payment-sched .settings-labelstyle3 {
  right: 1rem !important;
}
  .Payments-Details.Agreement-details{
    padding: 2rem;
    padding-bottom: 5rem;
  }
  .transactions-details .detailsRightSide{
    text-align: left;
  }
  .Payments-Details .InvoiceDetails .connection-photo {
  float: unset !important;
  margin-top: 1rem;
}
  .new-summary{
  display: unset !important;
  }
  .view-agr-left {
   width: unset !important;
}
.view-agr-right .info-block .paymentChosen .paymentmethod-chosen {
  top: 1.8rem !important;
}
  .view-agr-right{
    border-left:unset !important;
     width: unset !important; 
     margin-top: 2rem;
     padding-left: unset !important;
  }
  .Fees{
    width: 17rem !important;
  }
  app-my-account  .dashboard-left ul li {
    min-width: 100% !important;
}
.transactions-details button{
  min-width: 13rem !important;
}
.transactions-details .btn-style-2{
  margin-bottom: 1rem;
}
.Payments-Details.transactions-details .Invoices-Container {
padding-bottom: 5rem;
}
.transactions-details {
  margin-top: unset !important;
}
.InvoiceDetails img {
  margin-top: unset !important;
}
.connections-profile-actions .row:nth-of-type(1){
  margin-right: 1rem;
}
.connections-profile-actions .row:nth-of-type(2){
  flex-wrap: unset !important;
}
.connections-profile-actions{
  display: flex;
}
}
 .user-dashboard button.mat-icon-button::after{
  border-radius: 50%;
  width: 33px;
  height: 33px;
  display: inline-block;
  text-align: center;
  font-size: 1.6rem;
  line-height: 1.2;
  color: #363636;
}
  .SeeAll-links{
    float: right;
    font-size: 0.75rem;
    color: #1C008A;
    font-weight: 550;
    margin-top: -1rem;
    margin-right: 0.5rem;
    opacity: 75%;
    text-decoration: underline;
    }
    .Payment-sched .Agreements-rightpart .btn-style-5{
    position: absolute;
    right: 5rem;
    border-radius: 25px;
    min-width: 7rem;
    }
    .invoices-body .btn-style-4{
      min-width: 100% !important;
    }
    .dashboard-middle-container .no-documents-found{
     font-size: 0.8rem;
     margin-top: 6rem;
    }
   .dashboard-middle-container {
    width: 100%;
    padding-bottom: 0.5rem !important;
    margin-bottom: 1rem;
    background-color: white;
    border-radius: 15px;
    min-height: 25rem;
    padding: 1.5rem;
    border: 1px solid #DBDBDB;
  }
  .user-dashboard-middle .connection-item{
    flex:45% !important;
    max-width: 49% !important;
  }
  .user-dashboard-middle .connection-item:last-child,.user-dashboard-middle .connection-item:nth-child(5) {
    margin-bottom: 0.5rem;
}
  /* LEFT SIDE BAR COMPONENT STYLE */
  .collectedcoins{
    margin-top:-0.5rem;
  }
  .dashboard-label1{
    font-size: 1.6rem;
    font-weight: 500;
    color: #1C008A;
  }
  .dashboard-label2{
    font-size: 0.75rem;
    color: #CDCACA;
    font-weight: 500;
    margin-right: 1.5rem;
  }
    .dashboard-left-container {
      align-items: center;
      display:wrap;
    }
    .collectedcoins-block{
      background-color: white;
      padding: 1rem 2rem;
      padding-bottom: 0.5rem;
      border-radius: 10px;
      box-shadow: 0 3px 10px rgb(0 0 0 / 10%);
    }
    .tutorials{
      width:100%;
      /* max-height:150px; */
      overflow-x: auto;
      border-radius:8px;
      overflow-y: hidden;
      display:flex;
      padding: 1rem 0rem;
    }
     .tutorials svg{
    display:block;
    margin:auto;
    margin-top: 0.8rem;
    }
    .blockSuggestion .fas{
      font-size:16.45px;
      color:#cdcaca;
    }
    .blockSuggestion h4{
      font-size: 9px;
      position: absolute;
      text-align: center;
      bottom: 0.2rem;
      width: 90%;
    }
    .warningtext{
      border: 1px solid red !important ;
    }
    .connection-codereference input{
    border-radius: 8px;
    border: 1px solid #0000004d;
    padding: 0rem 0.5rem;
    margin-bottom: 0.5rem;
    }
    .exportpay span{
      display: unset !important;
    }
     .blockSuggestion{
      cursor: pointer;
      /* background-color: #EFEFEF; */
      /* margin-right: 0.8rem; */
      font-size: 0.6rem;
      min-width: 100px;
      /* color: #363636;
      border-radius:8px;
      border: 1px solid #D6D6D6; */
      /* -webkit-box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.13); 
      box-shadow: 3px 3px 8px 0px rgba(167, 132, 132, 0.13); */
      position: relative;
      padding:5px;
      color: white;
      align-content: center;
    }
    .sender-logo img{
      margin-right: unset !important;
      border-radius: 50%;
      max-width: 15rem;
    }
    .InvoiceDetails table .connection-item{
      max-width: unset !important;
    }
    .relatedpayments{
      cursor: pointer;
      color: #3315a8;
    }
    .e-signature{
      padding: 0.6rem;
      padding-bottom: 2rem !important;
      font-style: italic;
      font-size: 1.2rem;
    }
    .tutotials-scroll{
      float: right;
      margin-top: 1.5rem;
    }
    .tutotials-scroll div{
      border-radius: 50%;
      border: 2px solid #1c0d8a5c;
      width: 33px;
      height: 33px;
      display: inline-block;
      text-align: center;
      font-size: 1.6rem;
      line-height: 1.3;
      margin-left: 3rem;
      color: #1c0d8ab8;
    }
    .dashboard-actions {
      display: flex;
      cursor: pointer;
      justify-content: space-between;
      margin-top:1.43rem;
    }
    .dashboard-left-carousel{
    margin-top: 2rem;
    position: relative;
    }
    .dashboard-left-carousel .fas{
      position: absolute;
      top: 2.8rem;
      font-size: 1.1rem;
      right: -1.2rem;
      color: #1C008A;
    }
    .dashboard-SvgLabels {
      color: #363636;
      font-size: 0.85rem;
      text-align: center;
      margin-top: 1rem;
      opacity: 75%;
      font-weight: 500;
    }
     .blockSuggestion div{
      margin-top: 0.2rem;
    }
    .suggestion-title{
      width: 70%;
      position: absolute;
      bottom: 1rem;
      left: 1rem;
      text-align: center;
    }
    .dashboard-label3{
      font-size: 2.3rem;
    font-weight: 500;
    }
    .labelstyle-7{
      color: #363636;
      font-size: 0.9rem;
      FONT-WEIGHT: 400;
      opacity: 75%;
    }
    .dashboard-collectedpoints{
      display:flex;
      margin-left:0.5rem;
      }
      .dashboard-collectedpoints .fas{
        font-size: 1.2rem;
        color: #1C008A;
        opacity: 0.5;
        line-height: 3;
        margin-left: auto;
      }
/* DASHBOARD ENDS HERE */
.ListAgreements .warning span{
    color:#E74E5A;
  }
  .warning{
    color:#E74E5A;
  }
  .ListAgreements .sorting-dropdown .Sort-agreements{
    right: -3rem;
    top: 4.5em;
    border-radius: 40px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    border:unset;
    visibility: hidden;
    opacity: 0;
  }
  .text-center.heading-3{
    margin-bottom: 2rem !important;
  }
  .Sort-agreements a:first-child{
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
  }
  .Sort-agreements a:last-child{
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
  }
  .Agreements-rightpart  .connection-item .sorting-dropdown a {
    width:100%;
    height: unset;
    line-height: unset;
    color: black !important;
    text-align: unset;
  }
  .connections-filters li{
    margin-bottom: 1rem;
  }
  .Accept-RejectAgreement{
    width: 30%;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    background-color: #cdcaca8f;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1.5px solid #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  .connection-item {
    cursor: pointer;
    padding: 1.25rem;
    background-color: #fff;
    box-shadow: 1px 1px 8px 0px rgb(0 0 0 / 13%);
    margin-top: 1.2rem;
    border-radius: 20px;
    flex: 40%;
    max-width: 32%;
  }
  ul.list-1 input[type="checkbox"]:checked{
  background-color: unset;
  }
  .agr-invoices .Agreements-rightpart .col-3{
    min-width: 5rem !important;
  }
  ul.list-1 li input[type="checkbox"]{
  width: 1.3rem;
  }
  ul.list-1 input[type="checkbox"]:checked:before {
    position: unset;
    border-radius: 4px;
    border: 1px solid black;
  }
  .dashboard-left.with-shadow ul.list-1{
    border-bottom: 0.5px solid #cdcaca85;
    padding-bottom: 0.5rem;
  }
  .dashboard-left.with-shadow ul.list-1 li label{
    line-height:2.5rem;
    padding:unset;
    display: unset;
  }
  .dashboard-left.with-shadow ul.list-1 li input[type="checkbox"]:checked{
    line-height: 1.6rem;
  }
  .dashboard-left.with-shadow ul.list-1 input[type="checkbox"]:checked:before{
    padding: 0.1rem;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 31px;
    width: 31px;
    left: 0;
    bottom: 1px;
    background-color: white;
    /* border: 1.5px solid #ccc;
    -webkit-box-shadow: 0px 3px 1px 0px rgba(0,0,0,0.13); 
    box-shadow: 0px 3px 1px 0px rgba(0,0,0,0.13); */
    -webkit-transition: .4s;
    transition: .4s;
  }
  input:checked + .slider {
    background-color: #1C008A;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #1C008A;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  .app-modal-editpic .settings-bottom-buttons{
    float:right;
    margin-bottom:1rem;
  }
  .app-modal-editpic .btn-style-2, 
    .app-modal-editpic .btn-style-1{
    min-width:10rem;
  }
  .move{
    border-radius: 50%;
  }
  .resize{
    visibility: hidden;
  }
  .settings-bottom-buttons-modal .btn-style-2 , .settings-bottom-buttons-modal .btn-style-1{
    min-width:10rem;
  }
  .addresses-list-2 .addresses-list{
    flex-direction: column;
  }
  .edited-image{
    display:block;
    margin:auto;
    margin-bottom: 1rem;
  }
  .send-note{
    margin-top: -1.1rem;
    font-size: 0.7rem;
  }
   .agreementRelated  label div{
    margin-left: 0.5rem;
    position: relative;
  }
  .agreement-info .connection-item{
    box-shadow: unset !important;
  }
  .agreement-payments-actions .Add-doc-block{
    width: 320px;
    height: 210px;
    color: #1C008A;
    padding-top: 1.5rem;
    margin-top: unset;
  }
  .dashboard-left.with-shadow .list-1.agreementRelated label div{
    display: inline-block;
  }
  .Invoices-Container .InvoiceDetails .settings-bottom-buttons .btn-style-5{
    margin-right: 1rem;
    display: unset !important;
    height: unset !important;
    line-height: 2rem;
    min-width: 18rem !important;
  }
  .connections-list .address-item{
    flex: 48% !important;
  }
  .searchforInvoice{
    padding: 2rem;
  }
  .profile-middle-menu {
  min-width:18rem;
  }
  .profile-middle-content .wizard-stepper-block.full {
    width:90%;
  }
  app-agreement-create-invoice .invoices-body{
    padding:unset !important;
  }
  .settings-container .verification-code-input{
    width: 40% !important;
  }
  .settings-container .profile-verified-notice{
    font-size: 0.875rem;
    padding: 1.375rem 1.25rem;
    background-color: #F5F5F5;
    border-radius: 15px;
    margin-left: auto;
    width: 40%;
  }
ul.agreement-menu{
  width: 40% !important;
  display: flex;
}
.agreement-info2-list ul.menu-style-2 li {
  width: 33% !important;
}
  app-agreement-documents.ng-star-inserted{
    width: 100% !important;
  }
  app-agreement-payments.ng-star-inserted,app-edit-security-ques,app-change-mobile{
    width: 100% !important;
  }
  app-transaction-details,app-agreement-upload-amounts.ng-star-inserted,app-transactions-history,
  app-agreement-edit-template.ng-star-inserted,app-agreement-customers.ng-star-inserted,
  app-agreement-summary.ng-star-inserted, app-payments-results.ng-star-inserted{
    width: 100%;
  }
  .payment-type app-import-clients-popup{
    margin-left: auto;
  }
  .template-import{
    margin-left: auto;
    width: 5%;
  }
  .menu-bar{
  width: 2rem;
  float: right;
  position: relative;
}
.menu-bar span{
  transition-delay: 0s;
  transition-duration: .4s;
  transition-property: all;
  transition-timing-function: ease;
  -webkit-transition-delay: 0s;
  -webkit-transition-duration: .4s;
  -webkit-transition-property: all;
  -webkit-transition-timing-function: ease;
  -moz-transition-delay: 0s;
  -moz-transition-duration: .4s;
  -moz-transition-property: all;
  -moz-transition-timing-function: ease;
  -ms-transition-delay: 0s;
  -ms-transition-duration: .4s;
  -ms-transition-property: all;
  -ms-transition-timing-function: ease;
  -o-transition-delay: 0s;
  -o-transition-duration: .4s;
  -o-transition-property: all;
  -o-transition-timing-function: ease;
}
  .menu-bar span {
    width: 100%;
    height: 2.5px;
    vertical-align: top;
    background: #1C008A;
    /* display: inline-block; */
    float: right;
    margin-bottom: 6px;
    overflow: hidden;
}
.change-background .menu-bar span:nth-child(1) {
  transform: rotateZ(-45deg);
}
.navbar-toggler.mod{
  filter: grayscale(0);
}
.change-background .menu-bar span:nth-child(3) {
  transform: rotateZ(45deg);
  top: -16px;
  position: relative;
}
.change-background  .menu-bar span:nth-child(2){
  opacity:0;
  width:1px;
}

.navbar-toggler.mod .navbar-toggler-icon{
  background:none;
}

.menu-bar span:nth-child(2) {
  width: 60%;
  background:#FFA72E;
}
  /* RESPONSIVE */
  @media only screen and (max-width: 1025px) and (min-width: 990px){
    .navbar-iconslist{
      display:none;
    }}
  @media screen and (min-width: 960px){
    body:not(.has-scroll) .role-management-container .bottom-actions {
    position: unset;
    }}
  @media only screen and (max-width: 1025px){
    .MyInvoices .connections-list.SelectTopay .connection-item,.profile-verified-notice .connections-list.SelectTopay .connection-item{
      width: 100% !important;
      margin-left: 1rem !important;
    }
    .create-buttons{
      padding: unset !important;
    }
    .create-buttons a{
      min-width: 15rem !important;
    }
    .NoAmounts {
      width: unset !important;
    }
    .user-dashboard .dashboard-left{
      width: unset !important;
    }
    .Payments-method{
      min-width: unset !important;
    }
    .payment-title{
      left: 0rem !important;
      width: 100%;
    }}
    .languageopen{
      margin-top: 1rem;
      margin-left: 0.5rem;
    }
  @media only screen and (max-width: 1025px) {
    .roll-back{
      padding-bottom: 5rem;
    }
   .inmain{
      display: none;
    }
    .blockSuggestion{
      font-size: 10px;
      min-width: 145px !important;
    }
    .dashboard-label3 {
      font-size: 2rem !important;
  }}
  @media screen and (max-width :1100px){
    fieldset div.agr-summary{
      width: unset !important;
    }}
  @media screen and (max-width :1260px) and (min-width: 1025px){
    .terms-types {
     display: unset !important;
    }
    .terms-types .types-title{
      text-align: left !important;
      margin-bottom: 2rem;
    }}
  @media screen and (max-width :800px){
    html, body {
      font-size: 4vw;
  }
  .terms-conditions {
    height: 16rem;}
  .user-dashboard button.mat-icon-button::after{
    line-height: 33px !important;
  }
    .connections-list.payments-listing .connection-item-right{
      margin-left: 2rem !important;
    }
    .steps-container{
      overflow: hidden;
      width:100%;
    }
    .steps-agreement .step-circle{
      min-width: auto !important;
      width:100%;
    }
    .step-name{
      font-size:2.6vw;
    }
    ul.menu-style-2 li a {
      font-size: 3vw;
    }
    .steps-container {
      grid-gap: 2vw;}
    .step-circle .step-name {
      position: relative;
      left: 0;
      font-size: 2.5vw;
  }
    .steps-container{
      max-width: 100% !important;
    }
    .MyInvoices .connections-list.InvoicesList.ng-star-inserted .connection-item-right {
      margin-left: 3rem !important;
  }
  div .View-agr-options {
    right: 15px !important;
    left: auto !important;
}
  .confirm-payment .headingstyle1{
    display: block !important;
  }
  .Payfrom-account {
    width: 100% !important;
  }
  .View-agreement-container .confirm-payment  .settings-bottom-buttons .btn-style-5{
    min-width: 10rem !important;
    height: 3.4rem !important;
    margin-bottom: unset !important;
  }
  .Payfrom-account span {
    font-size: 0.9rem !important;
}
  .Payfrom-account {
   margin-top: 1rem;
  }
    .agreement-amounts .action-link {
     margin-top: 1rem;
  }
    .agreement-amounts .VAT .row {
      margin-top: 1rem;
  }
    .agreement-amounts .mat-form-field-infix {
      width: 150px !important;
    }
    .terms-types{
      overflow-x: auto;
      display: flex !important;
    }
    .documents-row1 {
      display: unset !important;
    }
    .agreements-docs-button{
      margin-bottom: 1rem;
    }
    .agreement-payment-container .action-link.danger{
      float: right;
      margin-top: 1rem !important;
    }}
  @media screen and (max-width :1100px){
    fieldset div.agr-summary{
      width: unset !important;
    }}
  @media screen and (max-width :1260px) and (min-width: 1025px){
    .terms-types {
     display: unset !important;
    }
    .terms-types .types-title{
      text-align: left !important;
      margin-bottom: 2rem;
    }}
  @media screen and (max-width :800px){
  .confirm-payment .headingstyle1{
    display: block !important;
  }
  .Payfrom-account {
    width: 100% !important;
  }
  .Details-container .InvoiceDetails .settings-bottom-buttons.btn-mob-full-width{
    display: block !important;
    width: 90%;
    margin: 0 5% !important;
  }

  .btn-mob-full-width.settings-bottom-buttons button{
    width:100%;
  }
  .agr-summary{
    padding-left:0.5rem;
    padding-right: 0.5rem;
  }

  /* .View-agreement-container .confirm-payment  .settings-bottom-buttons .btn-style-5,.confirm-payment  .settings-bottom-buttons .btn-style-1{
    min-width: 10rem !important;
    height: 3.4rem !important;
    margin-bottom: unset !important;
  } */
  .Payfrom-account {
   margin-top: 1rem;
  }
  .subscrptionType{
    margin-top: 1rem;
  }
  .View-agreement-container .connections-list.InvoicesList .connection-item{
    margin-top: 2.5rem;
  }
  .View-agreement-container .Agreement-details .Payment-sched{
    margin-top: 1rem;
  }
  .documents-row1 ul.menu-style-2 li{
    width: 100% !important;
  }
    .agreement-amounts .VAT .row {
      margin-top: 1rem;
  }
    .agreement-amounts .mat-form-field-infix {
      width: 150px !important;
    }
    .terms-types{
      overflow-x: auto;
      display: flex !important;
    }
    .documents-row1 {
      display: unset !important;
    }
    .agreements-docs-button{
      margin-bottom: 1rem;
    }
    .agreement-payment-container .action-link.danger{
      float: right;
      margin-top: 1rem !important;
    }}
    @media screen and (max-width :1280px) and (min-width:1025px){
      .donut {
        width: 100px !important;
        height: 100px !important;
         }
        }
  @media screen and (max-width :666px){
    .connections-container .total-matensa {
      display: none;
       }
    .Payment-sched .btn-style-10, .Payment-sched .SelectTopay .connection-item .btn-style-10{
      position: absolute !important;
      bottom: 3% !important;
    }
    .settings-bottom-buttons {
      padding-bottom: 7rem;
      display: unset !important;
      margin-left: 20%;
    float: left;
    }
    .agr-invoices .settings-bottom-buttons {
     margin-left: unset !important;
  }
  .agr-invoices .settings-bottom-buttons .btn-style-2{
    min-width: 20rem;
    margin-bottom: 1rem;
  }
    .agreement-info2-list:first-child{
      display: unset !important;
    }
    .agreement-info2 .search-input{
      margin-bottom: 1rem;
    }
    .ViewAgr-Actions {
      margin-left: unset !important;
    }
    .agreement-info2-list .menu-style-2{
      margin-top: 1rem;
      margin-left: -1rem;
    }
    .Inner-Payment-item{
      margin-right: 0.2rem;
    }
    .Agreements-rightpart .image .NoPaymentMethods {
      bottom: -6.3em !important;
  }
    .Agreements-rightpart .connection-info .invited{
  display: none;
    }
    .payment-up {
      margin-top: 0.5rem;
  }
    .modal-agr .agreement-actions{
      width: unset !important;
    }
    .modal-agr {
      text-align: center !important;
    }
    .Invoices-Container .InvoiceDetails .settings-bottom-buttons .btn-style-5{
      min-width: 20rem !important;
      margin-bottom: 1rem !important;
    }
     .Agreements-rightpart .settings-permissions{
      display: unset!important;
    }
    .agr-invoices .invoices-body{
      padding-left: unset !important;
    }
    .MyInvoices .settings-bottom-buttons .btn-style-5{
      margin-bottom: 1rem ;
      min-width: 17rem !important;
      margin-left: -2rem !important;
    }}
  @media (max-width: 1375px) and (min-width: 750px) {
    .payments-list  .SelectTopay .connection-item {
      width: 90% !important;
    }
    .Agreements-rightpart .connection-item {
    max-width: unset;
    flex:49%;
    }
    .Agreements-rightpart .invited {
     left:300%;
    }}
  @media (min-width: 1715px){
  .sorting-dropdown .Sort-agreements {
    right:unset;
  }}
  @media (min-width: 1400px){
    .Agreements-rightpart .list-types {
      margin-right:21rem;
    }}
  @media screen and (max-width :1025px) {
    .row.user-dashboard-middle .col-md-9 {
      padding-right: 15px !important;
  }
    .agreement-amounts .tabs-style-2 li a{
      width: unset !important;
      height: unset !important;
    }
    .steps-agreement {
      display: flex;
      width: 100%;
      overflow-x: auto;
  }
  .added-input .action-link {
    line-height: 1.7rem !important;
  }
  .steps-agreement .step-circle {
    min-width: 8rem;
  }
    .my-account-hidden {
      display: block !important;
      font-size: 2rem;
      margin-top: -0.5rem;
      margin: auto !important;
      color: #00008A;
    }
    .InvoiceDetails{
      padding: unset !important;
    }
    .connections-top .btn-style-5 {
      margin-top: -3.5rem;
      float: left;
      margin-left: unset;
    }
    .connections-middle .heading-3{
      margin-bottom: unset !important;
    }
    .connections-add .heading-3{
      margin-bottom: 4.5rem !important;
    }
    .connections-add .connections-top .btn-style-5 {
      margin-top: unset !important;
      top: -4.5rem !important ;
  }
  .helpsection-leftmenu ul li {
    padding: 1rem !important;
    min-height: unset !important;
}
    .connections-middle{
      margin-top: -3rem;
    padding: 2rem 0rem !important;
    }
    .connections-add .connections-top{
      margin-top: 1rem;
    }
    ul.menu-style-2 {
      width: 100% !important;
      height: unset;
    }
    .with-shadow {
      margin-bottom: 1rem;
    }
    .profile-left-menu ul li,
    .profile-middle-menu ul li {
      display: table-cell;
    }
    .View-agreement-container{
      margin-top: -2rem;
    }
    .import-file .btn-style-5 {
      right: 1rem !important;
      top: 3.9rem !important;
  }
  .create-template {
    padding-top: 2rem !important;
}
    .import-file {
      margin-bottom: 4rem !important;
  }
  .Pay-Now .pay-now-back {
    left: -1rem !important;
}
.pay-now-filters div{
  margin-bottom: 1rem;
  margin-left: 1rem;
}
  .MyPayments.Pay-Now .headlines {
    top: 13rem !important;
    right: unset !important;
    width: unset !important;
}
.Pay-Now .not-found-page div{
margin-top: unset !important;
}
  .Pay-Now .heading-3{
    margin-top: -1rem !important;
    text-align: center;
  }
  .input-control{
    width: 100%;
  }
 .Pay-Now .pay-now-filters {
    display: revert !important;
    margin-top: 9rem !important;
    text-align: center;
    width: 50%;
    margin: auto;
}
  .View-agreement-container .dashboard-middle .btn-style-1 {
    min-width: 15rem !important;
}
    .template-control {
      width: unset !important;
  }
    .agreement-info2-list .heading-3{
      margin-bottom: unset !important;
    }
    .agreement-info2 .agreement-info2-list .menu-style-2{
      width: unset !important;
    }
    .view-customers .heading-3{
      margin-bottom: unset !important;
      margin-left: 1rem !important;
    }
    ul.menu-style-1 {
      overflow-x: auto;
      width: unset !important;
      overflow-y: hidden;
    }
    .profile-middle-menu ul.menu-style-1 li a {
      border-bottom: unset;
      text-align: center;
    }
    .profile-left-menu ul li a i,
    .profile-middle-menu ul li a i ,.headingstyle1 {
      display: none;
    }
    .dashboard-container {
      display: block;
    }
    .dashboard-middle {
      width: unset;
      margin-left: unset;
      display: unset;
    }
    ul.menu-style-1,
    ul.menu-style-1 li {
      min-width: 16rem;
      border-radius: 8px;
    }
        .connections-middle .my-account-menu {
          overflow-x: unset !important;
          overflow-y: unset !important;
        }
    .dashboard-left {
      min-height: unset;
      position: relative !important;
      top: unset !important;
    }
    .no-documents-found.center {
      position: unset;
      top: unset;
      transform: unset;
    }
    .wizard-stepper-block .btn-style-4 {
      min-width: 15rem
    }
    .profile-middle-menu {
      border-right: unset;
      width: unset;
      overflow: auto;
      padding: 1.37rem 0rem;
      margin-bottom: 1rem;
      margin-top: 1.5rem;
    }
    .main-profile-settings .dashboard-left,.wizard-stepper-block,
    .dashboard-left {
      width: unset !important;
    }
    .privacy-security .profile-middle-menu {
      max-width: unset !important;
      min-width: unset;
      padding: 2rem 0rem;
    }
    .profile-middle-content .bottom-actions {
      float: unset;
      padding-left: unset;
      width: unset;
    }
    .settings-container {
      padding: 3rem 0rem;
    }
    .change-mobile-number .settings-container ,.blockedaccounts-settings .btn-style-6 , .change-password .form-group .form-control,
    .securityandLogin .profile-middle-menu {
      min-width: unset;
    }
    .role-management-add .bottom-actions {
      float: left;
    }
    .role-managemenet-main .role-useradded ,.profiile-flex-container{
      display: unset;
    }
    .Notifications-container .profiile-flex-container {
      width: unset !important;
  }
  .Notifications-container .heading-3{
    margin-bottom: 2rem!important;
  }
  .notification-block .connection-actions-options {
    right: 0 !important;
}
  .Notifications-container .profiile-flex-container .adjust {
   top:0 !important
}
  .notification-block .ViewAgr-Actions .action-link{
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .Notifications-container .Dropdown-content {
  border: unset !important;
}
    .role-management-add .bottom-actions .btn-style-2 {
      margin-right: -1rem;
      margin-bottom: 1rem;
    }
    .addresses-list {
      flex-wrap: wrap;
    }
    .wizard-stepper-block {
      padding: 1rem 0rem;
    }
    .dashboard-settings-left {
      min-height: unset;
      padding: 2rem 1.8rem;
    }
    .main-profile-settings .dashboard-settings-left ul li.active {
      border-left: unset;
    }
    .settings-info-container .no-documents-found  {
      margin-left: unset;
    }
    .dashboard-settings-left ,.with-shadow ul{
      overflow-x: auto;
    }
    .dashboard-left .list-1.list-types {
     overflow: unset;
     padding-bottom: 5rem !important;
  }
  .Transactions .heading-3{
    margin-bottom: unset !important;
  }
    .dashboard-left ul li {
      text-align: center;
      min-width: 17rem;
    }
    .Transactions .detailsRightSide {
      text-align: unset !important;
  }
    .invoices-body .btn-style-4 {
      height: unset !important;
  }
  .Payments-Details table {
    width: 100% !important;
}
    .dashboard-left ul.list-types li {
      flex: 0 0 15% !important;
      min-width: unset !important;
    }
    .change-mobile-number .btn-style-1,
    .edit-security-questions .btn-style-1,
    .edit-security-questions .btn-style-2,
    .change-password .btn-style-1,
    .change-password .btn-style-2,
    .change-mobile-number .btn-style-2 {
      min-width: 13rem;
      margin-top: 1rem;
    }
    .role-managemenet-main .role-management-container {
      margin: 2rem 0rem;
    }
    .Email-notifications .settings-info-container {
      padding: 40px 0px;
      padding-right: unset;
    }
    .sharing-tools-slider {
      overflow-x: auto;
      padding:0 3rem;
    }
    .connection-item {
      display: unset;
      max-width: 100%;
      flex: 49% !important;
    }
    body.show-import-sidebar .right-sidebar{
      width: 100%;
      left: 0;
    }
     .Agreement-details .headingstyle1 .payment-method{
      display: none;
    }
    .right-sidebar .btn-style-4 {
     min-width: unset;
     height: unset;
  }
  .Connection-profileInfo ul {
    min-height: unset !important;
}
  .connections-profile-actions .col-md-6{
   margin-top: unset !important;
   margin-bottom: 1rem;
     }
    .left-actions {
      position: unset;
      margin-top: 2rem;
    }
    .connections-middle .heading-5 {
      color: white;
    }
    .label-style-1 {
      color: unset;
      text-align: center;
    }
    .dashboard-profile-left{
      margin-top: -2rem;
    }
    .myCustomModalClass .wizard-dropzone .form-group .dropzone .dropzone-text {
      width: unset;
      text-align: unset;
    }
    .myCustomModalClass .wizard-dropzone .form-group .dropzone {
      flex-direction: column;
      height: unset;
    }
    .connection-actions-options {
      width: 15rem;
    }
    .dashboard-left-carousel,.dashboard-actions {
      height: 6rem;
    }
    .user-dashboard .dashboard-right {
      margin-left: unset;
      width: unset;
    }
    .dashboard-right .btn-style-9{
      margin-top:1.5rem !important;
      margin:auto;
      display:block;
      font-size: 1.1rem;
      padding: 1rem;
    }
   }
   .Finances .create-menu{
    top: -9rem !important;
   }
     .create-menu {
       text-align: center;
       position: absolute;
       z-index: 7766;
       top: -5rem;
       border-radius: 15px;
       box-shadow: 1px 1px 10px 5px rgb(0 0 0 / 13%);
       font-size: 0.8rem;
       padding: 0.5rem;
       background-color: white;
       width: 10rem;
       color: black;
     }
   .create-menu a{
    padding-bottom: 0.9rem;
   }
   .user-dashboard .labelstyle-2{
    font-weight: 500 !important;
   }
        @media screen and (max-width: 1025px) {
          /* .user-dashboard .Agreements-rightpart .connection-item .fas {
            display: none;
        } */
          .dashboard-left-carousel.labelstyle-2 {
            height: unset !important;
          }
          .balance-allocation .labelstyle-7 {
            line-height: 1;
        }
        .dashboard-balance{
          min-height: 24rem;
        }
          .carousel-slide-overlay{
            background-color: unset !important;
          }
          .Notifications-container .notification-block .View-agr-options {
            left: 93% !important;
        }
           .dashboard-container,.user-dashboard .dashboard-container, .dashboard-content .View-agreement-container .dashboard-container {
            width: 90% !important;
            margin: auto;
            margin-left: auto !important;
            margin-right: auto !important;
        }
        .View-agreement-container .dashboard-left ul {
          margin-left: unset !important;
          display: flex;
      }
      .invoices-body .heading-3{
        margin-bottom: unset !important;
      }
      .Create-invoice-main .agr-invoices{
        margin-top: -4rem;
        width: 100% !important;
      }
        .dashboard-grey{
          border-radius: 15px;
          min-height: unset !important;
          margin-top: unset ;
          padding: unset !important;
          background-color: unset !important;
          position: unset !important;
          top: unset !important;
        }  
        .user-dashboard .Payments-Actions,.user-dashboard .Payments-Actions .Payments-Details{
          padding: 2rem;
        } 
        .privacyAndSecurity{
          margin-top: 1rem;
        }
        .blockedaccounts-settings.legal-account-settings .heading-3{
           margin-bottom: 3.5rem !important;
        }
        .blockedaccounts-settings,.change-password,.change-mobile-number,.edit-security-questions,.logged-in-devices{
          margin-top: 3rem;
        }
        .logged-in-devices .connection-item {
          min-width: 100% !important;
        }
        .logged-in-devices .device-icons {
          margin: auto 1rem;
      }
        .share-connection-profile {
          right: 1rem;
      }
        .settings-list .btn-style-6 {
          width: 97% !important;
      }
        .dashboard-left{
          min-width: unset !important;
        }
        .blockedaccounts-settings .connection-item {
          max-width: unset !important;
      }
        .connection-labelstyle1 i:hover .profile-image-options{
          left: -9rem !important;
          width: unset !important;
        }
        .privacyAndSecurity .connections-info-left{
          width: unset;
          border-right: unset;
          padding-right: unset !important;
        }
        .privacyAndSecurity .connections-info-right{
          margin-left: unset;
          width: unset !important;
        }
        .connection-labelstyle1,.labelstyle-2{
          margin-left: unset !important;
          padding-left: unset;
        }
        .notification-block:last-child {
          border-radius: unset !important;
      }
        .Email-notifications .black-labels {
         padding-left: unset !important;
      }
        .settings-list{
          width: unset !important;
          margin-left: -1.5rem !important;
        }
        .terms-types label {
        margin-right: 0rem !important;
      }
      .types-title {
       margin-right: unset !important;
    }
        .agreement-info2 .sorting-dropdown ul.tabs-style-2 a{
          margin-bottom: 1rem;
          font-size: 0.7rem;
          font-weight: 500;
        }
        .agreement-amounts .VAT {
         margin-top: 1rem;
         margin-left: 0rem !important;
      }
                .agreement-amounts .btn-style-5 {
                  line-height: 2rem !important;
                  height: 36px !important;
                }
        .agreement-actions .btn-style-2{
          margin-right: 0.5rem;
        }
        .change-mobile-number .heading-3,.change-password .heading-3,.edit-security-questions .heading-3{
          margin-bottom: unset !important;
        }
        .add-account{
          padding: 2rem !important;
        }
        .blockedaccounts-settings .settings-info-container {
          padding: 0rem !important;
      }
        .helpsection-leftmenu ul{
          display: flex;
        }
        .helpsection-main-page .heading-3{
          margin-top: unset !important;
          margin-bottom: 2rem !important;
        }
        .share-connection-profile .btn-style-5 {
          margin-top: 2rem;
      }
        .helpsection-leftmenu{
          min-height: unset;
          overflow-x: auto;
          margin-left: unset !important;
          width: unset !important;
        }
        .tutotials-scroll {
          margin-top: unset !important;
          margin-bottom: 2rem;
          width: 60%;
          margin: auto;
          float: unset !important;
      }
      .user-dashboard-middle .payments-listing .connection-item .Fees {
        width: 17rem !important;
    }
    .user-dashboard-middle .connections-list {
      padding-right: 0.3rem;
      grid-gap: 15px !important;
  }
    .Receipt-Icon {
      left: 94% !important;
  }
  .download-pdf {
    top: 0rem;
    right: 0 !important;
}
      div.wizard-dropzone .bottom-actions{
        display: revert!important;
        padding-bottom: 10rem;
      }
      .iti__flag-box, .iti__country-name, .iti__dial-code {
       font-size: 0.8rem;
    }
      div.wizard-dropzone .bottom-actions .btn-style-1{
        margin-top: 1rem;
      }
      .form-group .mat-form-field .mat-select .mat-select-arrow{
        top: unset !important;
      }
            .profile-left-content .btn-style-5:hover .profile-image-options {
              left: 0rem !important;
              width: 20rem !important;
            }
            .sidebar-documents {
              width: 100% !important;
              border-top-left-radius: unset !important;
              border-bottom-left-radius: unset !important;
            }
            div.wizard-dropzone .bottom-actions .btn-style-1 {
             margin-right: unset !important;
          }
      .navbar-brand {
        margin-left: -2rem !important;
        margin-bottom: 2rem;
    }
    .navbar-toggle-now{
      margin-top: -1rem;
      margin-right: -1.5rem;
    }
      .balance-pie {
        width: 50%;
        margin: auto;
      }
      .balance-pie svg{
        width: 100%;
      }
      ul.agreement-menu {
       margin-left: unset !important;
    }
    .Fees{
      left: unset !important;
    }
    .templates-drafts .heading-3{
      margin-left: 1rem !important;
      margin-bottom: unset !important;
    }
    .invoice-details-info .heading-3 {
      margin-top: unset !important;
      margin-bottom: unset !important;
  }
  .InvoiceFooter {
    display: none;
}
    .heading-3 {
      margin-top: 2rem;
      margin-bottom: 5rem !important;
      margin-left: unset !important;
  }
       .profile-pic:hover .profile-image-options {
        left:-3rem;
    }
      .my-account-buttons{
        text-align: center;
      }
      .my-account-buttons .btn-style-1{
        margin-top: 1rem;
      }
      app-my-account .dashboard-left {
        min-width: unset !important;
        margin-bottom: 3rem;
    }
    .documents-list .document-title {
      width: 5.5rem !important;
  }
  .privacyAndSecurity .heading-3,.blockedaccounts-settings .heading-3,.role-management-container .heading-3,.settings-info-container .heading-3{
    margin-bottom: unset !important;
  }
  app-agreements-list .dashboard-container,app-invoices .dashboard-container,app-payments .dashboard-container,app-transactions .dashboard-container{
    margin-top: -2rem !important;
  }
  app-agreements-history .heading-3{
    text-align: center;
  }
  app-agreements-main-fil .filters-responsive{
    top: 7.6rem;
    right: 4.5rem;
  }
  form .dashboard-grey .heading-4 {
    background-color: #ffffff !important;
}
  .responsive-sorting{
    display: block !important;
    position: absolute;
    color: #1C008A;
    right: 1rem;
    top: 7.4rem;
    font-size: 1.2rem;
  }
  .responsive-sorting i{
    font-size: 1.2rem !important;
  }
  app-agreements-saved .sorting-filters {
   display: none;
  }
   .responsive-sorting .Sort-agreements{
    display: block !important;
    bottom: 0;
    background-color: white;
    z-index: 10000000;
    width: 90% !important;
    position: fixed !important;
    padding: 1rem !important;
    right: 1rem !important;
    border-top-left-radius: 80px !important;
    border-top-right-radius: 80px !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    box-shadow: 1px 1px 10px 8px rgb(0 0 0 / 13%) !important;
  }
  .connections-top app-import-clients-popup .btn-style-5,.import-log,app-import-clients-popup .action-link {
   display: none;
}
  .mobile-menu{
    display:flex !important;
    transition: all .5s ease;
    text-align: center;
    transform-origin: left bottom;
  }
  .Inner-menu-items{
    width: 25%;
    position: relative;
  }
          .mobile-fade {
            transition: all .5s ease-in-out;
            transform-origin: left bottom;
            transform: scaleY(0);
          }
  app-agreements-list .agreement-menu, .MyInvoices .connections-search{
    margin-top: -4rem !important;
  }
  .responsive-button{
    display: none;
  }
  .dashboard-container{
    margin-bottom: 4rem !important;
    margin-top: 1rem !important;
    padding-bottom: 3rem;
  }
  .settings-labelstyle1{
   margin-left: unset !important;
  }
      .Payments-method .payment-type{
        margin-bottom: 2rem;
      }
      .invoices-body .settings-labelstyle3 {
        top: unset !important;
    }
    .import-sorting{
      display: revert !important;
      margin-top: 3rem ;
    }
     .Payment-sched .import-sorting .connections-search ,.import-sorting button{
      width: 100% !important;
      position: relative;
  }
  .Agreements-rightpart .SelectTopay .connection-item {
    max-width: 100% !important;
}
  .import-dates .form-group{
   width: 100%;
  }
  .import-sorting .sorting-dropdown{
    margin-left: 1rem;
  }
  .user-dashboard-middle .connection-info {
    max-width: 65% !important;
    min-width: 60%;
}
button.mat-icon-button.mat-button-base.mat-accent.ng-star-inserted {
  margin-top: 6rem!important;
}
  .user-dashboard-middle  span.invited,.user-dashboard-middle  .invoice-date {
    left: unset !important;
    position: unset !important;
    display: flex;
 }
    .import-sorting div{
      margin-top: 0.5rem;
      width: 100%;
    }
    .group-filters .Sort-agreements {
       width: unset !important;
    }
        }  
        @media screen and (max-width:900px){
          .user-dashboard-middle .connection-item {
            flex: 49% !important;
            max-width: unset !important;
        }
        }
        @media screen and (max-width:760px){
          .Pay-Now .pay-now-filters {
           width: unset !important;
        }
          div.wizard-dropzone .bottom-actions .btn-style-2 {
            margin-right: unset !important;
        }
        .Invoices-Container  .Details-container .InvoiceDetails table {
            width: unset !important;
        }
          .invoice-details-info {
            margin-top: unset !important;
        }
          .settings-permissions .sorting-dropdown  {
            left: 1.5rem;
            top: 12rem !important;
        }
        .account-verif-main {
          margin: unset !important;
      }
      .connections-mainInfo, .connections-MoreActions {
       padding: unset;
    }
        .MyPayments .settings-permissions .sorting-dropdown{
          left: 1rem;
        }
        .Agreements-rightpart .profile-middle-content {
          padding: 1.5rem 0.5rem !important;
      }
        .sorting-dropdown .Sort-agreements{
          left: unset !important;  
          right: -1rem !important;
          width: 15rem !important;
        }
        .invoice-details-info .heading-3 {
          margin-top: unset !important;
          margin-bottom: unset !important;
      }
    .InvoiceDetails  .roll-back button {
        min-width: 12rem !important;
    }
      .InvoiceFooter{
      display: none;
      }
      .responsive-table{
        margin: 1rem;
        overflow-x: auto;
      }
        }
        .FiltersOpened{
          display: unset !important;
        }
        .mobile-menu{
          bottom: 0;
          position: fixed;
          z-index: 8737336;
          background-color: white;
          width: 100%;
          height: 60px;
          display: flex;
          border-top: 1px solid #70707063;
          padding: 0.5rem;
          display: none;
        }
        .mobile-menu .dashboard-SvgLabels {
          color: #CDCACA;
          font-size: 0.65rem;
          text-align: center;
          margin-top: unset !important; 
           opacity: unset !important;
      }
            .add-mobile {
              position: absolute;
              right: 43%;
              border-radius: 50px;
              width: 3rem;
              height: 3rem;
              padding-top: 0.6rem;
              background-color: white;
              text-align: center;
              box-shadow: 1px 1px 10px 5px rgb(0 0 0 / 13%);
              top: -1.5rem;
              color: #D7008A;
              font-size: 1.2rem;
            }
            .add-mobile i{
              color: #D7008A;
            }
   
  @media screen and (max-width:1025px){
   .dashboard-grey ul.menu-style-1 li a ,.profile-left-menu ul.menu-style-1 li a{
     background-color: unset !important;
  }
    .profile-middle-content .bottom-actions .btn-style-1 {
      float: unset;
  }
    .agreement-payment-container {
      margin-right: 1rem !important;
      width: unset !important;
  }
    .terms-types {
      width: unset !important;
      display: unset !important;
      text-align: center;
  }
    .agreement-amounts .action-link {
      margin-top: 1rem;
      top: 2rem !important;
      right: 1rem !important;
  }
    .agreement-amounts .mat-form-field-infix {
      width: 150px !important;
  }
    .agreements-stepper .heading-3 {
      margin-bottom: 1.5rem !important;
      margin-top: unset !important;
  }
    .step-name {
      margin-top: 1rem;
      font-size: 0.9rem;
  }
    .steps-agreement .far, .steps-agreement .fas {
      left: 3.5rem !important;
      right: unset !important;
  }
    .step-circle:after {
      width: 90%;
      height: 2px;
      position: absolute;
      top: 0.55rem;
      left: 60%;
      background: #1c008a69 !important;
  }
    .dashboard-left .steps-agreement .step-circle {
      text-align: center;
      height: 5rem !important;
  }
    .View-agreement-container .Agreement-details .Payment-sched {
      margin-top: 1rem;
  }
    form .HasTemplate {
      margin-top: unset !important;
  }
  .View-agreement-container .agr-docs-container .heading-3{
    margin-bottom: unset !important;
  }
    .Payment-sched .Agreements-rightpart .btn-style-5 {
      display: none;
  }
    .View-agreement-container .Agreements-rightpart .col-3 {
      min-width: 4.5rem !important;
      padding: 0.5rem !important;
      margin-right: unset !important;
      margin-bottom: 1rem;
      max-width: unset !important;
  }
    .UpperFilter ul.list-types {
      width: 110% !important;
      margin: auto !important;
      display: unset !important;
  }
    .View-agreement-container .documents-info {
      position: unset !important;
  }
    .Payfrom-account {
      width: unset !important;
  }
  .confirm-payment .cancelPay i {
 padding-top: unset !important;
}
    .connection-info-container {
      display: unset;
  }
    .connections-filters  li label{
      font-size: 0.67rem !important;
    }
    .needsFilters .dashboard-grey .dashboard-left{
      height: 25rem;
      overflow-y: auto;
      overflow-x: hidden;
    }
   .needsFilters .dashboard-grey{
      display: none;
      z-index: 100000000;
      height: unset;
      box-shadow: 1px 1px 10px 8px rgb(0 0 0 / 13%);
      position: fixed !important;
      border: 1px solid gray;
    background-color: white !important;
    border-radius: unset !important;
    padding: 2rem 1.2rem !important;
    margin-right: 1rem;
    border-top-right-radius: 80px !important;
    border-top-left-radius: 80px !important;
    bottom: 0;
    }
    body.show-import-sidebar .right-sidebar {
      height: 95vh !important;
  }
  .fixed-helpMessage {
    padding-bottom: 3rem;
    margin-bottom: 4rem;
    margin-left: 2rem;
    margin-top: -3rem;
    position: unset !important;
}
    .dashboard-grey  ul li label {
     width: unset !important;
    }
    .form-group .mat-form-field-type-mat-select {
      line-height: 2rem;
  }
    .filters-responsive{
      display: unset !important;
    }
    .connections-container .filters-responsive{
      top: 18.4rem !important;
      /* z-index: 8888; */
    }
    .connections-container .search-input input[type="text"]{
      width: 87%;
    }
    .View-agreement-container .dashboard-middle {
      padding: unset !important;
      border-left: unset !important;
    }
    .connections-filters{
      padding-bottom: 3rem !important;
    }
    .SingleItemAgreement{
      width: unset !important;
    }
    .view-customers .connections-search{
      display: unset !important;
    }
    .view-customers .connections-search .search-input{
      margin-bottom: 1rem;
    }
    .Agreements-rightpart .no-documents-found{
      width: unset !important;
    } 
    .Agreement-details .headingstyle1{
      display: unset !important;
    }
    .Agreement-details .All-selected {
      display: unset !important;
    }
    .agr-docs-container  .headingstyle1{
      display: block !important;
    }
    .agr-docs-container .row .col-md-6{
      margin-bottom: 4rem;
    }
    .Agreement-details .Total-block{
      margin-top: 5rem !important;
      width: unset !important;
    }}
  @media screen and (max-width:765px){
      .available-balance{
        margin-bottom: 1rem;
      }}
  @media screen and (max-width:690px) {
   .carousel button.mat-icon-button.mat-button-base.mat-accent.ng-star-inserted:first-of-type{
       margin-left: unset !important; 
    }
    .View-agreement-container .confirm-payment .image .agreement-status {
      top: -1.55em !important;
  }
  .steps-agreement .far, .steps-agreement .fas {
    left: calc(50% - 0.2vw) !important;
    right: unset !important;
    transform: translateX(-50%);
}
.agreement-payment-container {
  margin-right: 1rem !important;
  width: unset !important;
}
.step-circle:after {
  width: 76%;
  left: calc(61% + 0.9vw);
}
.remove-payment {
 top: 0 !important;
}
.agreement-payment-container .action-link.danger {
  line-height: 1.2rem !important;
}
.agreement-payments-actions .Add-doc-block {
  width: 250px !important;
}
.documents-info {
  float: unset !important;
}
.documents-info ul {
display: unset !important;
}
.agreement-payment-container .profile-verified-notice {
padding: 1rem !important;
}
.terms-types label{
  margin: auto;
    margin-top: 1rem;
}
.headlines{
  width: unset !important;
  top: -4.5rem !important;
}
form .agreement-info .connection-item {
 max-width: 100% !important;
}
.steps-container {
  padding: unset !important;
  min-height: unset !important;
}
.with-shadow {
  margin-bottom: unset !important;
}
  .agreements-stepper .form-group textarea.form-control {
    min-width: unset !important;
}
.agreement-amounts .tabs-style-2 {
  display: unset !important;
}
.agreement-amounts ul.tabs-style-2 li {
  margin: 0.2rem 0.375rem !important;
}
.agreement-info-step .agreement-info2-list .menu-style-2 {
  width: 100% !important;
}
.agreements-stepper ul.menu-style-2 {
margin-left: unset !important;
}
.agreements-stepper .heading-3{
margin-bottom: 1.5rem !important;
margin-top: unset !important;
}
.menu-style-1 .relative-position {
  margin-top: unset !important;
}
.relative-position .Payments-Details {
  margin-top: unset !important;
  margin-left: unset !important;
}
.agr-docs-container .heading-3{
  margin-bottom: 0rem !important;
}
.View-agreement-container .documents-info {
 position: unset !important;
}
.View-agreement-container .agr-docs-container {
  margin-top: unset !important;
}
.gray-notice {
display: none;
}
.documents-list .multiple-docs .document-title{
  width: unset !important;
}
.agreements-stepper .documents-row1 ul {
 width: unset !important;
}
.agreements-stepper ul.menu-style-2.SingleValue {
  width: 50% !important;
}
  .confirm-payment .cancelPay{
    line-height: 1rem !important;
  }
     .MyInvoices .image .agreement-status.NoPaymentMethods {
      top: -1.5em !important;
    }
    .modal-body .send-money-container .modal-agr .agreement-actions {
      width: unset !important;
  }
    .Agreement-details .Amount-title .list-types{
      display: unset !important;
      margin-top: 1rem;
    }
    .MyInvoices .Agreements-rightpart .connection-item{
  padding-bottom: 1.2rem !important;
    }
    .profile-middle-content .bottom-actions .btn-style-1 {
      float: unset;
    }
    .user-dashboard .connection-item-right {
      margin-left: 1rem !important;
      margin-top:0.5rem;
    }
    /* .user-dashboard .Agreements-rightpart .connection-item .fas{
      display: none;
    } */
   .user-dashboard .MyInvoices .connections-list.InvoicesList .connection-photo {
      display: none ;
  }
  .connections-middle .connections-list .connection-actions-options {
    right: 0 !important;
}
    .ListAgreements .InvoicesList .connection-info{
      max-width: 60% !important;
    }
    .payments-listing .connection-info {
      margin-left: 20px;
  }
    .payments-listing span.invited, .payments-listing .invoice-date {
      left: 70% !important;
  }
  .needsFilters .settings-labelstyle3{
    display: none;
  }
  .sharing-tools {
    margin: 1.625rem 0rem !important;
}
.upload-amounts-table{
  min-width: 50rem;
}
.HasTemplate .row{
  flex-wrap: unset;
}
.view-customers .btn-style-5{
  margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
}
     .MyInvoices .ListAgreements .InvoicesList .image .agreement-status{
      top: -1rem !important;
    }
   .user-dashboard .MyInvoices .ListAgreements .InvoicesList .image .agreement-status {
      top: -1.9em !important;
  }
  .user-dashboard .MyInvoices .image .agreement-status {
    top: -1.4em !important;
}
 .user-dashboard .MyInvoices .InvoicesList .image .invited {
    left: 70% !important;
}
.Invoices-Container .InvoiceDetails table{
  margin:unset !important;
  width: 100% !important;
  padding: 1rem !important;
}
.Invoices-Container .Details-container  .InvoiceDetails table{
  margin-top: unset !important;
  margin-bottom: 5rem !important;
}
.settings-bottom-buttons.invoice-details-info .btn-style-2{
  height: unset !important;
}
.connections-mainInfo .connections-info-right {
  margin-left: unset !important;
  margin-right: unset !important;
}
.Details-container .InvoiceDetails .Payments-Details.Agreement-details table{
  padding: 1rem !important;
  width: unset !important;
}
.Invoice-table .AmountsTableRows td.col-md-4 {
 display: revert;
}
.Details-container .InvoiceDetails .settings-bottom-buttons{
  margin-left: 1rem;
  display: flex !important;
}
.table-responsive {
  overflow-x: auto !important;
}
.settings-bottom-buttons.invoice-details-info .btn-style-2,.invoice-details-info .btn-style-1 {
  min-width: 9rem !important;
  margin-bottom: 1rem;
}
.OverDue-Svg.Invoice-Overdue{
  display: none !important;
}
.Agreements-rightpart .profile-middle-content.Transactions {
  padding: unset !important;
}
.Receipt{
  min-width: 40rem;
  padding: 1rem;
}
.Payments-Actions{
  display: unset !important;
}
.profile-middle-content.Transactions .heading-2{
  margin-left: unset !important;
}
  .MyInvoices .Transactions .image .invited {
    left: 70% !important;
}
    .connection-info-container ,.role-managemenet-main .bottom-actions{
      display: unset;
    }
    .settings-notification .switch {
      margin-right: unset;
    }
   .settings-notification{
      width: unset;
    }
    .settings-info-container .btn-style-1 {
      min-width: 10rem;
    }}
  @media screen and (min-width: 960px){
  .LoginFormProfile .user-auth-form {
      height: unset; 
      padding-bottom: 0px !important;
      margin-top: 1rem;
      margin-bottom: -4rem;
  }
  body:not(.has-scroll) .LoginFormProfile .bottom-actions {
    position: unset;
    width:unset;
  }}
  @media screen and (min-width: 960px){
    body:not(.has-scroll) .profile-middle-content .bottom-actions {
        width: 100% !important;
        position: unset !important; 
    }
    body:not(.has-scroll) .profile-middle-content .bottom-actions.agreement-actions {
      width: 50% !important;
      margin-top: 2rem;
    }}
    @media screen and (max-width: 1500px){
        .no-documents-found {
          width: unset ;
      }}
      @media screen and (min-width: 960px){
         .steps-agreement {
      top:unset;
      transform: unset !important;
      height: 100%;
      }}
      @media screen and (min-width: 960px){
        body:not(.has-scroll) .modal-agr .bottom-actions {
          position: unset;
          width: unset;
          margin-top: 1rem;
        }}
        @media screen and (min-width: 960px){
          body:not(.has-scroll) .bottom-actions {
              margin-bottom: 1rem;
              position: fixed;
            }}
            @media screen and (max-width: 1250px){
                .confirm-payment {
                  min-width: unset !important;
              }
              .searchforInvoice.agreement-info2 {
               width: unset !important;
            }}
              @media screen and (max-width: 1300px) and (min-width: 1025px){
                .agr-invoices .noInvoices  {
                  width: 58rem !important;
              }}
  /* AGREEMENTS  */
  .Clear-All{
    float:right;
    color:#00008A;
  }
  .agr-documents .documents-list .multiple-docs .document-title{
width: unset !important;
  }
  .Clear-All .fas{
    color:#00008A;
    font-size:1.25rem;
  }
  .head-1{
  font-weight: 500;
  color: #2706a9;
  }
  .list-1{
    margin-top:0.5rem !important;
    margin-bottom: 1rem !important;
  }
  .view-image {
    width: 80rem;
  }
  .list-1 li{
    vertical-align: unset;
  }
  .date-rows{
    margin-top:1rem;
  }
  .sidebar-list ul.menu-style-1:not(.start) li:not(.active) a ,.sidebar-list ul.menu-style-1:not(.start) li:not(.active){
  opacity: 1 !important;
  }
  .Agreements-rightpart .profile-middle-content{
   padding:1.5rem;
  }
  .Agreements-rightpart .col-3{
    border-radius: 50px;
    border:1px solid #CDCACA;
    font-size:1rem;
    text-align: center;
    padding:0.7rem;
  }
  .payment-method img{
    width: 75%;
    min-height: 2rem;
  }
  .Agreements-rightpart .connections-search{
    float:unset;
  }
  .Agreements-rightpart .connections-list{
    margin-top:0.5rem;
  }
  .list-types {
    list-style-type: none;
    display: flex;
    margin-left: 0rem;
    margin-right: 19.5rem;
    padding-left: 0rem;
  }
  .invoices-body .payments-list .connection-item{
    min-width: 100% !important;
  }
  .invoices-body .headingstyle1 {
  line-height: 3rem;
  }
  .UpperFilter ul.list-types{
  width:56%;
  margin-right: unset;
  margin-top: 1rem;
  }
  .UpperFilter .available-balance {
    line-height: 3.4rem;
  }
  .UpperFilter .btn-style-5 .fas {
    margin-top: unset !important;
    font-size: 1.3rem;
  }
  .UpperFilter .btn-style-5 span {
    display: unset !important;
  }
  .UpperFilter .btn-style-5 {
    padding: 0rem 1rem;
    border-radius: 25px;
  }     
  .sorting-dropdown{
    margin-left:auto;
  }
  .sorting-dropdown .fas{
    margin-top:0.9rem;
    font-size:1rem;
  }
  .sorting-dropdown .btn-style-5{
    min-width: unset;
  }
  .Sort-agreements a {
    font-size: 0.875rem;
    color:#000000 !important;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    padding:0 1.8rem;
  }
  .Sort-agreements a:hover {
    background-color: #1c008a1f;
    font-weight: 500;
  }
  .Payment-sched  .confirm-payment .back-button{
    display: none;
  }
  .Payment-sched  .confirm-payment{
    margin-top: -2rem !important;
    padding: unset !important;
  }
  .Payment-sched  .confirm-payment .Payfrom-account span {
 font-size: unset !important;
}
  .Payment-sched  .confirm-payment .connection-item{
   padding-bottom: 1rem !important;
  }
  .Sort-agreements a span {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    border-bottom: 0.5px solid #cdcaca78;
    padding:1rem 0;
  }
  .View-agr-options{
    left: 96% !important;
      font-weight: bold !important;
      top: 1.5rem !important;
      font-size: 1.5rem;
      color: #80808052 !important;
  }
  .ListAgreements span.invited{
    left: 70%;
  }
  .ListAgreements span.invited .far{
    font-weight: 600 !important;
  }
  .Payment-sched .invoices-body .btn-style-10{
    margin-top: 3% !important;
    right: 9%;
  }
  .ListAgreements span.invited .far:hover{
    color:#00008A;
  }
  .ListAgreements .connection-item:hover .Sort-agreements, .image:hover .Sort-agreements{
    visibility: visible;
    opacity: 1;
  }
  .agreement-info2 .sorting-dropdown .Sort-agreements{
    right:unset;
    top:unset;
  }
 .create-template{
  text-align: center;
  color: #979797;
  font-size: 1.3rem;
  padding-top: 10rem;
 }
  .view-customers .btn-style-5{
    min-width: 7rem;
    font-size: 1rem;
    display: unset;
    padding: 0rem 1rem;
    border-radius: 25px;
    margin-right: 1rem;
    text-align: center;
  }
  .select-payment-template{
    display: unset !important;
  }
  .select-payment-template .agreement-payment-container{
    width: unset;
    display: revert;
  }
  .select-payment-template .profile-verified-notice{
    text-align: center;
    box-shadow: unset !important;
  }
  .view-customers .btn-style-5 span{
    display: unset !important;
  }
  .Currencies span:hover{
  background-color: #1C008A;
  color:white;
  }
  .sorting-dropdown .Sort-agreements{
    width: 25rem;
    padding:0rem;
    right: 4%;
    top: 24%;
      background-color: #fff;
      position: absolute;
      z-index: 10;
      border: 1px solid #0073B1;
      border-radius: 25px;
      -webkit-transition-property: all;
      -webkit-transition-duration: 0.5s;
      -webkit-transition-timing-function: linear;
      -webkit-transition-delay: 0s;
      -ms-transition-property: all;
      -ms-transition-duration: 0.5s;
      -ms-transition-timing-function: linear;
      -ms-transition-delay: 0s;
      transition-property: all;
      transition-duration: 0.5s;
      transition-timing-function: linear;
      transition-delay: 0s;
  }
  .receiver-info-display,.sorting-dropdown:hover .fa-chevron-up,
  .sorting-dropdown .sorting-filters:hover .Sort-agreements{
  visibility: visible;
  opacity: 1;
  }
  .Agreements-rightpart .invited{
    position: absolute;
    top: 1.8rem;
      left: 190%;
      z-index: 1;
      color: #CDCACA;
      font-style: unset;
  }
  .Agreements-rightpart .image{
    position: relative;
  }
  .Agreements-rightpart  .agreement-status{
    position: absolute;
    writing-mode: tb-rl;
    height: 102%;
    text-orientation: sideways;
    transform: rotate(-180deg);
    left: 0rem;
    text-align: center;
    top: -0.1rem;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    font-size: 0.8rem;
    text-transform: uppercase;
  }
  .agreement-status-290,.agreement-status-287,.agreement-status-361,.agreement-status-385,.agreement-status-286,.agreement-status-283,.agreement-status-356,.agreement-status-329{
    background-color: #CDDAFD;
  }
  .agreement-status-292,.agreement-status-289,.agreement-status-360,.agreement-status-284,.agreement-status-353,.agreement-status-328,.agreement-status-355{
    background-color: #FFF1E6;
  }
  .agreement-status-291,.agreement-status-288{
    background-color: #BEE1E6;
  }
  .user-dashboard-middle .load-more{
    display: none;
  }
  .fromTab{
    margin: auto;
    width: 80%;
    padding-top: 3rem;
  }
  .agreement-status-445,.agreement-status-18{
    background-color: #0000ff54;
  }
  .Payments-Details table{
    width: 60%;
  }
  .reciept-additional-info{
    background-color: #1c008a0f;
    padding: 1rem;
  }
  .SendMoneyTo .right-sidebar .modal-actions{
    left: -3rem !important;
  }
  .SendMoneyTo  .right-sidebar{
    left: 105% !important;
  }
  .receipt-labels{
    color: grey;
  }
  .Info-rows.row{
    margin-left: unset !important;
    margin-right: unset !important;
  }
  .Payments-Details .Invoices-Container{
    margin-top: unset !important;
    padding: 1rem;
  }
  .menu-style-1 .relative-position{
    margin-top: -3rem;
  }
  .menu-style-1 .relative-position a{
    position: absolute;
    left: -1.3rem;
  }
  .Payments-Details .InvoiceDetails{
    padding: unset !important;
  }
  .invoices-actions .Invoices-Container{
    margin:0rem !important;
  }
  .responsive-sorting{
    display: none;
  }
  .invoices-actions  .dashboard-middle{
    padding:2.2rem 0rem !important;
    border-left: unset !important;
  }
  .invoices-actions .InvoiceDetails{
    padding:0rem 2rem !important;
  }
  .agreement-summary fieldset,.invoices-actions,.Payments-Actions,.agreement-info2-list,.Payment-sched .sorting-dropdown,.Agreement-details .headingstyle1{
    display: flex;
  }
  .Payments-Actions a{
    margin-top: 0.8rem;
    margin-right: 1rem;
    font-size: 1.2rem;
    font-weight: 500;
  }
  .receiptList{
    display: flex;
    margin-bottom: 1rem;
    gap: 25px;
    overflow-x: scroll;
  }
   .InvoiceDetails .Receipt svg {
    margin-top: unset !important;
  }
  .invoice-details-info .btn-style-2{
    line-height: 2.3rem !important;
    min-width: 13rem !important;
  }
  .Receipt{
    border-radius: 15px;
    margin: 0.5rem;
    min-width: 30rem;
    box-shadow: 1px 1px 8px 0px rgb(0 0 0 / 13%);
  }
  .Receipt td{
    font-size: 0.9rem !important;
    padding: 0rem 1rem;
  }
  .isprimary{
    margin-top: 1rem;
  }
  .invoices-actions a{
    margin-top: 2rem;
      font-size: 1.3rem;
      font-weight: 500;
  }
  .agreement-status-308,.agreement-status-285,.agreement-status-354,.agreement-status-327,.agreement-status-384,.agreement-status-375{
    background-color: #FAD2E1;
  }
  .Receipt-Icon{
    position: absolute;
    left: 90%;
    top: 2rem;
  }
  .View-agreement-container .Payments-Details table {
    width: 100% !important;
}
  .list-1.list-types li div{
  height:100%;
  font-size: 0.5rem;
  }
  .list-1.list-types li{
    padding-left:unset;
    height: 35px;
    width: 35px;
    padding-right:unset;
    padding:unset;
    border-radius: 5px;
    border: 1px solid #CDCACA;
    font-size: 1rem;
    text-align: center;
    margin-right: 0.5rem;
    flex: 0 0 17%;
    opacity: 0.5;
  }
  .list-1.list-types .col-3:hover {
    border: 1px solid #00008A !important;
  }
  .agr-invoices .settings-bottom-buttons{
    margin-top: 3% !important;
  }
  .list-1.list-types li img{
    width: 80%;
    height: 80%;
    margin: auto;
    display: block;
    margin-top: 0.3rem;
  }
  ul.agreement-menu li a{
    background-color: unset;
  }
  .Agreements-rightpart .connection-item .fas{
    color: #1C008A;
    font-size: 1.2rem;
    position: absolute;
    bottom: -3.5rem;
    left: 2.5rem;
  }
  .confirm-payment .payment-method ul.list-types{
    height: 3rem;
  }
  .confirm-payment .settings-bottom-buttons{
    margin-top: 10% !important;
  }
  .Create-invoice-main .agr-invoices{
    width: 80%;
    padding-bottom: 2rem;
    margin: auto;
  }
  .createInvoice .create-agr-invoice{
    width: 82%;
    margin: auto;
    margin-top: 1.5rem;
  }
  .otherparty .connection-phone{
    color:#979797 !important;
  }
  .otherparty .connection-name{
    font-size: 1rem !important;
    font-weight: unset !important;
  }
  .agr-invoices .row1-summary .row .agreements-docs-button{
    display: unset !important;
  }
  .agr-invoices .row1-summary .row .btn-style-5{
  float: right;
  line-height: 2.8rem;
  }
  .agr-invoices{
    background-color: white;
    border-radius: 5%;
  }
  .agreementRelated label{
    float:unset !important;
  }
  .otherparty .connection-item:hover{
    border-right:2px solid transparent;
    border-top:2px solid transparent;
    border-bottom:2px solid transparent;
  }
  .Transactions .connection-info{
    min-width: 50%;
    max-width: 70% !important;
  }
  .Agreements-rightpart .connection-actions-options{
  visibility: visible;
  }
  .Agreements-rightpart .connection-item {
   border:2px solid transparent;
   border-left:0px;
   }
    .agreement-menu li.active a,.list-types li.active {
      color: #fff !important;
      background-color: #00008A;
    }
  .list-types li.active a{
    color: #fff !important;
  } 
  .wizard-content .wizard-pagination .btn-style-1 {
    min-width: 12.25rem;
  }
  .wizard-content .wizard-pagination .btn-style-2 {
    min-width: 9rem;
  }
  .wizard-content .wizard-stepper-block {
  padding:unset;
  width:unset;
  }
  .inProgress{
    font-style: italic;
    font-size: 0.75rem;
    font-weight: 550;
  }
  .BoldedStep{
    font-weight: 650 !important;
    color: #FFA72E;
    opacity: 100% !important;
  }
  div.step-circle.step-5.ng-star-inserted:after{
  height:0px;
  }
  .Agreements-rightpart .no-documents-found{
    transform: translateY(30%);
  }
  .create-buttons{
    padding: 0rem 1rem;
    text-align: center;
  }
  .create-buttons .btn-style-2{
    min-width: 100%;
  }
  .MyInvoices .profiile-flex-container,.MyInvoices .Agreements-rightpart{
    width: 100%;
  }
  .create-buttons .butn{
    min-width: 20rem;
  }
  /* AGREEMENT DESIGN */
  .agreements-container  .heading-5 {
    margin-left:2rem;
    font-weight: 500;
    margin-bottom: -1rem;
  }
  .agreements-stepper .wizard-stepper-block{
    width:100% !important;
  }
  .agreement-info .form-group{
    display: inline-block;
    margin-right:2rem;
    width:99%;
  }
  .agreement-info .form-group textarea.form-control {
    height: 5.375rem !important;
}
  .agreement-info .mat-button-toggle-group-appearance-standard{
    height:3rem;
  }
   .mat-button-toggle-appearance-standard .mat-button-toggle-label-content{
    line-height: 36px !important;
  }
  .agreement-info .btn-style-1{
    text-transform: unset;
    font-weight: 500;
    line-height: unset;
    width: 95%;
    margin-left: 1.5rem;
  }
  .agreement-info2 .no-documents-found{
    margin-top: 3rem;
  }
  .user-dashboard-middle .connections-list{
    grid-gap: 10px !important;
    padding-top: 1rem !important;
  }
  .user-dashboard-middle  .connection-item{
    padding: 0.5rem 1rem;
    padding-bottom: 0.5rem !important;
    margin-top: unset !important;
  }
  .user-dashboard-middle .connection-item .connection-photo{
    display: none;
  }
  /* .user-dashboard-middle .payments-listing .connection-item .Fees{
    top: -4rem !important;
  } */
  .user-dashboard-middle .Agreements-rightpart .connections-list.InvoicesList .connection-item .fas {
    bottom: -2.5rem;
    left: 1rem;
}
.user-dashboard-middle .connections-list.InvoicesList .connection-item-right {
  margin-left: 3.2rem;
}
  .user-dashboard-middle .payments-listing .connection-item .fas {
   font-size: 1.2rem !important;
}
  .agreement-info2 .search-input{
    width:100%;
    margin-right: 1rem;
  }
  .agreement-info2 .search-input input[type="text"] {
  border-radius: 20px;
  }
  .agreement-info2-list .menu-style-2{
    margin-left:auto;
    margin-bottom: 1rem;
    width: 30% !important;
    margin-right: 1rem;
  }
  .sorting-dropdown ul.tabs-style-2 a{
    border: 1px solid #EFEFEF;
    padding: 0.5rem 1.5rem !important;
    height: unset !important;
  }
  input[type=radio] {
    accent-color: #3110b6;
    width: 20px;
    height: 20px;
  }
  .otherparty .connection-actions{
    left: 75%;
      position: absolute;
  }
  .my-contacts-List .connection-item{
    display: flex;
    padding-top: 1rem !important;
    margin-top: unset;
    border-right: 2px solid transparent !important;
    border-top: 2px solid transparent !important;
    border-bottom: 2px solid transparent !important;
  }
  .my-contacts-List{
    display: flex;
    grid-gap:15px;
    flex-wrap: wrap;
    margin:1rem 0.1rem;
  }
  .my-contacts-List .connection-item-right{
    margin-left:1rem !important;
  }
  .my-contacts-List  .connection-actions{
    margin-left: auto;
  }
  .my-contacts-List .select-all{
    line-height: 3rem;
  }
  .agreement-info2 .sorting-dropdown{
    margin-top: 1rem;
  }
   .have-agr{
    margin-top:2.5rem;
  }
  .steps-agreement .step-circle{
    height: 8rem !important;
  }
  .steps-agreement .far,.steps-agreement .fas{
    top: 0.2rem;
    font-size: 0.8rem;
    color: #1C008A;
    left: 0;
    position: absolute;
  }
  .steps-agreement .fas{
    display: none;
  }
  .agreements-stepper ul.menu-style-2 {
    width: 27rem;
    display: flex;
}
  .agreement-info .fa-search, .agreement-info2 .fa-search{
    transform: scaleX(-1);
    font-weight: 700;
  }
  .agreement-actions .btn-style-2{
    min-width:45% !important;
    height:3.4rem !important;
    display: inline-block !important;
    text-transform: uppercase;
    line-height: 3 !important;
  }
  .user-auth-form .agreement-actions button{
    min-width: 21% !important;
    margin-right: 1rem;
    border-radius: 50px;
  }
  .middle-actions{
    margin-bottom: -2rem !important;
    width: 23rem;
    margin: auto;
    margin-top: 2rem !important;
  }
  .user-auth-form .agreement-actions{
    position: absolute;
    width: 100%;
    bottom: 0rem;
  }
  .user-auth-form .address-actions{
    bottom: -1rem;
    margin-left: -1.5rem;
  }
  app-professional-info .wizard-form-container{
    width: 50%;
    margin-bottom: 2rem;
  }
  .Addresses-container{
    display: flex;
  }
  .addresses-left,.addresses-right{
    width: 50%;
    margin-right: 3rem;
  }
  .agreement-info2-list .fa-chevron-left{
    margin-right:1.5rem;
    font-size:1.2rem;
    line-height: 2rem;
    font-weight: 550;
  }
  .NoAmounts{
    width: 50rem;
  }
  .agreement-amounts .mat-form-field-underline{
    width:unset;
  }
  .agreement-amounts .mat-form-field-appearance-fill .mat-form-field-flex {
    border-radius: 10px;
    border: 1px solid #1C008A;
    background-color: unset;
    padding: 0.2em 0.3em;
  }
  .agreement-amounts .mat-form-field-appearance-fill .mat-select-arrow-wrapper {
    transform: translateY(-10%);
  }
  .agreement-amounts .mat-select-value-text {
    margin-left:0.5rem;
  }
  .agreement-amounts .mat-select-arrow {
    color: #1C008A !important;
    margin:0 7px;
  }
  .agreement-amounts .mat-form-field-infix {
    width: 60px ;
    margin-top: -0.8rem;
    padding: 0.5em 0 !important;
  }
  .agreement-amounts .VAT .row{
   width:85%;
  }
  .agreement-amounts .VAT {
    margin-bottom: -0.5rem;
  }
  .agreement-amounts .action-link{
    background-color: unset;
    height:3.4rem;
    line-height: 3.2rem;
    width:3.4rem;
    position: absolute;
    top: -3rem;
    right: 0;
    font-size: 1.5rem;
  }
  .agreement-amounts .form-group input[type="checkbox"]:checked{
    margin-left: 0.1rem;
  }
  .agreement-amounts .agreement-value{
    margin-bottom: 2rem;
  }
  .agreement-amounts .col-md-8{
  padding-left: 0.4rem;
  }
  .agreement-amounts .agreement-value:nth-of-type(2) {
    border-top: 1px solid #1C008A;
    padding-top: 2rem;
}
  .InfoCompleted{
    font-weight: 600 !important;
  }
  .agr-documents .custom-autocomplete{
    position: unset;
  }
  .agr-documents .custom-autocomplete label{
    text-align: center;
  }
  .agreements-font2{
    line-height: 3rem;
    color: #00008A;
    font-weight: 500;
  }
  .otherparty .connection-item-right{
    margin-left:4rem !important;
  }
  .otherparty label{
    margin-top: 2rem;
  }
  .otherparty .connection-item{
    display: flex;
    border: unset !important;
    box-shadow: unset !important;
    max-width: 60% !important;
    padding-top: 1rem !important;
    margin-bottom: 1rem;
  }
  .otherparty .action-link.default{
    position: absolute;
    left: 2rem;
      top: 0.5rem;
  }
  .add-note .btn-style-5{
  min-width: 25% !important;
  opacity: 0.5;
  }
  .add-note{
    margin-top: 1rem;
  }
  .add-note .sendnote{
    border-radius: 50%;
    position: absolute;
    width: 38px;
    height: 38px;
    right: 0.3rem;
    bottom: 0.3rem;
    padding-top: 0.2rem;
    font-size: 1.5rem;
    text-align: center;
    color: #A3A3A3;
    background-color: #dbdbdb9e;
  }
  .showActions a{
    position: relative;
  }
  .showActions a .fas{
    left: 0.4rem !important;
    position: unset !important;
  }
  .showActions a.success .fas{
    color: #00B6A8 !important;
  }
  .showActions a.danger .fas{
    color: #E74E5A !important;
  }
  .ListAgreements .showActions .connection-item-right   .connection-actions{
    position: absolute;
    right: 0;
  }
  .Agreement-details .upper-title-1{
    font-size: 1rem;
    font-weight: 550 !important;
    opacity: 0.9;
    color: #1C008A;
  }
  .mat-icon-button.mat-accent {
    color: unset !important;
  }
.change-mobile-pass .form-group{
  width: 50%;
}
.agr-sorting ul{
list-style-type: none;
}
.agr-sorting ul li{
  margin-top: 0.5rem;
  }
.view-customers .sorting-dropdown:nth-child(3){
  margin-left: unset ;
}

  button.mat-icon-button.mat-button-base.mat-accent.ng-star-inserted:first-of-type::after{
    content: "\003C";
    padding-right: 0.2rem;
  }
  button.mat-icon-button.mat-button-base.mat-accent.ng-star-inserted{
    left: 15rem !important;
    position: relative !important;
  }
  .carousel[_ngcontent-xia-c391] > button[_ngcontent-xia-c391]:last-of-type {
    right: 0 !important;
    left: 33rem !important;
  }
  .Payment-sched .fa-chevron-up,.sorting-dropdown .fa-chevron-up,.sorting-dropdown:hover .fa-chevron-down {
    display: none;
  }
  .Amount-title{
    display: flex;
    margin-left: 0.2rem;
    margin-top: 0.5rem;
  }
  .payment-up{
  display: block !important;
  }
  .UpperFilter.Agreements-rightpart ul.list-types{
    width: 40% !important;
  }
  .agr-invoices .mat-form-field-suffix .mat-icon {
  font-size: unset;
  line-height: 2.8rem;
  display: unset;
  padding-right: 0.8rem;
  color: #CDCACA;
  }
  .agr-invoices .mat-form-field-flex {
    position: relative;
    background-color: white;
  }
  .agr-invoices .sorting-dropdown .btn-style-5 {
    padding-left: 1rem;
    border-radius: 10px;
    min-width: 100% !important;
  }
  .cycleChosen{
    border: 1.5px solid #1C0D8A !important;
  }
  .WizardSteps4.ng-star-inserted:after{
    height:0px;
  }
  .cycleChosenText{
    font-weight: 550;
    color: #1C0D8A !important;
  }
  .Agreement-details .Payment-sched{
    border-top: unset;
  }
  .agr-invoices .sorting-dropdown .fas{
    position: absolute;
    right: 0;
    bottom: 1rem;
  }
  .Payment-sched .sorting-dropdown div a{
    line-height: 3rem;
    color: #00008A;
    text-decoration: underline;
  }
  .vat-amount{
    font-weight: lighter;
    font-size: 0.8rem;
    color: #66737A;
  }
  .amount-info {
    position: absolute;
    right: 7rem;
  }
  .payment-down{
    display: none !important;
    }
  .Payment-sched.connections-middle{
    padding:unset !important;
  }
  .Payment-sched .profile-verified-notice i{
    margin-left: auto;
    line-height: 3rem;
    color: #00008A;
  }
  .import-organize{
    overflow-y: auto;
    max-height: 33rem;
  }
  .MyInvoices .connections-list.InvoicesList .connection-photo{
    margin-left: 0.4rem;
    display: none;
  }
  .MyInvoices .connections-list.InvoicesList .connection-item-right{
    margin-left: 2.5rem !important;
  }
  .MyInvoices .connections-list.InvoicesList .fas{
    bottom: -3rem !important;
    left: 0.7rem !important;
  }
  .Payment-sched .connection-photo {
    margin-left:0.4rem;
  }
  .Payment-sched .agreementrelated{
    display: none;
  }
  .payments-list{
    display: flex !important;
    flex-wrap: wrap;
    grid-gap: 0.7rem;
    margin-bottom: 1rem;
  }
  .agr-invoices .Payment-sched{
    padding-top: unset;
    border-top: unset;
  }
  .Payment-sched .btn-style-10{
    width: 70px;
    bottom: unset !important;
    min-height: 28px;
    max-height: 28px;
    margin-left: auto;
    margin-top: 3%;
  }
  .Agreement-details .Amount-title .list-types{
    margin-right: 15rem !important;
    padding-left: unset;
    margin-left: auto;
  }
  .filters-responsive{
    position: absolute;
    display: none;
    top: 13rem;
    color: #1C008A;
    right: 2rem;
    font-size: 1.2rem;
  }
  .Payment-sched .invoices-body .invited,.Payment-sched .invoices-body .invoice-date{
    left: 75% !important;
  }
  .DetailsIcons{
  margin-left: 3.3rem !important;
  display: block !important;
  }
  .Payment-sched .sorting-dropdown .btn-style-5, .agr-invoices .sorting-dropdown .btn-style-5{
    min-width: 8rem;
    margin-right: auto;
  }
  .Payment-sched .sorting-dropdown span{
    margin-left: auto;
  }
  .payment-method{
    display: flex;
    min-height: 20px;
  }
  .paymentChosen{
    border:1.4px solid #1C008A !important;
  }
   .connection-actions .form-group input[type="checkbox"]:checked:before{
    border: 1px solid !important;
    border-radius: 10px !important;
  }
   .connection-actions .form-group input[type="checkbox"]:checked{
    background-color: unset !important;
  }
   .checkItem.connection-actions input {
    position: absolute !important;
    opacity: 0;
    cursor: pointer;
    z-index: 2;
    top: 0;
  }
  .View-agreement-container .checkmark{
    top: 0.3rem;
  }
  .checkmark {
    position: absolute;
    top: -0.3rem;
    left: 0.5rem;
    height: 15px;
    width: 15px;
    border-radius: 15px;
    border: 1px solid;
  }
   input:checked ~ .checkmark {
    background-color: #00008A;
    border: 1px solid white;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .Agreements-rightpart .connections-list  input[type=radio] {
      width: 20px;
      height: 20px;
      margin-top: 2em;
  }
  .selected{
    border: 1px solid #00008A !important;
    opacity: 1 !important;
  }
  .selected div {
   font-weight: 500 ;
}
  .SelectTopay .connection-actions{
   display: block !important;
    position: absolute;
    margin-right: 0.2em;
    left: -2rem;
    z-index: 3;
    bottom: -0.5rem;
  }
  .connections-list  .connection-item{
    position: relative;
  }
  .SelectTopay .connection-item{
    max-width: 30% !important; 
    margin-left: 1rem !important;
  }
   .profile-verified-notice .load-more a{
     color: white !important;
   }
  .SelectTopay .btn-style-10{
    position: absolute !important;
    bottom: unset !important;
  }
  .Long-summary{
    text-align: right;
    font-size: 1.3rem;
    font-weight: 500;
    padding: 1rem;
  }
  .relative-position .Payments-Details{
    margin-top: -3rem;
    margin-left: 4rem;
  }
  .UpperFilter.Agreements-rightpart .list-types li.active{
   background-color: unset;
  }
  .needsFilters .settings-labelstyle3{
    display: none;
  }
  .needsFilters .Pay-Now .settings-labelstyle3{
    display: unset !important;
  }
  .payment-method ul.list-types{
    margin-top: 0.1rem;
    margin-right: unset;
    margin-bottom: -0.5rem;
    width: 10rem;
  }
  .payment-method .col-3{
    padding: 0px 0px !important;
    border: unset;
  }
  .iconsactiveLink{
    color:#1C008A;
  }
  .iconsactive{
    background-color: white;
  }
  .agreementRelated li{
    display: flex !important;
  }
  .agreementRelated input[type=radio] {
      margin-top: 0.5rem;
      position: absolute;
  }
   .image .NoPaymentMethods{
    bottom:-6.9em !important;
  }
  .Details-container .InvoiceDetails table{
width: 83% !important;
margin-top: -1rem !important;
padding-top: 0;
  }
  .Total-block td{
 border-top: 0.5px solid #70707040;
 border-bottom: 0.5px solid #70707040;
 padding:1rem 1rem;
  }
  .payment-list .image .NoPaymentMethods{
    bottom:-6.7em !important;
  }
  .ViewAgr-Actions .action-link{
    height: unset; 
    padding: 0rem 2rem;
    margin-right: 1rem;
     width: 13rem;
     margin-top: 2rem;
     color: white;
      font-weight: 550;
  }
  .available-balance{
    margin-left: auto;
    display: flex;
    line-height: 3rem;
  }
  .ViewAgr-Actions .success{
    background-color: #00B6A8;
  }
  .Amount-title .select{
    margin-left: 4rem;
      line-height: 3rem;
      font-size: 1rem;
  }
  .Agreement-details .ViewAgr-Actions{
    padding-top: 1rem;
      margin-top: 1.5rem;
      border-top: 1px solid #CDCACA;
  }
  .Payment-sched{
     border-top: 0.5px solid #CDCACA;
  }
  .Payment-sched .payments-listing .invited{
    top: 0.3rem !important;
    width: 9rem;
    left: 77%;
  } 
  .Payment-sched .InvoicesList .invited{
    top: unset !important;
  }
  .payment-list .image .agreement-status{
      top: -1.3rem;
      width: 1.8em;
      padding-right: 0.2rem;
  }
  .createInvoice{
    margin-top: -4rem;
  }
  .agr-invoices .Payment-sched .back-button{
    position: absolute;
    left: 5rem;
  }
  .Payments-Details img{
    margin-left: unset !important;
    margin-top: unset !important;
  }
  .Receipt .detailsRightSide img{
    margin-top: -1rem !important;
    width: 10%;
  }
  .secured-matensa{
    color: #363636;
    opacity: 50%;
  }
  .confirm-payment .available-balance{
  display:unset;
  line-height: unset;
  margin-top: -3rem;
  }
  .user-dashboard-middle .connections-list.InvoicesList .payment-method img{
    height: 2rem;
  }
  .confirm-payment .agreementpaymentsList ul li{
    margin-right: 2rem;
  display: flex;
  }
  .confirm-payment  ul input[type=radio]{
    width: 17px;
    margin-top: 0.2rem;
      height: 17px;
      position: absolute;
      margin-right: 0.5rem;
  }
  .confirm-payment{
    padding: 1rem 2rem;
    width: 87%;
    margin: auto;
    margin-top: 2rem;
  }
  .Payfrom-account{
    width: 35%;
  }
  .accountbalance-Info{
    display: flex;
    border: 1px solid #cdcaca94;
    border-radius: 10px;
    padding:0rem 0.5rem;
    margin-top: 0.3rem;
    height: 40px;
    position: relative;
  }
  .accountbalance-Info:hover .AllAccounts,.Payfrom-account:hover .AllAccounts{
  visibility: visible;
  opacity: 1;
  }
  .AllAccounts label{
    margin-left: 2rem;
    font-size: 1rem;
  }
  .AllAccounts .list-1{
    margin-right: 1rem;
    margin-left: -2rem;
    margin-bottom: 0.5rem !important;
  }
  .discount-control .form-control{
    border: 1px solid #00008A;
  }
  .AllAccounts ul input[type=radio] {
    margin-top: 1.2rem;
    display: none;
  }
  .AllAccounts ul li:nth-child(1){
   border-top: unset !important;
  }
  .AllAccounts ul li{
    display: flex;
    border-top: 0.5px solid #CDCACA;
  }
  .modal-agr.agr-docs-container{
    text-align: center;
    margin-top: -3rem;
  }
  .confirm-payment .connection-item .fas{
    font-size: 1.3rem;
    left: 1rem;
  }
  .confirm-payment .connection-item .connection-item-right{
    margin-left: unset !important;
  }
  .confirm-payment .cancelPay i{
    font-size: 0.9rem !important;
    bottom: unset !important;
    color: #E72F3E !important;
    padding-top: 0.4rem;
    position: unset !important;
  } 
  .accountbalance-Info .AllAccounts img{
    position: absolute;
    left: -2.2rem;
    top: 0.4rem;
    height: 2.5rem !important;
    width: 2rem;
  }
  .account-verif-main .Agreements-rightpart .connection-item .fas {
   left: 1rem !important;}
.account-verif-main.MyInvoices .Agreements-rightpart .connection-item .fas {
  bottom: unset !important;
}
.account-verif-main .settings-labelstyle3{
  display: none;
}
  .accountbalance-Info  img{
    max-width: 3rem;
    max-height: 3rem;
    height: 100%;
  }
  .AllAccounts label{
    cursor: pointer;
  }
  .Payments-Details .Invoices-Container .detailsRightSide .payment-method img{
    width: 20%;
  }
  .document-block .profile-image-options {
    top: 5rem;
    z-index: 333;
    width: 10rem;
    padding: 1rem 1.5rem;
}
  .AllAccounts{
    position: absolute;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    visibility: hidden;
    opacity: 0;
    width: 100%;
    cursor: pointer !important;
    top: 2.8rem;
    box-shadow: 1px 1px 8px 0px rgb(0 0 0 / 13%);
    right: 0rem;
    z-index: 5;
    background-color: white;
    border-radius: 25px;
    border: 1px solid white;
    -webkit-transition-property: all;
    -webkit-transition-duration: 0.5s;
    -webkit-transition-timing-function: linear;
    -webkit-transition-delay: 0s;
    -ms-transition-property: all;
    -ms-transition-duration: 0.5s;
    -ms-transition-timing-function: linear;
    -ms-transition-delay: 0s;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: linear;
    transition-delay: 0s;
  }
  .Payfrom-account span{
    line-height: 2.5rem;
    font-size: 1.1rem;
      font-weight: 500;
      margin-left: 1rem;
  }
  .Pay-Now{
    max-width: 80%;
    margin: auto;
    padding-left: 2rem;
  }
  .payment-filters ul{
    list-style-type: none;
    padding: unset;
    margin: unset;
    cursor: pointer;
  }
  .payment-filters ul li{
    display: flex;
    line-height: 3;
  }
  .payment-filters ul li img{
    margin-right: 1rem;
    width: 3rem;
    height: 3rem;
  }
  .payment-filters ul li:hover {
    opacity: 50%;
}
.group-filters{
  display: flex;
  position: relative;
}
.group-filters .connections-search{
  width: 90%;
}
.group-filters .sorting-filters:hover .Sort-agreements{
visibility: visible;
opacity: 1;
}
.group-filters .Sort-agreements{
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
  width: 25rem;
  padding:0rem;
  visibility: hidden;
  opacity: 0;
  right: 4%;
  top: 24%;
    background-color: #fff;
    position: absolute;
    z-index: 10;
    border-radius: 40px;
    -webkit-transition-property: all;
    -webkit-transition-duration: 0.5s;
    -webkit-transition-timing-function: linear;
    -webkit-transition-delay: 0s;
    -ms-transition-property: all;
    -ms-transition-duration: 0.5s;
    -ms-transition-timing-function: linear;
    -ms-transition-delay: 0s;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: linear;
    transition-delay: 0s;
}
.download-pdf{
  position: absolute;
  right: 2rem;
}
.payment-date{
  position: absolute;
  top: 0;
  opacity: 0;
}
  .payment-filters{
    position: absolute;
    width: 15rem;
    z-index: 5;
    background-color: white;
    border-radius: 25px;
    visibility: hidden;
    opacity: 0;
    padding: 1rem;
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
    -webkit-transition-property: all;
    -webkit-transition-duration: 0.5s;
    -webkit-transition-timing-function: linear;
    -webkit-transition-delay: 0s;
    -ms-transition-property: all;
    -ms-transition-duration: 0.5s;
    -ms-transition-timing-function: linear;
    -ms-transition-delay: 0s;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: linear;
    transition-delay: 0s;
  }
  .sorting-filters:hover .payment-filters{
    visibility: visible;
    opacity: 1;
  }
  .pay-now-filters .btn-style-5 span{
    display: unset !important; 
    font-weight: 500;
    padding: 0rem 1rem;
    color: black;
  }
  .Pay-Now .headlines{
    top: 10rem !important;
        right: 6rem !important;
  }
  .import-log{
    right: 7rem !important;
  }
  .pay-now-filters .btn-style-5{
    border-radius: 50px !important;
    min-width: 15.25rem;
  }
  .pay-now-filters{
    display: flex;
    margin-top: 2.5rem;
  }
  .Pay-Now .pay-now-back{
    position: absolute;
    left: 6rem;
    top: 8.7rem;
  }
  .Payfrom-account i {
    margin-left: auto;
    margin-right: 0.5rem;
    line-height: 3rem;
    color: #DBDBDB;
    font-size: 1.2rem;
  }
  .confirm-payment .cancelPay{
    position: absolute;
    right: -0.5rem;
    top: -0.5rem;
    z-index: 2;
    width: 23px;
    height: 23px;
    line-height: 1.2rem;
    text-align: center;
    color: #E72F3E;
    border: 1px solid #E72F3E;
    border-radius: 50px;
    background-color: white;
  }
  .confirm-payment span.invited,.confirm-payment span.invoice-date{
    left: 70% !important;
  }
  .confirm-payment .headingstyle1{
    position: relative;
  }
  
  .connections-container .total-matensa{
    position: absolute;
    left: 23rem;
  }
  .connections-container .connections-middle{
    position: relative;
  }
  .confirm-payment .headlines{
    width: unset !important;
    padding: 1rem;
    top: -4rem !important;
  }
  .confirm-payment .agreementpaymentsList ul{
    list-style-type: none;
      display: flex;
      margin-top: 0.7rem !important;
      padding-left: 0rem;
  }
  .Payment-sched.connections-middle .connection-item .payment-method, .Payment-sched.connections-middle .connection-item .image  .fas{
    display: none !important;
  }
  .Payment-sched.connections-middle .confirm-payment .connection-item .image  .fas{
    display: block !important;
  }
  .confirm-payment .btn-style-1{
    width: unset !important;
  }
  .confirm-payment .available-balance .headingstyle1{
    font-size: 1.5rem;
  }
  .View-agreement-container .dashboard-container{
    width: unset !important;
  }
  .headlines .headingstyle2{
    font-size: 1rem;
    font-weight: 400;
  }
  .payment-list .Payment-sched .invited{
    left: 55% !important;
  }
  .agr-invoices .sorting-dropdown .Sort-agreements {
    right: -1rem;
    top: 2.7rem;
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
    border: unset;
    border-radius: 40px;
    visibility: hidden;
    opacity: 0;
  }
  .agr-invoices .sorting-dropdown:hover .Sort-agreements {
    visibility: visible;
    opacity: 1;
  }
  .agr-invoices  .InvoicesList .connection-item .fas{
    left: 1.8rem;
  }
  .agr-invoices .Agreements-rightpart .image .agreement-status {
    top: -1.1rem;
    left: -1.3rem;
  }
  .InvoiceDetails img{
    margin-top: -2rem;
  }
  .failure-correction-form{
    max-height: 40rem;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .agr-docs-container .documents-list{
  grid-gap: 10px !important;
  padding-bottom: 3rem !important;
  }
  .expired{
    background-color: #FAD2E1 !important;
    border-top: 0.5px solid #eaadc3 !important;
  } 
  .agr-documents .agreements-docs-button{
    color: #00008A;
    font-size: 1.1rem;
  }
  .agr-documents .agreements-docs-button i{
    font-size: 1.3rem;
  }
  .not-uploaded{
    background-color: #EFEFEF !important;
  }
  .NoDocs{
    padding: 0.5rem;
    color:#CDCACA;
  }
  .new-documents{
    margin-top: 2rem;
  }
  .agr-docs-container .have-agr{
    margin-top: 2rem;
  }
  .agr-docs-container .btn-style-1,.activate-agr .btn-style-1{
    margin-top: 1rem !important;
    background-color: #00B6A8;
  }
  .added-input{
    position: relative;
    margin:0;
  }
  .added-input .action-link {
    position: absolute;
    top: 6px;
    border-radius: 5px;
    right: 10px;
    z-index: 2;
    font-size: unset;
    height: 26px;
    width: 26px;
    cursor: pointer;
    line-height: 2.1rem;
    transform: translateX(2px);
  }
   .agreement-amounts .profile-verified-notice{
     text-align: center;
     width: 71px;
     height: 71px;
     padding:1rem;
     margin-top: 0.2rem;
   }
  .agreement-info .connection-item{
    display: flex;
    margin-bottom: 1rem;
    max-width: 45% !important;
  }
  .agreement-info .connection-item-right {
    display: flex;
}
  .agreement-info .connection-item .item-body{
    width: 100%;
  }
  .InvoicesList .View-agr-options{
    left:100% !important;
    position: absolute;
  }
  .InvoiceDetails{
    padding:2rem 2.5rem;
  }
  .payment-icon{
    width: 100%;
    height: 100%;
  }
  .payment-icon img{
    width: 60%;
    height: 100%;
    padding: 0.2rem;
    opacity: 0.5;
  }
  .InvoicesList .invoice-date{
    left: 70% !important;
  }
  .MyInvoices .InvoicesList .invited{
    left: 70% !important;
  }
  .paymentChosen img{
    opacity: 1;
  }
  .agr-invoices .invoices-body .sorting-dropdown{
    position: relative;
  }
  .modal-agr.change-method .btn-style-1{
    min-width: 20rem !important;
  }
  .payments-listing  .connection-item .fas{
    font-size: 1.5rem;
    left: 1rem ;
    bottom: -1.8rem;
  }
  .payments-listing span.invited, .payments-listing .invoice-date{
    left: unset !important;
    display: flex;
    position: unset !important;
  }
  .Payment-sched .payments-listing .invoice-date{
    top: 2rem !important;
  }
  .invoices-body .settings-labelstyle3{
    right: 3rem !important;
    top: 17rem;
  }
  .Payment-sched .InvoicesList .invoice-date {
    top: 3.3rem
  }
  .Inner-Invoice-item .connection-phone{
    display: none;
  }
  .Inner-Invoice-item .connection-info{
    margin-bottom: 2rem;
  }
  .agr-invoices .InvoicesList .Inner-Invoice-item .fas {
   display: none !important;
}
.Agreements-rightpart .Inner-Invoice-item .image {
  position: revert !important;
}
.Inner-Payment-item .connection-name{
  display: none;
}
.Inner-Payment-item .connection-phone{
  position: absolute;
  top: 2.5rem;
}
.Payment-sched .payments-listing .Inner-Payment-item .invited {
 top: unset !important;
  width: unset !important;
  left: unset !important;
  margin-left: 1rem;
  color: unset !important;
}
.payments-listing .Inner-Payment-item{
  padding: 1.25rem 1.25rem 3rem 1.25rem !important;
}
.Inner-Payment-item .connection-date{
  position: absolute;
    top: 1.5rem;
}
.send-money-container .action-link.primary{
  display: none;
}
.connection-date{
  font-size: 0.8375rem;
  color: #CDCACA;
}
.Payment-sched .payments-listing .Inner-Payment-item .invoice-date {
 left:1rem !important;

}
  .Payment-sched .InvoicesList .Inner-Invoice-item .invoice-date {
    bottom: 0.5rem;
    left: 5.4rem !important;
    top: unset !important;
}
  .Agreements-rightpart .Inner-Invoice-item .invited{
    bottom: 1.8rem;
    left: 5.4rem !important;
    top: unset !important;
  }
  .Payment-sched  .payments-listing  .connection-item-right{
    margin-left: 1rem !important;
  }
  .payments-listing  .connection-item-right{
    margin-left: 3rem !important;
  } 
  .Currencies{
    position: absolute;
    display: grid;
    border: 1px solid #1C008A;
    width: 90%;
    color: #1C008A;
    left: 0.4rem;
    border-radius: 8px;
    z-index: 2;
    background-color: white !important;
  }
  .Currencies{
    position: absolute;
    display: grid;
    border: 1px solid #1C008A;
    width: 90%;
    color: #1C008A;
    left: 0.4rem;
    border-radius: 8px;
    z-index: 2;
    background-color: white !important;
  }
  .Currencies span{
    padding-left: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .InvoicesList .View-agr-options .far{
    font-weight: 550 !important;
  }
  .InvoicesList .sorting-dropdown{
    position: relative;
  }
  .MyInvoices .InvoicesList .image .agreement-status{
  bottom:-4.9rem !important;
  top: -1.5em !important;
  }
  .notContract{
    max-width: unset;
    margin-top: -2rem;
    flex:unset;
  }
  .MyInvoices .settings-bottom-buttons .btn-style-5{
    display: unset;
    height:unset !important;
    min-width: 15rem;
    margin-left: 2rem;
  }
  .other-documents .document-block {
    padding-top: 0.5 !important;
    padding: 0.5rem 1rem;
    text-align: unset !important;
    border-radius: 20px;
    width: 100% !important;
    height: unset !important;
    display: flex;
    z-index: unset !important;
    border: unset !important;
  }
  .Step-done{
    display: unset !important;
  }
  .other-documents  a{
    height: 1.7rem !important;
    width: 1.7rem !important;
    margin-left: auto;
    right: 15px !important;
    z-index: 3;
    top: 5px !important;
    line-height: 1.6 !important;
    border-radius: 50% !important;
  }
  .gray-notice{
    padding-left: 0.7rem;
    padding-right: 0.4rem;
    font-size: 0.875rem;
    padding: 1.375rem 1.25rem;
    background-color: white;
    border-radius: 15px;
}
  .profile-verified-notice ul.tabs-style-2 li a:hover, ul.tabs-style-2 li a.active {
    background-color: #FFA72E !important;
    color: #363636 !important;
  }
  .agreements-docs-button .btn-style-4{
    line-height: 3.1rem;
    min-width: 10rem;
    height:3.1rem;
  }
  .table-responsive{
    overflow-x: unset;
  }
  .red-dot{
    background-color: #E74E5A;
    border-radius: 20px;
    height: 25px;
    width: 25px;
    position: absolute;
    text-align: center;
    line-height: 2rem;
    right: 0;
    bottom: 1.5rem;
    margin-left: 1rem;
  }
  .agr-documents .multiple-docs{
    top: 5rem !important;
  }
  .agr-documents .documents-list i{
    top: -2rem !important;
  }
  .Multiple{
    box-shadow: 0px -4px 3px rgb(50 50 50 / 14%);
  }
  .connections-mainInfo .connection-info-container{
    margin: 2rem 0rem !important;
    display: unset !important;
  }
  .Connection-profileInfo ul.menu-style-1 li a {
    border-bottom: 0.5px solid #CDCACA;
  }
  .Connection-profileInfo ul.menu-style-1 li.active a i{
display: none;
  }
  .info-block{
    margin-bottom: 2rem;
    border-bottom: 1px solid #70707021;
  }
  .connections-mainInfo .connection-info-container .label-style-1{
    font-weight: 500;
    text-align: left;
  }
  .document-block input[type="checkbox"] {
    display: none;
    margin-top: -1rem;
    left: 0.5rem;
    position: absolute;
  }
  .agreements-docs-button {
    display: flex;
  }
  .documents-row1{
    display: flex;
  }
  .user-auth-form .back-button{
    position: absolute;
    left: 0rem ;
  }
  .documents-row1 ul{
    margin-right: auto;
    width: 30rem !important;
  }
  .agreement-amounts .btn-style-5, .agreements-docs-button .btn-style-5{
    line-height: 2.6rem;
    min-width: unset;
    width:36px;
    display: unset;
    font-size: 2rem;
    border-radius: 50%;
    margin: auto;
  }
  .agreement-amounts input.mat-input-element {
    border:1px solid #CDCACA;
    border-radius:15px;
    padding: 0.7rem;
    font-size: 1rem;
  }
  .agr-summary{
    border: 1px solid #DBDBDB;
    border-radius: 25px;
    background-color: white;
    padding: 1rem 2rem;
    margin-bottom: 1.5rem;
  }
  .row1-summary{
    display: flex;
    margin-bottom: 1rem;
  }
  .agr-summary table{
  border-collapse: separate;
  border-spacing: 1.5rem;
  }
  .agr-summary table th{
    vertical-align: top;
  }
  .row1-summary .action-link.primary{
    border-color:black ;
  }
  .modal-agr .agreement-actions{
    margin-top: 2rem !important;
    width: 23rem;
    margin: auto;
  }
  .istemplate{
    color: #CDCACA;
  }
  .View-agreement-container .createInvoice{
    margin-top: unset !important;
  }
  .modal-agr {
    margin-bottom: 2rem;
  }
  .modal-agr .btn-style-5,.modal-agr .btn-style-1{
    min-width: 10.25rem !important;
    margin-left:1rem;
    margin-top: 0.5rem;
  }
  .agreement-terms{
    position: relative;
  }
  .headlines{
    margin-left: auto;
    width: 20%;
    padding: 0.7rem;
    right: 0;
    top: 0rem;
    position: absolute;
    text-align: center;
    border-radius: 15px;
    font-weight: 600;
    font-size: 1.2rem;
    background-color: #1c008a1c;
}
ul.menu-style-2.SingleValue{
    width: 20% !important;
  }
  ul.menu-style-2.SingleValue li{
    width: 100% !important;
  }
  .terms-types{
    margin-top: 3rem !important;
    display: flex;
    margin-bottom: 2.5rem;
    padding-bottom: 2rem;
    margin: auto;
    width: 30%;
  }
  .terms-types label{
    border-radius: 18px;
    background-color: #EFEFEF;
    text-align: center;
    padding: 1rem;
    padding-top: 2rem;
    margin-right: 2.5rem;
    width: 121px;
    height: 121px;
  }
  .agreement-payment-container .profile-verified-notice{
      margin-top: unset;
      padding: 2rem 1.5rem;
      position: relative;
      padding-bottom: 0rem;
      margin-bottom: 2rem;
      background-color: unset;
      margin-left: 0.2rem;
      box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  }
  .agreement-payment-container .form-control[readonly] {
  background-color: white;
  }
  .vat-row-payments{
    margin-bottom: 2rem;
  }
  .agreement-payments-actions{
    margin-bottom: 1rem;
    display: inline-flex;
  }
  .terms-types label.active{
    background-color:#ffa72e57;
    border: 2px solid #FFA72E;
  }
  .agreement-payments-actions i{
    line-height: 1.8rem;
      margin-left: 0.5rem;
      margin-top: 4rem;
      font-size: 4rem;
  }
  .carousel{
    height: 100% !important;
  }
  .dashboard-balance .carousel-slide-overlay{
    background-color: unset !important;
  }
  .active-text{
    color: #363636 !important;
   font-weight: bold;
  }
  .agreement-value-container .connections-list{
    grid-gap: unset !important;
    padding-top: 3rem;
    border-top: 0.5px solid #D2D2D2;
  }
  .agreement-payment-container ul.tabs-style-2 li{
    margin:0.5rem;
  }
  .agreement-payment-container .profile-verified-notice a {
  text-decoration: unset !important;
  color: unset !important;
  }
  .agreement-payment-container{
      margin-right: 2rem;
      width: 30%;
      display: inline-block;
  }
  .agreement-payment-container .action-link.danger{
    background-color: unset;
      margin-top: -2rem;
      height: 23px;
      width: 23px;
      font-size: 1rem;
      line-height: 1.5rem;
  }
  .documents-info{
    float: right;
  }
  .documents-info-dot{
    width: 16px;
    height: 16px;
    margin-right: 0.5rem;
    border-radius: 50%;
  }
  .documents-info ul li{
    display: flex;
  }
  .documents-info ul{
    list-style-type: none;
    display: flex;
    grid-gap: 2rem;
    margin-bottom: 0;
    margin-top: 1rem;
  }
  .agreement-payment-container .form-group .form-control {
    background-color: white;
  }
  .remove-payment{
    position: absolute;
    right: 2rem;
    top: 1.3rem;
  }
  .agreement-payment-container .mat-input-element:disabled {
    color:unset;
  }
  .bankTransfer{
    opacity: 0.5;
  }
  .agreement-payment-container .form-control:disabled{
    background-color: white;
    border:1px solid #1C008A;
  }
  .agreement-payment-container .form-group label b {
    font-weight: bolder !important;
  }
  .types-title{
    margin-right: 2rem;
    margin-top: 0.5rem;
    text-align: center;
    color: #8C8C8C;
  }
  .Payment-sched .Agreements-rightpart{
    border-left: unset;
  }
  /* NEW DOCUMENTS UPDATE */
  .filled img{
    filter: grayscale(1) invert(1) brightness(100);
  }
  .cycleChosen img{
    filter: grayscale(1) invert(1) brightness(100);
  }
  .myCustomModalClass .wizard-dropzone .form-group .dropzone {
    height: 10rem;
    padding:1rem;
  }
  /* VIEW AGREEMENT */
  .view-agr .search-input input[type="text"]{
    border-radius: 50px;
    border: 1px solid #DBDBDB;
    text-indent: 0.3rem;
  }
  .settings-permissions  .fal{
    transform: scaleX(-1);
  }
  .connection-labelstyle1 i{
    position: relative;
  }
  .connection-labelstyle1 i:hover .profile-image-options {
    display: block;
    width: 26rem;
    padding: 2rem !important;
    box-shadow: 1px 1px 8px 0px rgb(0 0 0 / 13%);
    left: -2rem;
    border: none;
    border-radius: 40px;
  }
  .termsConditions{
    overflow-y: scroll;
    height: 40rem;
  }
  .connection-labelstyle1 .profile-image-options .role {
    padding: 0.5rem 0rem;
    font-family: 'Poppins', sans-serif !important;
    margin-bottom: 0.5rem;
  }
  .View-agreement-container .agr-docs-container{
    margin-top: 5rem;
  }
  .agr-docs-container .documents-list:nth-child(2) {
   border-bottom: unset !important;
}
  app-verify-account .myCustomModalClass .modal-dialog{
    width: unset !important;
  }
  .role:nth-of-type(1),.role:nth-of-type(2) {
    border-bottom: 1px solid #cdcaca7d;
    margin-bottom: 1rem;
  }
  .connection-labelstyle1 .role span{
    font-size: 1.2rem;
    color: #1C008A;
    font-weight: 550;
  }
  .role div{
    margin-top: 1rem;
    color: #363636;
    font-weight: 400;
    opacity: 80%;
    line-height: 1.4;
    padding-bottom: 0.5rem;
  }
  .privacyAndSecurity .settings-permissions,.Email-notifications .settings-permissions{
    padding: 0.5rem 0rem;
  }
  .Amount-title .col-3{
    max-width: unset;
    border-radius: 15px;
    max-height: 3rem;
  }
  .profile-verified-notice .Amount-title .col-3 a{
    text-decoration: unset !important;
    color:#CDCACA !important;
  }
  .View-agreement-container .dashboard-middle{
    padding:2.2rem;
    margin-left: -0.1rem;
    border-top-left-radius: unset;
    z-index: 1;
  }
  .View-agreement-container .dashboard-middle ,.View-agreement-container .dashboard-settings-left{
    box-shadow: unset;
  }
  .View-agreement-container .dashboard-settings-left{
    border-top-right-radius: unset;
    padding: 1.3rem 1.5rem;
  }
  .View-agreement-container .dashboard-left ul{
    width: 130%;
    margin-left: -3rem;
  }
  .View-agreement-container .dashboard-left ul.menu-style-1 li a{
    padding: 1.1rem 3rem !important; 
  }
  .View-agreement-container .dashboard-settings-left ul li.active a {
  color:white !important;
  }
  .View-agreement-container .dashboard-settings-left ul li {
  margin-bottom: unset !important;
  }
  .View-agreement-container a.dashboard-settings-list {
  font-size: unset !important;
  }
  .settings-list{
    margin-left: -3rem;
    width: 129%;
    margin-top: 2.5rem;
  }
  .View-agreement-container .settings-bottom-buttons .btn-style-5{
    width:35%;
    margin-top: 4rem;
  }
  .View-agreement-container .dashboard-middle .btn-style-1{
    width:35%;
    color: white;
  }
 .choose-account-container{
  margin-left: 8rem !important;
  margin-top: -1.5rem;
  margin-right: 7rem;
 }
 .modal-body .connection-item{
  margin: 0.5rem;
  flex: 49% !important;
 }
 .send-money-container .modal-agr .agreement-actions{
width: 40% !important;
 }
 .send-money-container .agreement-info2-list:first-child{
  display: unset !important;
 }
 .send-money-container .agreement-info2-list .menu-style-2{
  width: unset !important;
  margin-top: 1rem;
 }
 .send-money-container .searchforInvoice{
  padding: unset !important;
  width: unset !important;
  margin-top: unset !important;
 }
  .View-agreement-container .col-md-6 .form-group .btn-style-1{
    margin-top:unset;
  }
  .document-block .doc-icon{
    opacity: 0.3;
  }
  .documentfilled .doc-icon {
    opacity: 1 !important;
  }
  .settings-info-container .settings-labelstyle1,.change-mobile-number .settings-labelstyle1,.edit-security-questions .settings-labelstyle1{
    margin-bottom: 1rem;
  }
  .Email-notifications .settings-notification,.password-modal-container form{
    margin-top: 1rem;
  }
  .View-agreement-container .settings-bottom-buttons .btn-style-5{
    line-height: unset;
    display: unset;
    height: unset !important;
  }
  .All-selected{
    display: flex;
  }
  .access-denied{
    font-size: 2rem;
    font-weight: 500;
    margin: auto;
    margin-top: 2rem;
    text-align: center;
    width: 50%;
  }
  .reset-pass-labels .form-group label{
text-transform: unset !important;
  }
  div.wizard-dropzone .bottom-actions{
    position: unset !important;
    margin-top: 3rem;
    width: unset !important;
    display: flex;
  }
  .not-found-page{
    width: 85%;
    margin: auto;
    margin-top: 2rem;
  }
  .auth-req{
    font-size: 3rem;
  }
  div.wizard-dropzone .bottom-actions .btn-style-1,div.wizard-dropzone .bottom-actions .btn-style-2{
    min-width: 12rem !important;
  }
  .dashboard-left .list-1.list-types{
    width:85% ;
    border-bottom: unset !important;
  }
  /* INVOICES */
  .payment-list  .invited {
    left: 80% !important;
  }
  .NoFees{
  height: 57px !important;
  }
   .Agreements-rightpart .payments-list .connection-item .fas{
    display: none;
  }
  .Agreements-rightpart .payments-list .connection-item .connection-photo{
    display: none;
  }
  .MyInvoices .connection-name{
      font-weight: 500;
  }
  .import-sorting{
   display: flex;
   position: relative;
   margin-top: 2rem;
  }
  .import-sorting .btn-style-5 span{
   display: unset !important;
  }
  .import-sorting .sorting-dropdown .Sort-agreements {
    right: 1% !important;
  }
  .reason{
    font-size: 0.9rem;
    color: #1C008A;
    text-decoration: underline;
  }
  .import-sorting .btn-style-5{
    min-width: 11rem !important;
    line-height: 2;
  }
  .imports-listing .connection-actions {
    font-size: 1.3rem;
}
.reason-descp{
  font-size: 0.9rem;
  margin-top: 0.5rem;
}
.imports-listing .connection-phone{
  color: #A3A3A3 !important;
  font-weight: unset !important;
}
.Email-notifications .you-personalize,.settings-info-container .you-personalize {
  font-size:12px;
}
.imports-listing .connection-date{
  color: #DBDBDB;
  font-size: 0.9rem;
}
.cancel-payment{
  background-color: white !important;
  color: #363636!important;
  border: 1px solid #363636!important;
}
.imports-listing .connection-info{
  max-width: 100%;
}
.Payment-sched .imports-listing .connection-item .connection-actions,.Payment-sched.connections-middle .imports-listing .connection-item .fas{
  display: unset !important;
  position: absolute;
  right: 0;
}
.imports-listing .red-dot{
  color: white;
  height: 16px;
  background-color: #C70010;
  top: 2rem;
  left: 1rem;
  bottom: unset !important;
  width: 16px;
  line-height: 1.1rem !important;
}
  .import-results-main{
    width: 90% !important;
    margin: auto;
  }
  .import-sorting .Sort-agreements{
    visibility: hidden;
    box-shadow: 1px 1px 8px 0px rgb(0 0 0 / 13%);
    opacity: 0;
    border-radius: 40px;
    border: unset !important;
    -webkit-transition-property: all;
    -webkit-transition-duration: 0.5s;
    -webkit-transition-timing-function: linear;
    -webkit-transition-delay: 0s;
    -ms-transition-property: all;
    -ms-transition-duration: 0.5s;
    -ms-transition-timing-function: linear;
    -ms-transition-delay: 0s;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: linear;
    transition-delay: 0s;
  }
  ngx-spinner .overlay{
    background: rgba(224, 224, 224, 0.4) !important;
    backdrop-filter: blur(5px);
   
  }
 .nav-header #ddcurrency .dropdown-menu{
    inset: unset  !important;
    left: -6rem !important;
    transform: translateY(40px) !important;
    }
  .MyInvoices .btn-style-10{
      width: 70px;
      min-height: 28px;
      max-height: 28px;
      margin-left: auto;
      margin-top: 3%;
      right: 9%;
      font-size: 0.75rem;
  }
  .Agreement-details table .headingstyle1 {
    display: unset !important;
  }
  .invoices-actions .receiptList{
    width: unset !important;
    display: inline-grid !important;
  }
  .MyInvoices .invited{
    top: 0.2rem;
    color:unset !important;
  }
  .invoice-date{
    position: absolute;
    top: 1.7rem;
      left: 77% !important;
      color: #CDCACA;
      font-size: 0.9em;
  }
  .MyInvoices .image .agreement-status{
    top: -0.8em !important;
     width: 1.8em;
     padding-right: 0.2rem;
      bottom: -6.4rem;
      height: 7rem !important;
  }
  .view-customers .SingleItemAgreement{
    width: 70rem; 
  }
  .contactus-page .form-group .mat-form-field .mat-select .mat-select-arrow{
    top: unset !important;
  }
  .NoPaymentIcon{
    bottom: -2.5rem !important;
    left: 1rem !important;
  }
  .loginform .form-group input.form-control{
    direction: ltr;
  }
  .user-dashboard-middle .headlines{
    display: none;
  }
  .video-modal-popup .modal-dialog{
    width: 58.125rem;
  } 
  .tutorial-video{
    width: 100%;
  }
  .user-auth-right .SeeAll-links{
    font-size: unset !important;
    float: unset !important;
  }
  .InvoiceDetails{
    display: unset;
    width:100%;
  }
  .Invoice-details-text1{
    font-size: 1.3rem !important;
    font-weight: 550;
    color: #1C008A;
    opacity: 0.9;
  }
  .Info-rows td{
    padding: 0.5rem 1rem !important;
  }
  .Receipt .Invoice-details-text1{
    font-size: 1.2rem !important;
  }
  .main-title{
    font-size: 1.4rem !important;
    margin-bottom: -0.3rem;
  }
  .document-block input[type="checkbox"]:checked:before{
    width: 1.1rem;
    height: 1.2rem;
    content: '✔';
    position: absolute;
    z-index: 1;
    background-color: #fff;
    border-radius: 2px;
    color: #1C008A;
    padding-left: 0.2rem;
  }
  .ChooseAccounts .connection-photo{
    width: 5rem;
    height: 5rem;
    float:unset;
    margin:auto;
  }
  .searchparty .form-group{
  margin-top: 1.5rem;
  }
  .searchparty .btn-style-1{
    width: 100% !important;
  }
  .agreement-summary .ChooseAccounts .col{
    flex-grow: unset !important;
    text-align: center;
  }
  .ChooseAccounts .row{
    padding-bottom: 4rem;
  }
  .sorting-filters .Sort-agreements{
top: 3rem !important;
  }
  .create-buttons .btn-style-2{
    margin-bottom: 1.5rem;
  }
  .templates-drafts .back-button{
    margin-top: -0.5rem;
    margin-right: -1rem;
  }
  .templates-drafts .Agreements-rightpart .connections-search{
    margin-top: -1rem;
  }
  .ChooseAccounts{
    margin-top: 2rem;
  }
  .ListAgreements .agr-invoices .invoices-body .heading-3:first-of-type{
    display: none;
  }
    .agr-invoices.fromTab .invoices-body .heading-3:nth-of-type(2)   {
    display: none;
      }
      .View-agreement-container .invoices-body .heading-3:first-of-type   {
        display: none;
          }
  .no-documents-found img{
    margin-top: 1rem;
  }
  .account-name{
    margin-top: 1rem;
  }
  .agreement-summary .active {
    opacity:0.5 ;
    cursor: pointer;
  }
  app-address-form{
    width: 100%;
  }
  .Fees{
    z-index: 55;
    top: 7.5rem;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    width: 23rem;
    background-color: #363636;
    color: white;
    -webkit-transition-property: all;
    -webkit-transition-duration: 0.5s;
    -webkit-transition-timing-function: linear;
    -webkit-transition-delay: 0s;
    -ms-transition-property: all;
    -ms-transition-duration: 0.5s;
    -ms-transition-timing-function: linear;
    -ms-transition-delay: 0s;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: linear;
    transition-delay: 0s;
  }
  .agr-docs-container .document-block img {
    max-height: 4rem;
}
  .Indiv-Subscription:hover .Fees{
   visibility: visible;
   opacity: 1;
  }
  .Fees:before {
    content: "";
    display: block;
    position: absolute;
    right: 45%;
    top: -15px;
    border-bottom: 15px solid #363636;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
}
  .fixed-fee{
    position: absolute;
    left: 70%;
    width: 50%;
    top: 0;
  }
  .HasTemplate{
    margin-top: -3rem !important;
  }
  .import-file .btn-style-5{
    position: absolute;
    right: 3rem;
    min-width: 10.5rem;
    border-radius: 50px;
    top: 1rem;
  }
  .View-agreement-container .dashboard-left .upper-title-1 {
    font-size: 1.2rem;
}
  .import-file .btn-style-5 span {
    display: unset !important;
  }
  .import-file{
    position: relative;
  }
  .subs-fees{
    position: relative;
  }
  .upload-amounts-table td,.upload-amounts-table th{
    border: 1px solid #dbdbdb8f;
  }
  .upload-amounts-table{
    position: relative;
  }
  .view-customers .sorting-dropdown i{
    font-size: 1.2rem;
    margin-top: unset !important;
  }
  .upload-amounts-table i{
    position: absolute;
    right: 1rem;
    top: 1rem;
    font-size: 1.3rem;
  }
  .actions .connection-actions-options a:hover{
    background-color: unset !important;
  }
  .actions a span{
    padding: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .upload-amounts-table .actions:hover .connection-actions-options{
    opacity: 1;
    visibility: visible;
  }
  .upload-amounts-table .connection-actions-options{
    right: 0;
    top: 2rem;
  }
  table  th:last-child {
    border-top-right-radius: 8px;
    border-top: unset;
    border-right: unset;
}
.upload-amounts-table .form-control{
  border-radius: 0.55rem;
}
table thead tr:first-child th:first-child {
  border-top-left-radius: 8px;
  border-top: unset;
  border-left: unset;
}
.payment-method-types{
  display: flex;
}
  .table .thead-light th {
    background-color: #EFEFEF;
    border-bottom: unset !important;
    border-top: unset !important;
  }
  .ChooseAccounts .AccountsTitle{
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .actions .connection-actions-options a{
    font-size: unset;
  }
  .Payments-Actions app-create-invoice, .Payments-Actions app-invoice-details{
    width: 100%;
  }
  .agr-invoices .Payments-Actions .back-button{
    position: absolute;
    left: 6rem;
  }
  .Receipts-part{
    margin-left: auto;
    width: 89%;
  }
    .InvoiceDetails .Payments-Details.Agreement-details table {
      border: unset !important;
      padding: 0rem 6rem !important;
    }
  .modaltitle{
    color: red;
    font-weight: 550;
    font-size: 1.3rem;
    margin-top: -2rem;
    margin-bottom: 2rem;
    padding-left: 0.8rem;
  }
  .payment-list .noPayments{
    transform: unset !important;
    top:unset;
  }
  .acceptButton{
    background-color: #00B6A8 !important;
    background-image: unset;
  }
  .paid{
    color:#00B6A8;
  }
  .agr-invoices .noInvoices{
    width:70rem;
  }
  .view-agr-left .connection-photo{
    width: 7.125rem;
    height: 7.125rem;
  }
  .parties{
    position: relative;
  }
  .info-block .choose-doc-title .document-title {
   position: unset !important;
}
  .parties-photos,.new-summary{
    display: flex;
  }
  .party-name{
    width: 70%;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    text-align: center;
    font-size: 0.8rem;
    top: 7.5rem;
    color: #363636;
    opacity: 50%;
  }
  .view-agr-left{
    width: 30%;
    padding-right: 1rem;
  }
    .payment-details-link .AmountsTable .upper-title-1,
    .payment-details-link .Invoice-details-text1 {
      color: #000;
    }
    .new-summary .profile-job{
      color: #363636;
      opacity: 50%;
      font-size: 14px;
    }
  .view-agr-right{
    border-left: 1px solid #e1e1e1;
    width: 70%;
    padding-left: 2rem;
  }
  .info-block .paymentChosen .paymentmethod-chosen {
    font-size: 0.7rem;
    top: 2.3rem !important;
  }
  .info-block .payment-icon img{
    margin-left: unset !important;
    margin-top: unset !important;
    height: 80%;
  }
  .view-agr-right .label-style-1{
    padding-left: 1rem;
    padding-top: 0.6rem;
    font-weight: unset !important;
  }
  .parties:nth-of-type(1){
    margin-right: -1rem;
  }
  .AmountsTable .upper-title-1{
    color:#1C008A;
    font-weight: 550 !important;
    font-size: 0.9rem;
    cursor: pointer;
  }
  .SendMoneyTo .my-contacts-List{
    overflow-y: auto;
    max-height: 20rem;
    grid-gap: unset !important;
  }
  .MyInvoices .Agreements-rightpart{
    border-left:unset;
  }
  .searchforInvoice .bottom-actions {
    display: none !important;
  }
  .my-contacts-List .connection-item {
    height: unset !important;
    padding-bottom: 0;
  }
  .detailsRightSide{
    text-align:right;
  }
  .AmountsTableRows td.col-md-4{
    border-bottom: 1px solid #c6c3c35c;
    border-top: 1px solid #c6c3c35c;
    padding-top:0.5rem;
  }
  .responsive-table .connection-phone{
    color: #363636;
    opacity: 75%;
  }
  .Invoice-table .AmountsTableRows td.col-md-4 {
  border-top: unset;
  }
  .Invoice-table .upper-title-1{
    font-size: unset !important;
    font-weight: 550 !important;
  }
  .invoice-pay{
    bottom: 5% !important;
  }
  .AmountsTable td:nth-child(4),.AmountsTable td:nth-child(1){
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .InvoiceDetails .btn-style-1{
    min-width: 13rem;
  }
  .InvoiceFooter{
    position: absolute;
    width: unset !important;
    bottom: -2rem;
    left: 9rem;
  } 
  .Invoices-Container .InvoiceDetails table{
    padding: 2rem 3rem;
    margin: auto;
    width: 91%;
    border-spacing: 0em 1em;
      border-collapse: separate;
      border-radius: 50px;
      border: 1px solid #DBDBDB;
  }
  .createInvoice a i.fa-arrow-circle-left{
    position: absolute;
    left: 5rem;
    font-size: 3rem;
    top: 13rem;
  }
  .DetailsShown{
    padding-left: 3.5rem !important;
    padding-top: 0 !important;
  }
  .roll-back button{
    min-width: 25rem !important;
  }
  .Transactions button{
    min-width: 13rem;
    border-radius: 50px;
    line-height: unset;
  }
  .Payments-Details .AmountsTableRows td.col-md-4{
    border-top: unset !important;
    border-bottom: 0.5px solid #c6c3c37a !important;
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .save-gallery{
    width: 70%;
    font-size: 1rem;
    color: #3315a8;
    cursor: pointer;
  }
  .confirm-payment .connection-item {
    padding-bottom: 0rem !important;
  }
  .status-completed{
    color: #197E77;
    text-transform: uppercase;
  }
  .save-gallery i{
    font-size: 1.4rem;
  }
  .Transactions .heading-3{
    font-size: 1.2rem !important;
  }
  .Transactions table td{
    font-size: 0.9rem;
    font-weight: 500;
  }
  .Agreements-rightpart .Transactions  .connection-item .fas {
    bottom: 0!important;
    left: 1rem !important;
}
.Transactions  .InvoicesList .invited, .Transactions .InvoicesList .invoice-date {
  left: unset !important;
  display: flex ;
  position: unset !important;
}
.MyInvoices .Transactions .connections-list.InvoicesList .connection-item-right {
  margin-left: 3rem !important;
}
.Transactions .connections-list.InvoicesList .connection-photo{
  display: none;
}
  .Transactions .AmountsTableRows td{
    border-bottom: 0.5px solid #c6c3c37a !important;
  }
  app-payment-details{
    width: 100% !important;
  }
  .invoice-details-info{
    margin: auto;
    width: 85%;
    display: flex;
    margin-top: -1.5rem;
  }
  .notification-block .ViewAgr-Actions .action-link {
  width:8rem !important;
  padding: unset !important;
  }
  .notification-block .ViewAgr-Actions{
    display: flex;
  }
  .AmountsTable  td.col-md-4{
    padding-bottom: 0.5rem;
    padding-top: 0.8rem;
  }
  .Invoice-Overdue{
    display: block !important;
  }
  .IfOverdue{
    color:#E74E5A !important;
  }
  .OverDue-Svg{
    position: absolute;
    display: none;
    opacity: 0.2;
    left: 20%;
    top: 30%;
    transform: rotate(5deg);
  }
  .transaction-id{
    text-align: center;
    margin-bottom: 1.5rem;
  }
  .Exc-Mark{
    position: absolute;
    left: 1.5rem;
    border-radius: 50%;
    width: 1.2rem;
    top: -0.5rem;
    height: 1.2rem;
    background-color: red;
    border: 1px solid red;
  }
    .notifications-content {
      max-height:59vh;
      display: flex;
      height: max-content;
      flex-direction: column;
      overflow-y: auto;
      width: 100%;
      overflow-x: hidden;
      margin-bottom: 1.5rem;
    }
  .showActions a.success  .Exc-Mark .fas{
    color: white !important;
    font-size: 0.7rem !important;
    top: 0.15rem !important;
    font-weight: 600 !important;
    bottom: 1rem !important;
    position: absolute !important;
  }
  .modal-agr .Payfrom-account{
    width: 100% !important;
    margin-bottom: 1.5rem;
  }
  .demo{
    padding: 5rem;
    display: inline-block;
  }
  .Transactions .payment-method img {
    height: 2rem !important;
    width: 100% !important;
  }
  .agreements-stepper .form-group textarea.form-control {
   min-width: 30rem;
  }
  .modal-agr .searchparty .btn-style-1 {
    width: 90% !important;
  }
  .choose-doc-title .document-title{
    position: absolute;
    width: 100%;
    top: 5rem;
  }
  .have-agr input{
  width: 15px;
  height: 15px;
  }
  .add-note textarea.form-control{
    height: 86px !important;
  }
  .templates-drafts{
    margin: auto;
    width: 88%;
    margin-left: auto !important;
    margin-top: 5rem;
  }
  .modal-agr .documents-list{
    grid-gap: 10PX !important;
    padding: unset !important;
    border-bottom: unset !important;
  }
  .modal-agr .document-block{
    width: 120px;
  }
  .agr-docs-container .multiple-docs{
    top: 7rem !important;
  }
  .agr-docs-container .document-block{
    border-top: 0.5px solid #d8d2d2;
  }
  .agr-docs-container .fa-folder{
    color: #EFEFEF !important;
  }
  .missing-docs{
    color: red;
    font-size: 0.6rem;
    bottom: 0.5rem;
    position: absolute;
    left: 5.5rem;
  }
  td .payment-method{
    float: right;
  }
  .mandatory{
    color:#E74E5A !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    margin-bottom: -1rem;
  }
  .receiver-item{
    font-size: 1.2rem;
      border-bottom: 1px solid #CDCACA;
      padding: 1rem 0.5rem;
  }
  .receiver-head{
    display: flex;
  }
   .receiver-item .fa-angle-up{
  color:#0073B1;
  }
  .field-label{
    color:white;
  }
  .receiver-info {
    padding: 0rem 2rem;
    display: none;
  }
  .receiver-item i{
    color: #CDCACA;
    font-weight: 500;
    line-height: 1.2;
      font-size: 1.5rem;
  }
  .Payments-method{
      display: flex;
      min-width: 50rem;
      grid-gap: 3rem;
      flex-wrap: wrap;
      margin-bottom: 2rem;
  }
  .payment-block{
        border-radius: 15px;
        min-width: 85px;
        height: 50px;
        border: 1px solid #DBDBDB;
        text-align: center;
        position: relative;
  }
  .connections-middle .mt-5 button{
   min-width: 12rem !important;
  }
  .payment-type .form-group{
    margin-top: 20%;
    display: none;
      margin-right: 1rem;
  }
  .payment-title{
    position: absolute;
    width: 100%;
    top: 4.2rem;
    color: #DBDBDB;
  }
  .payment-type .form-group input[type=radio]{
    width: 17px;
    height: 17px;
  }
  .template-form .primary{
    font-size: 2rem;
  }
  .template-form .danger{
    margin-top: 2rem;
  }
  .View-agreement-container .documents-info{
    position: absolute;
    right: 2rem;
    top: 12rem;
  }
  .template-form.HasTemplate .template{
    display: flex;
    flex-wrap: wrap;
  }
  .template-control{
    width: 50%;
  }
  .paymentmethod-chosen{
    color:black !important;
  }
  .payment-block svg{
    margin-top: 10%;
  }
  .agreement-value .subscrptionType .form-group label{
    display: flex;
    font-size: 1rem;
    font-weight: 500;
  }
  .subscrptionType:nth-of-type(2){
    margin-bottom: 1rem;
    border-bottom: 1px solid #D2D2D2;
  }
  .agreement-value .form-group input[type=radio]{
    width: 20px;
    height: 20px;
    margin-right: 0.7rem;
   accent-color: #1C008A;
  }
  .agreement-amounts .tabs-style-2 li a{
    text-align: center;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    border-radius: 20px;
    border: 0.5px solid #DBDBDB;
    line-height: unset;
  }
  .agreement-amounts .tabs-style-2{
    display: flex;
  }
  .MyProfile ul.menu-style-1:not(.start) li:not(.active) a, .MyProfile ul.menu-style-1:not(.start) li:not(.active), .profile-left-menu ul.menu-style-1:not(.start) li:not(.active) a, .profile-left-menu ul.menu-style-1:not(.start) li:not(.active){
    opacity:1 !important;
  }
  .agr-invoices .profile-verified-notice{
  background-color: #F8F8F8;
  margin-top: unset;
  }
  .modal-body .connection-item{
    flex: unset !important;
    max-width: unset !important;
    width: 100% !important;
  }
  .view-agreement{
    color:#1C008A;
    font-weight: 550;
    font-size: 1.1rem;
    text-decoration: underline;
  }
   .social-media-connect .btn-style-2 {
    display: inline-block;
    width: 10rem;
    line-height: 3.5rem;
    min-width: 10rem;
    vertical-align: middle;
    font-weight: 400;
  }
  .LoginFormProfile .heading-layer {
    margin-bottom: 0.5rem;
  }
  .LoginFormProfile .bottom-actions{
  margin:0;
  }
  .LoginFormProfile form{
    border-radius: 8px;
      background-color: white;
      padding: 0.5rem 1rem;
  }
  .documents-div{
    margin: auto;
  }
  .privacy-security .profile-middle-content{
    min-width:75%;
  }
  .contactus-page .user-auth-form ,.register-form { 
    margin-top: 20px;
  }
  .loader {
    width: 120px;
    margin: auto;
    margin-top: 20rem;
  }
  .main-loader{
    margin-top: -10rem;
  }
  /* /////// */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  /**lilian**/
  .form-group .form-control, .input-control {
    padding: .375rem 2.75rem !important;
  }
.mdc-icon-button{
  font-size: 18px !important;
}
.mat-icon {
  height: 22px !important;
}
.mat-mdc-text-field-wrapper {
  background-color: transparent !important;
}
.heading-7 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  text-align: right;
}
.heading-layer {
  float: right;
  margin-top: -55px;
}
.admin-left-form{
  border:1px solid #F5F5F5;
  border-radius: 15px;
  padding:15px;
  height:100%;
}
.back-button {
  text-align: center;
  border-radius: 50%;
  border: 1px solid #707070;
  width: 25px;
  font-size: 1rem;
  display: inline-block;
  height: 25px;
  margin-right: 1rem;
  line-height: 25px;
  background-color: white;
}